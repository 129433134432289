<div class="top-group">
  <span>
    <button
      mat-button
      *ngIf="translatedFilters.length > 0"
      class="medis-button-white"
      [matMenuTriggerFor]="filters"
      data-testid="btn-reset-filters"
    >
      {{ 'RESET_FILTERS' | translate }} ( {{ translatedFilters.length }} )
    </button>
    <button
      mat-button
      *ngIf="translatedFilters.length === 0"
      class="medis-button-white"
      matTooltip="{{ 'INBOX_NO_FILTERS_HINT' | translate }}"
      data-testid="btn-no-applied-filters-for-reset"
    >
      {{ 'RESET_FILTERS' | translate }} ( 0 )
    </button>
  </span>
  <i
    class="fas medis-icon-toolbar"
    disabled="exportEnabled"
    (click)="exportEnabled ? exportToExcel() : null"
    [ngClass]="loadingReport ? 'fa-circle-notch fa-spin' : 'fa-file-download'"
    [matTooltip]="(loadingReport ? 'LOADING' : exportEnabled ? 'EXPORT' : 'NO_RECORDS') | translate"
  ></i>
  <ng-container *ngIf="selectedItems?.length && canProcessEnquiries">
    <span class="selection-dropdown">
      <app-reassign
        [preselectUnassigned]="false"
        [style]="'toolbar'"
        [mode]="'users-teams'"
        [selectedItems]="selectedItems"
        (reassign)="onReassign()"
        [disabled]="!!interactionEditingDropdownsTooltipText"
        [matTooltip]="interactionEditingDropdownsTooltipText | translate"
        [matTooltipDisabled]="!interactionEditingDropdownsTooltipText"
      ></app-reassign>
    </span>
    <span class="selection-dropdown">
      <mat-form-field
        class="form-field-wrapper toolbar"
        (click)="$event.stopPropagation()"
        [matTooltip]="interactionEditingDropdownsTooltipText | translate"
        [matTooltipDisabled]="!interactionEditingDropdownsTooltipText"
      >
        <mat-label class="header-cell">{{ 'set_priority' | translate }}</mat-label>
        <mat-select
          [ngModel]="filters['selectedPriority']"
          (ngModelChange)="interactionRequest($event, undefined)"
          [disabled]="!!interactionEditingDropdownsTooltipText"
        >
          <mat-option *ngFor="let priority of priorities" [value]="priority">
            <div class="medis-flex-container-between">
              <span class="priority-icon" [ngClass]="priority + '_priority_icon'">{{
                priority | translate
              }}</span>
              <i *ngIf="priority === filters['selectedPriority']" class="fa fa-check"></i>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="selection-dropdown">
      <mat-form-field
        class="form-field-wrapper toolbar"
        (click)="$event.stopPropagation()"
        [matTooltip]="statusDropdownTooltipText | translate"
        [matTooltipDisabled]="!statusDropdownTooltipText"
      >
        <mat-label class="header-cell">{{ 'set_status' | translate }}</mat-label>
        <mat-select
          [ngModel]="filters['selectedStatus']"
          (ngModelChange)="interactionRequest(undefined, $event)"
          [disabled]="!!statusDropdownTooltipText"
        >
          <mat-option *ngFor="let status of inquiryStatuses" [value]="status">
            <div class="medis-flex-container-between">
              <span class="status-label">{{ status }}</span>
              <i *ngIf="status === filters['selectedStatus']" class="fa fa-check"></i>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </ng-container>
</div>

<mat-menu #filters="matMenu" class="medis-menu reset-menu" overlapTrigger="false">
  <button
    mat-menu-item
    *ngIf="translatedFilters.length > 1"
    (click)="resetFilters({ field: 'ALL', translation: 'ALL' })"
    [attr.data-testid]="'btn-reset-all-filters'"
  >
    <i class="fas fa-ban"></i>{{ 'Reset' | translate }} {{ 'ALL' | translate }}
  </button>
  <span
    *ngFor="let filter of translatedFilters"
    [title]="filter.displayTerm ? filter.displayTerm : ''"
    class="reset-filter-item"
  >
    <button
      mat-menu-item
      (click)="resetFilters(filter)"
      [attr.data-testid]="'btn-reset-filter-' + filter.field"
    >
      <i class="fas fa-ban"></i>{{ 'Reset' | translate }} {{ filter.translation | translate }}
      <span *ngIf="filter.field != 'MODE'">: {{ filter.displayTerm }}</span>
    </button>
  </span>
</mat-menu>
