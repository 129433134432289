import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { capitalize } from 'lodash';
import { Subject } from 'rxjs';

import { Helpers } from '@mi-tool/utils/helpers';
import { GenericDialogComponent } from './generic-dialog.component';
import { environment } from 'app/../environments/environment';
import { BInteraction } from 'app/modules/data-model/interaction/interaction';
import { BUser } from 'app/modules/data-model/user/user';
import { RoleUsages } from 'app/modules/data-model/role/role.service';

const DISABLE_CLOSE_FOCUS_DIALOG: MatDialogConfig = { disableClose: true, autoFocus: 'dialog' };

@Injectable()
export class GenericDialogService {
  constructor(private dialog: MatDialog, private helpers: Helpers) {}

  openConfirmUserDeactivation(userDisplayInfo: string): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('USER_DEACTIVATION_CONFIRMATION_QUESTION', { USER_INFO: userDisplayInfo })
      .message('DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE')
      .saveCancelLabels('DEACTIVATE', 'CANCEL')
      .closeOnButton();
  }

  openConfirmUserDeactivationWithRemoteOpt(userDisplayInfo: string): Subject<boolean> {
    return this.openConfirmUserDeactivation(userDisplayInfo)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('DEACTIVATE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openConfirmBulkUsersDeactivationWithRemoteOpt(users: BUser[]): Subject<boolean> {
    const dialog = this.openConfirmUserDeactivation(users[0].displayInfo());
    if (users.length > 1) {
      dialog.title('CONFIRM_BULK_DEACTIVATION_OF_USERS');
    }
    return dialog
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('DEACTIVATE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openConfirmUserActivation(userDisplayInfo: string): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('USER_REACTIVATION_CONFIRMATION_QUESTION', { USER_INFO: userDisplayInfo })
      .message('ENABLE_USER_LOGIN_MESSAGE')
      .additionalMessage('SET_NOTIFICATIONS_PREFERENCES_MESSAGE')
      .saveCancelLabels('ACTIVATE', 'CANCEL')
      .closeOnButton();
  }

  openConfirmUserActivationWithRemoteOpt(userDisplayInfo: string): Subject<boolean> {
    return this.openConfirmUserActivation(userDisplayInfo)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('ACTIVATE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openMsgUserActivation(userDisplayInfo: string, saveLbl: string = 'GO_TO_ROLES'): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, { disableClose: true })
      .title('USER_REACTIVATED_MESSAGE', { USER_INFO: userDisplayInfo })
      .message('ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE')
      .additionalMessage('USER_MUST_RECOVER_PASSWORD_MESSAGE')
      .saveLabel(saveLbl)
      .closeOnButton()
      .getSaveSubject();
  }

  openRoleArchiveConfirmation(
    deactivate: boolean,
    userAssignedInteractionsValues: string = ''
  ): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('ARCHIVE_ROLE')
      .message(deactivate ? 'ROLE_ARCHIVE_WITH_USER_DEACTIVATION' : 'ROLE_ARCHIVE_CONFIRMATION')
      .additionalMessage(
        userAssignedInteractionsValues.length > 0
          ? 'USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA'
          : '',
        {
          USER_INTERACTIONS: userAssignedInteractionsValues,
        }
      )
      .confirmationQuestion('DO_YOU_WANT_TO_PROCEED')
      .saveCancelLabels('YES', 'NO')
      .closeOnButton();
  }

  confirmRoleArchive(roleUsages?: RoleUsages): Subject<void> {
    let addMessage = '';
    if (roleUsages) {
      if (roleUsages.users.length) {
        addMessage += `<b>${this.helpers.translate('ROLE_IN_USE_USERS')}</b>:<br><br>`;
        addMessage += `<table><tr><th>ID</th><th>${this.helpers.translate('Name')}</th></tr>`;
        for (const u of roleUsages.users) {
          addMessage += `<tr><td class='pe-2'>${u.pk()}</td><td>${u.displayInfo()}</td></tr>`;
        }
        addMessage += '</table><br>';
      }
      if (roleUsages.templates.length) {
        const label = this.helpers.translate('ROLE_IN_USE_USER_TEMPLATES');
        const names = this.helpers.arrayOfItemsToText(roleUsages.templates.map((t) => t.name));
        addMessage += `<b>${label}</b>: ${names}<br><br>`;
      }
      if (roleUsages.licenseCounterRole) {
        addMessage += `<b>${this.helpers.translate('ROLE_IN_USE_LICENSE_COUNTER')}</b>`;
      }
    }
    return new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('ARCHIVE_ROLE')
      .message('ROLE_ARCHIVE_CONFIRMATION')
      .additionalMessage(addMessage)
      .confirmationQuestion('DO_YOU_WANT_TO_PROCEED')
      .saveCancelLabels('YES', 'NO')
      .closeOnButton()
      .getSaveSubject();
  }

  openRoleArchiveConfirmationWithRemoteOpt(
    deactivate: boolean,
    userAssignedInteractions: number[]
  ): Subject<boolean> {
    const userAssignedInteractionsValues = userAssignedInteractions
      .map((interactionId) => `<b>${interactionId}</b>`)
      .join(', ');

    return this.openRoleArchiveConfirmation(deactivate, userAssignedInteractionsValues)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('ARCHIVE_ROLE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openCantArchiveRoleWarning(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('ARCHIVE_ROLE')
      .message('ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  loginCredentialsChanged(oldUsername: string, newUsername: string): void {
    const changes = { OLD_USERNAME: oldUsername, NEW_USERNAME: newUsername };
    new GenericDialogComponent.Builder(this.dialog)
      .title('LOGIN_CREDENTIALS_CHANGED')
      .message('CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE', changes)
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  alertUserForNewImportedInquiries(): void {
    new GenericDialogComponent.Builder(this.dialog)
      .title('WARNING')
      .message('NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE')
      .saveLabel('DONE')
      .closeOnButton();
  }

  showMaxDownloadsError(): void {
    new GenericDialogComponent.Builder(this.dialog, { height: 'fit-content' })
      .title('DOWNLOAD_ERROR')
      .message('DOWNLOAD_INQUIRIES_ERROR_MESSAGE')
      .saveLabel('CANCEL')
      .closeOnButton();
  }

  openDialogError(title: string, error: any): void {
    GenericDialogComponent.showMessage(this.dialog, error, title);
  }

  showMessage(message: string, title: string = null): void {
    GenericDialogComponent.showMessage(this.dialog, message, title);
  }

  showMessagesInPanels(title: string, panels: GenericDialogComponent.Panel[]): void {
    new GenericDialogComponent.Builder(this.dialog)
      .title(title)
      .panels(panels)
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  applyPendingChangesInformationDialog(interaction: BInteraction): Subject<void> {
    const pendingChanges = JSON.parse(interaction.pendingChanges);
    let message: string;
    let additionalMessage: string = '';
    let messageParams: { [key: string]: string };
    let additionalMessageParams: { [key: string]: string } = {};

    if (pendingChanges['interaction_team_values']) {
      message = pendingChanges['edited_interaction_id']
        ? 'INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS'
        : 'INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS';
      messageParams = {
        ENQUIRER: `<b>${interaction?.inquirer.fullName()}</b>`,
        EDITING_USER: `<b>${pendingChanges['editing_user']['fullname']}</b>`,
        UPDATED_INTERACTION_ID: `<b>${pendingChanges['edited_interaction_id']}</b>`,
        OLD_TEAM: `<b>${pendingChanges['interaction_team_values']['old_team_name']}</b>`,
        NEW_TEAM: `<b>${pendingChanges['interaction_team_values']['new_team_name']}</b>`,
      };
      additionalMessage = 'INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS';
      additionalMessageParams = {
        INTERACTION_ASSIGN_TO: `<b>${pendingChanges['interaction_assigned_to']}</b>`,
        NEW_TEAM: `<b>${pendingChanges['interaction_team_values']['new_team_name']}</b>`,
      };
    } else {
      message = 'USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS';
      messageParams = {
        EDITING_USER: `<b>${pendingChanges['editing_user']['fullname']}</b>`,
        INTERACTION_ASSIGN_TO: `<b>${pendingChanges['interaction_assigned_to']}</b>`,
        USER_ROLE: `<b>${pendingChanges['interaction_assigned_to_role']}</b>`,
        TEAM: `<b>${pendingChanges['interaction_team']}</b>`,
      };
    }

    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('WARNING')
      .message(message, messageParams)
      .additionalMessage(
        additionalMessage.length > 0 ? additionalMessage : '',
        additionalMessageParams
      )
      .confirmationQuestion('REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS')
      .saveLabel('CLOSE')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmInquirerCountryChange(
    message: string,
    messageParams: { [p: string]: string } | null,
    additionalMessage: string | null,
    additionalMessageParams: { [p: string]: string } | null
  ): MatDialogRef<GenericDialogComponent> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('WARNING')
      .message(message, messageParams)
      .additionalMessage(additionalMessage, additionalMessageParams)
      .saveCancelLabels('SAVE', 'CANCEL')
      .closeOnButton()
      .getRef();
  }

  confirmDeleteAttachment(interactionIds: number[]): Subject<boolean> {
    const builder = new GenericDialogComponent.Builder(this.dialog).saveCancelLabels(
      'CONFIRM',
      'CANCEL'
    );
    const ref = builder.getRef();
    const translateService = ref.componentInstance.translateService;

    if (interactionIds.length > 1) {
      ref.componentInstance.message = `${translateService.instant(
        'ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS'
      )}: ${this.helpers.arrayOfItemsToText(
        interactionIds.map((id) => id.toString())
      )}<br>${translateService.instant(
        'SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS'
      )}`;

      builder.checkboxLabel('DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE');
    } else {
      builder.message('CONFIRM_DELETE_ATTACHMENT');
    }
    return builder.closeOnButton().getSaveSubjectWithCheckboxVal();
  }

  openSetNewPassword(): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .message('ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION')
      .saveLabel('SET_PASSWORD')
      .closeOnButton()
      .getSaveSubject();
  }

  openVerifyEmailAddress(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('LOGIN_INCORRECT_EMAIL_ADDRESS')
      .message('LOGIN_VERIFY_EMAIL_ADDRESS')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  openLoginConfirmation(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('EXTRA_STEP_REQUIRED_TO_LOGIN')
      .message('EXTRA_STEP_DESCRIPTION')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  closeInquirer(unsavedFields: string[]): Subject<boolean> {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE')
      .message('PENDING_CHANGES_MESSAGE', { CHANGES: this.translateJoin(unsavedFields) })
      .saveCancelLabels('KEEP_INQUIRER_DETAILS_OPEN', 'LEAVE_WITHOUT_SAVING')
      .closeOnButton()
      .getRef().componentInstance.response;
  }

  confirmInquirerChange(message: string, question?: string): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('WARNING')
      .message(message)
      .confirmationQuestion(question)
      .saveCancelLabels('YES', 'NO')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmInteractionDetailsChange(additionalMessage: string): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION')
      .message('CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE')
      .additionalMessage(additionalMessage)
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  openConfirmationTherapeuticAreaDialog(message: string): Subject<boolean> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE')
      .message(message)
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getRef().componentInstance.response;
  }

  openInteractionInApprovalStateConfirm(message: string): Subject<void> {
    const messageParams = {
      IN_PROGRESS: '<b>In Progress</b>',
      SUBMITTED_FOR_APPROVAL: '<b>Submitted for Approval</b>',
      MERGE_AS_ADDITIONAL_QUESTION: '<b>Merge as additional question</b>',
    };
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .message(message, messageParams)
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  openSplitInteractionConfirm(): Subject<boolean> {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION')
      .message('SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE')
      .checkboxLabel('COPY_ANSWER_TO_NEW_INTERACTION')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubjectWithCheckboxVal();
  }

  confirmReopenInteraction(additionalMessage: string): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('WARNING')
      .message('RE_OPEN_CLOSED_INTERACTION_MESSAGE')
      .additionalMessage(additionalMessage)
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM')
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmDeleteInquiry(message: string): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('WARNING')
      .message(message)
      .confirmationQuestion('DEFAULT_CONFIRMATION_QUESTION')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  displayNewVersionNotice(): void {
    new GenericDialogComponent.Builder(this.dialog, { disableClose: true })
      .title('VERSION_DIALOG.TITLE')
      .message('VERSION_DIALOG.MESSAGE')
      .saveLabel('VERSION_DIALOG.SAVE_BUTTON')
      .onSave(() => window.location.reload());
  }

  confirmRolePermissionChange(msg: string): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('SAVE_CHANGES')
      .message(msg)
      .confirmationQuestion('DO_YOU_WANT_TO_PROCEED')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmSpecializationDeactivation(): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('SPECIALIZATION_DEACTIVATION_CONFIRMATION')
      .message('REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE')
      .saveCancelLabels('DEACTIVATE', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmLicenseCounterEdit(): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE')
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmCompanyEdit(message: string): Subject<boolean> {
    return new GenericDialogComponent.Builder(this.dialog, DISABLE_CLOSE_FOCUS_DIALOG)
      .title('COMPANY_ACTION_EDIT_CONFIRM_TITLE')
      .message(message)
      .saveCancelLabels('CONFIRM', 'CANCEL')
      .closeOnButton()
      .getRef().componentInstance.response;
  }

  open<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this.dialog.open(component, config);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  private resolveCheckboxLabelRemoteOpt(...parts: string[]): string {
    const labelParts = this.helpers.translateMany(parts);
    return [...labelParts, capitalize(environment.syncRemoteName)].join(' ');
  }

  private translateJoin(translationKeys: string[]): string {
    const translated = this.helpers.translateMany(translationKeys);
    return this.helpers.arrayOfItemsToText(translated, false);
  }
}
