export const italianLangData = {
  MEDICAL_INFO_SYSTEM: 'Sistema di informazione medica',
  ACTIVE_EVENTS: 'Eventi attivi',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Seleziona un evento per sfogliare gli utenti.',
  BACK_TO_THE_EVENT_SELECTION: "Torna alla selezione dell'evento",
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Se hai dimenticato la password',
  LOGIN: 'Accesso',
  RESET_IT_HERE: 'ripristinalo qui',
  CHANGE_LANGUAGE_TO: 'Cambia lingua in',
  TEST_SYSTEM: 'SISTEMA DI PROVA',
  HELP: 'Aiuto',
  THIS_IS_TEST_ENV: 'Questo è un ambiente di test, se vuoi inserire una vera richiesta vai a',
  USER_OR_PASS_IS_INCORRECT: 'Nome utente o password non sono corretti',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Non abbiamo trovato quello che cercavi',
  VIDEO_TUTORIALS: 'Tutorial video',
  STILL_DIDNT_FIND: 'Non hai ancora trovato quello che cercavi,',
  ASK_THE_SUPPORT_TEAM: 'Chiedi al team di supporto',
  LOGGED_IN_AS: 'Collegato come',
  MY_PROFILE: 'Il mio profilo',
  LOGOUT: 'Disconnettersi',
  RETRIEVING_YOUR_ACCESS_INFO: 'Recupero delle informazioni di accesso',
  LOGGED_IN_CAN_CLOSE: "Effettuato l'accesso, ora puoi chiudere questa scheda",
  TOKEN_HAS_NOT_BEEN_SAVED: "Il token non è stato salvato correttamente, contattare l'assistenza",
  PASSWORD_RESET: 'Reimpostazione della password',
  RESET_PASSWORD: 'Resetta la password',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'Inserisci la tua email per reimpostare la password',
  CANCEL: 'Annulla',
  CANCEL_UPPERCASE: 'ANNULLA',
  RETURN_TO_LOGIN: 'Ritorna a Login',
  USERNAME: 'Nome utente',
  USERNAME_IS_REQUIRED: 'è richiesto il nome utente',
  PASSWORD: "Parola d'ordine",
  PASSWORD_IS_REQUIRED: "E 'richiesta la password",
  FIRST_NAME: 'Nome di battesimo',
  LAST_NAME: 'Cognome',
  REGISTER: 'Registrati',
  LOADING: 'Caricamento in corso',
  INSERT_NEW_PASS: 'Inserisci la tua nuova password',
  VERIFYING_TOKEN: 'Verifica token',
  PASSWORD_DO_NOT_MATCH: 'le passwords non corrispondono',
  PASS_RESET_SUCCESSFUL: 'Reimpostazione password riuscita',
  SAVE_NEW_PASS: 'Salva nuova password',
  GO_TO_INBOX: 'Vai a Posta in arrivo',
  SELECT_ALL: 'Seleziona tutto',
  DESELECT_ALL: 'Deselezionare tutto',
  DO_NOT_SHOW_AGAIN: 'Non mostrare più',
  INQUIRY: 'Inchiesta',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Allegati a questa richiesta',
  USER_DETAILS: "Dettagli dell'utente",
  CLOSE: 'Vicina',
  TEAMS: 'squadre',
  TEAM: 'Squadra',
  SPECIALIZATION_IS: 'La specializzazione è',
  REQUIRED: 'necessaria',
  RESULTS_PER_PAGE: 'risultati per pagina',
  RESULTS_FOUND: 'Risultati trovati',
  EXPORT: 'Esportare',
  PERSONAL_SETTINGS: 'Impostazioni personali',
  TEAM_SETTINGS: 'Impostazioni squadra',
  USER_SETTINGS_OF: 'IMPOSTAZIONI UTENTE DI',
  SET_TEMPLATE: 'Imposta modello',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Le notifiche verranno sovrascritte con quelle correnti',
  PREVIEW: 'Anteprima',
  NOTIFICATIONS: 'NOTIFICHE',
  ROLE: 'RUOLO',
  TEMPLATE_NAME: 'NOME MODELLO',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Salva questo utente come modello',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Aggiungi un modello al tuo utente e copia il ruolo e le preferenze per le notifiche',
  AUTH_AND_NOTIFY_TEMPLATE: 'Modello di autorizzazione e notifiche',
  MY_ROLES: 'I miei ruoli',
  STATUS: 'STATO',
  THIS_ROLE_IS_TEMP:
    'Questo ruolo è temporaneo, è stato assegnato a causa del fatto che questo utente è un vice',
  CREATED: 'CREATA',
  APPROVED_REJECTED: 'APPROVATO / RESPINTO',
  APPROVED_REJECTED_BY: 'APPROVATO / RIFIUTATO DA',
  ARCHIVE: 'ARCHIVIO',
  SAVE: 'Salva',
  IMMEDIATE_EFFECT: 'Effetto immediato',
  OUT_OF_OFFICE_DEPUTY: "DEPUTATO FUORI DALL'UFFICIO",
  AUTO_ASSIGN_TO: 'QUESTO ECCELLENTE',
  GEOGRAPHY: 'GEOGRAFIA',
  ALIGNMENT: 'ALLINEAMENTO',
  Alignments: 'allineamenti',
  Address: 'Indirizzo',
  PHONE_FAX: 'Telefono / Fax',
  Email: 'E-mail',
  Contacts: 'Contatti',
  WORK_INFO: 'Informazioni sul lavoro',
  LAST_NAME_IS: 'Cognome è',
  PERSONAL_INFO: 'Informazione personale',
  USER: 'Utente',
  ID: 'ID',
  UPDATED_FROM: 'aggiornato da',
  ON: 'sopra',
  CRM: 'CRM',
  IMPORTED_FROM: 'importato da',
  CHANGE_PASS: 'Cambia la password',
  NOTIFICATION_IN_TEMPLATE: 'Notifiche nel modello',
  CONTINUE: 'Continua',
  DONT_SHOW_AGAIN: 'Non mostrare più questo messaggio',
  USING_THE_TABS_ON_THE: 'utilizzando le schede nella parte superiore dello schermo',
  PERSONAL_PROFILE_SETTINGS: 'Impostazioni del profilo personale',
  AND_YOUR: 'e il tuo',
  YOU_CAN_SWITCH_BETWEEN: 'Puoi passare dal tuo',
  WELCOME_TO_YOUR_USER_PROFILE: 'Benvenuto nel tuo profilo utente',
  SAVE_THIS_USER_AS: 'Salva questo utente come modello',
  INCLUDE_NOTIFY_SETTINGS: 'Include le impostazioni di notifica',
  INCLUDE_ROLES_SETTINGS: 'Include le impostazioni dei ruoli',
  ACTIVE_NOTIFICATIONS: 'NOTIFICHE ATTIVE',
  SET_THIS_USER_AS_A_: 'Imposta questa autorizzazione utente e le notifiche come nuovo modello',
  REQUEST_AUTH: 'Richiedi autorizzazione',
  REQUEST: 'Richiesta',
  FEEDBACK_SUPPORT: 'Feedback e supporto',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Se non riesci ancora a trovare quello che stai cercando, chiedi al team di supporto! Anche se hai un suggerimento per futuri miglioramenti, questo è il posto giusto.',
  TEMPLATE: 'Modella',
  SIGNATURE: 'firme',
  Disclaimers: 'Avvertenze',
  Greetings: 'Saluti',
  CUSTOMIZE: 'PERSONALIZZARE',
  SAVE_UPPERCASE: 'SALVA',
  DELETE: 'ELIMINA',
  RESET_TO_DEFAULT: 'RIPORTARE ALLE CONDIZIONI ORIGINALI',
  AFTER_SIGNATURE: 'Dopo la firma',
  BEFORE_SIGNATURE: 'Prima della firma',
  AFTER_ANSWER: 'Dopo la risposta',
  BEFORE_ANSWER: 'Prima della risposta',
  AFTER_GREET: 'Dopo i saluti',
  BEFORE_GREET: 'Prima dei saluti',
  Any: 'Qualunque',
  HCP: 'HCP',
  NO_HCP: 'Nessun operatore sanitario',
  Position: 'Posizione',
  ON_LABEL: "Sull'etichetta",
  OFF_LABEL: 'Off Label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Stai provando a personalizzare il modello',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'Non hai il permesso per farlo. Se vuoi procedere, verrà creata una copia del modello e lì effettuerai le modifiche.',
  CREATE_COPY: 'Crea copia',
  RECEIVED_ON: 'Ricevuto il',
  SENDER_EMAIL: 'Invio di e-mail',
  SUBJECT: 'Soggetto',
  UNSPAM: 'Unspam',
  UPLOAD: 'Caricare',
  APPROVED: 'Approvata',
  DRAFT: 'Bozza',
  SELECT_STATUS: 'Seleziona lo stato',
  UPLOAD_AS: 'Carica come',
  TITLE: 'Titolo',
  REPOSITORY: 'deposito',
  BROWSE: 'Navigare',
  URL: 'URL',
  APPROVED_ON: 'Approvato il',
  AUTH_HOLDER: "Titolare dell'autorizzazione",
  AUTH_NUMBERS: 'Numeri di autorizzazione',
  APPROVED_FOR: 'Approvato per',
  SELECT_ONE_REGION: 'Seleziona una regione',
  OWNED_BY: 'Posseduto da',
  KEYWORDS: 'parole',
  TOPICS: 'Temi',
  CATEGORIES: 'categorie',
  PRODUCTS: 'Prodotti',
  PRODUCT: 'Prodotto',
  LANGUAGE_IS_REQUIRED: 'Lingua richiesta',
  LANGUAGE: 'linguaggio',
  TARGET_CUSTOMERS: 'Clienti target',
  TYPE_IS_REQUIRED: 'Tipo richiesto',
  TYPE: 'genere',
  TITLE_IS_REQUIRED: 'È richiesto il titolo',
  DEFAULT_REFERENCE_IS_CREATED_:
    "Il riferimento predefinito viene creato con lo stile di Vancouver. Se vuoi sovrascriverlo, inserisci qui l'intero riferimento",
  REFERENCE: 'Riferimento',
  DOI: 'DOI',
  PUBMED_ID: 'ID pubmed',
  PAGES: 'pagine',
  ISSUE: 'Problema',
  VOLUME: 'Volume',
  JOURNAL: 'rivista',
  AUTHORS: 'Autrici',
  ABSTRACT: 'Astratto',
  REFERENCE_IS_REQUIRED: 'È richiesto il riferimento',
  THIS_DOC_IS_APPROVED: 'Questo documento è approvato Clicca qui per aprire il',
  ORIGINAL_DOCUMENT_AND_: 'documento originale e inserisci i tuoi commenti',
  EDIT_FILE: 'Modifica file',
  PROPERTIES: 'Proprietà',
  OPEN: 'Aperto',
  LOCALIZED_DOCUMENTS: 'Documenti localizzati',
  ORIGINAL_DOCUMENTS: 'Documento originale',
  INTERNAL_APPROVAL_NUMBER: 'Numero di approvazione interno',
  CREATED_BY: 'Creato da',
  EXPIRATION_DATE: 'Data di scadenza',
  VERSION_NUMBER: 'Numero della versione',
  NO_VERSION_AVAILABLE: 'Nessuna versione disponibile',
  ARCHIVED_IF_NO_DRAFT: 'Archiviato: se non sono presenti versioni bozza / approvate.',
  APPROVED_FOR_USERS: 'Approvato: per gli utenti.',
  DRAFT_FOR_AUTHORS_: 'Bozza: per autori ed editori.',
  VERSION_LEGEND: 'Legenda della versione',
  VERSION: 'versione',
  SELECT_ONE_LANG: 'Seleziona una lingua.',
  SELECT_ONE_TYPE: 'Seleziona un tipo.',
  INTERNAL_NUMBER_REQUIRED: 'È richiesto il numero di approvazione interno.',
  FILE: 'File',
  ATTACH_FILE: 'Allega file',
  LITERATURE: 'Letteratura',
  SLIDE_DECK: 'Slide Deck',
  PIL: 'PIL',
  FAQ: 'FAQ',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Aggiungi passaggio di approvazione',
  ADD_PARALLEL_STEP: 'Aggiungi un passo parallelo',
  DELETE_STEP: 'Elimina passaggio',
  DELETE_ALL_PARALLEL_STEPS: 'Elimina tutti i passaggi paralleli',
  CHANGE_STATUS_NAME: 'Cambia nome stato',
  SET_REQUIRED_ROLE: 'Imposta il ruolo richiesto',
  SET_TEAM_MEMBER: 'Imposta membro del team',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Non hai i permessi per cambiare questo processo.',
  SAVE_ROLE: 'SALVA RUOLO',
  CREATE_NEW_WORKFLOW: 'CREA NUOVO FLUSSO DI LAVORO',
  CREATE_NEW_STEP: 'CREA NUOVO PASSO',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Immettere il numero di passaggi di approvazione paralleli, ovvero i passaggi approvati contemporaneamente (non in sequenza). Se non sono presenti passaggi di approvazione paralleli, immettere 1. È sempre possibile modificare il numero di passaggi di approvazione in un secondo momento.',
  APPROVAL_MUST_BE_AT_LEAST: "L'approvazione deve essere almeno",
  INSERT_THE_NEW_STATUS_NAME: 'Inserisci il nuovo nome dello stato',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Inserisci il nuovo nome del flusso di lavoro.',
  INSERT_THE_NEW_STEP_NAME: 'Inserisci il nuovo nome del passaggio.',
  NEW_DOCUMENT: 'Nuovo documento',
  CREATE: 'Creare',
  DOCUMENT_MANAGEMENT: 'Gestione documenti',
  CONFIRM: 'Confermare',
  CONFIRM_PROCESS: 'PROCESSO DI CONFERMA',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINIRE PASSAGGI E SQUADRA DI OMOLOGAZIONE',
  SET_DETAILS: 'DETTAGLI SET',
  SELECT_TYPE: 'SELEZIONA TIPO',
  COMPLETE_LOCALIZATION_PROCESS: 'Processo di localizzazione completo',
  CREATE_NEW_VERSION: 'Crea nuova versione',
  CREATE_NEW_DOCUMENT: 'Crea nuovo documento',
  IS_PRIVATE: 'È privato',
  SHARED_WITH_OTHER_TEAMS: 'Condiviso con altre squadre',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Inserisci il numero di passaggi di approvazione sequenziali necessari. Se due approvazioni sono in parallelo, conta come un passo. Puoi sempre modificare il numero di passaggi di approvazione in un secondo momento.',
  DONE: 'Fatto',
  SUBMIT_FOR_APPROVAL: "Invia per l'approvazione",
  YOU_HAVE_TO_CONFIRM_THE_: 'Devi confermare il processo prima del caricamento.',
  SELECT_YOUR_DEC_TYPE: 'Seleziona il tipo di documento',
  TARGET_AUDIENCE: 'Destinatari',
  PROCESS_DETAILS: 'Dettagli del processo',
  START_DATE: "Data d'inizio",
  DOC_DETAILS: 'Dettagli del documento',
  CONFIRMATION: 'Conferma',
  APPROVAL_PROCESS: 'Processo di approvazione',
  SCHEDULED_EXP_DATE: 'Data di scadenza programmata',
  SCHEDULED_PUBLISHING_DATE: 'Data di pubblicazione programmata',
  NO_PROCESS_SELECTED: 'Nessun processo selezionato',
  COUNTRIES: 'paesi',
  DOC_ID: 'ID documento',
  THERAPEUTIC_AREA: 'Area terapeutica',
  FILTER: 'Filtro',
  BACK: 'indietro',
  ATTACH: 'allegare',
  COPY_ANSWER: 'Copia risposta',
  SIMILAR_INQUIRIES: 'Richieste simili',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Slide Decks',
  MEDICAL_DOC: 'Documento medico',
  SHOW: 'Spettacolo',
  LOAD_MORE: 'Carica altro',
  NO_RESULT_FOUND: 'Nessun risultato trovato.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    "può essere utilizzato per cercare un'intera frase, - può essere utilizzato per escludere dai risultati della ricerca all'interno della parola specificata",
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Cerca documenti medici o richieste simili',
  SEARCH: 'RICERCA',
  Preview: 'Anteprima',
  PREVIEW_AND_SEND: 'Visualizza in anteprima e invia',
  PREVIEW_AS_PDF: 'Anteprima in pdf',
  PUBLISHED_VERSION: 'Versione pubblicata',
  NEW_VERSION: 'Nuova versione',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Il documento è ancora in fase di approvazione, se si desidera modificare il contenuto del documento caricare un altro file.',
  OUT_TO: 'fuori a',
  NEXT: 'Il prossimo',
  SELECTED_SLIDES: 'Diapositive selezionate',
  GO_TO_SLIDE: 'VAI ALLA SLIDE',
  PREV: 'prev',
  SELECT_SLIDE: 'Seleziona diapositiva',
  ANSWER: 'Risposta',
  BACK_TO_STANDARD_VIEW: 'Torna alla vista standard.',
  UPLOAD_NEW_FILE: 'Carica nuovo file',
  CREATE_FILE: 'CREA FILE',
  EDITING_WORD_DOC: 'Modifica del documento Word',
  INSERT_FILE_NAME: 'Inserisci il nome del file',
  PROCEED_WITH_THE_LINK_: 'Procedere con il collegamento del file',
  A_NEW_WINDOW_WILL_BE_:
    'Verrà aperta una nuova finestra con un file vuoto, scrivere il contenuto e chiudere la scheda',
  INSERT_THE_NAME_FOR_:
    "Inserisci il nome dell'allegato che desideri creare e procedi con la creazione del file",
  INSTRUCTIONS: 'Istruzioni',
  CREATE_NEW_WORD_FILE_FOR: 'Crea un nuovo file di parole per',
  FROM: 'a partire dal',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Puoi trovare qui le ultime richieste che hai inserito nel sistema sia inviate che non ancora inviate. Puoi riutilizzare le richieste che hai inserito per creare richieste più simili o tornare alle domande che non hai ancora inserito.',
  MY_DRAFT_INQ: 'Le mie richieste di bozze',
  WARNING_CHANGES_NOT_SAVED: 'Avviso: modifiche non salvate',
  PAGE_THAT_WILL_BE_LOST: 'pagina che andrà persa se si decide di continuare.',
  YOU_HAVE_UNUSED: 'Hai modifiche non salvate in',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Sei sicuro di voler lasciare questa pagina senza salvare?',
  LEAVE_THIS_PAGE: 'Lascia questa pagina',
  STAY_ON: 'Resta acceso',
  ASSIGNED_TO: 'Assegnato a',
  THIS_INQ_HAS_BEEND_INSERTED_: "Questa richiesta è stata inserita durante l'evento.",
  STATUS_IN_APPROVAL: 'Stato: in approvazione',
  STATUS_DRAFT: 'Stato: bozza',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Se si desidera creare la richiesta effettiva, andare alla scheda Invia e fare clic sul pulsante Crea.',
  INQ_IMPORTED_FROM: 'Richiesta importata da',
  SEND_NEW_ANSWER: 'INVIA NUOVA RISPOSTA',
  RETURN_TO_INBOX: 'TORNA A INBOX',
  SUBMITTED_FOR_APPROVAL: "Presentato per l'approvazione",
  ANSWER_SUBMITTED_ON: 'Risposta inviata il',
  BY: 'di',
  INQ_HAS_BEEN_CLOSED: "L'indagine è stata chiusa senza risposta.",
  BCC: 'bcc',
  ANSWER_GIVEN_ON: 'Risposta data il',
  ANSWER_SENT_ON: 'Risposta inviata il',
  INBOX: 'Posta in arrivo',
  OPEN_ORIGINAL_INQ: 'Apri la richiesta originale',
  CLOSE_ANSWER_NOT_NEEDED: 'Chiudi: risposta non necessaria',
  HISTORY: 'Storia',
  ADD_A_NOTE: 'Aggiungi una nota',
  ADD_A_NOTE_INTO_: 'Aggiungi una nota nella cronologia delle richieste.',
  SHARE_OR_START_A_FOLLOW_UP: 'Condividi o avvia un follow-up',
  SEE_ALL_INQ_RELATED_TO: 'Vedi tutte le richieste relative a',
  SRD_NEEDED: 'SRD necessario',
  ANSWER_UNAVAILABLE: 'Risposta non disponibile',
  QUESTION: 'Domanda',
  SUBMIT: 'INVIA',
  ANSWER_UPPERCASE: 'RISPOSTA',
  DETAILS: 'DETTAGLI',
  SPLIT_INQ: 'Richiesta divisa',
  ADD_QUESTION: 'Aggiungi domanda',
  ENTER_QUESTION: 'Inserisci la domanda',
  MARK_AS_ANSWERED: 'Contrassegna come risposta',
  TO_BE_ANSWERED: 'A cui rispondere',
  FOLLOW_UP_RECEIVED: 'Seguito ricevuto',
  ERROR: 'Errore',
  SHOW_ORIGINAL_MESSAGE: 'Mostra messaggio originale',
  SEND: 'Spedire',
  DO_NO_SEND: 'Non inviare',
  OK: 'Ok',
  MAX_LENGTH_FOR_ZIP_CODE: 'La lunghezza massima per il codice postale è',
  CHARACTERS: 'personaggi',
  ADDRESS_1_IS_: "L'indirizzo 1 è",
  LABEL_IS: "L'etichetta è",
  NO_QUESTION_INSERTED: 'Nessuna domanda inserita',
  WARNING: 'AVVERTIMENTO',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Il follow-up non è disponibile per questa interazione.',
  INSERT_INQ_TEXT: 'Inserisci il testo della richiesta',
  SHOW_MORE: 'Mostra di più',
  OUT_OF: 'fuori da',
  INQ_TO_BE_SENT: 'Richieste da inviare',
  HIDE_INQ: 'Nascondi richieste',
  SHARE: 'Condividere',
  HCP_PROFILE_OF: 'PROFILO HCP di',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Compila tutti i campi obbligatori sottostanti e almeno un indirizzo e-mail, quindi salva per visualizzare in anteprima la notifica di ricezione GDPR.',
  CONFIRMATION_SENT_BY: 'Notifica inviata da',
  COUNTRY_IS: 'Paese è',
  COUNTRY: 'Nazione',
  AT_LEAST_ONE_CONTACT_IS_: 'Almeno un contatto è',
  EMAIL_IS: "L'email è",
  EMAIL_LABEL_IS: "L'etichetta e-mail è",
  PHONE_FAX_IS: "L'etichetta del telefono / fax è",
  PHONE_FAX_NUMBER_IS: 'Il numero di telefono / fax è',
  ADDRESS: 'Indirizzo',
  DELETE_PERSONAL_DATA: 'Elimina i dati personali',
  HCP_VERIFIED: 'HCP verificato',
  KEY_OPINION_LEADER: 'Key opinion leader',
  HOSPITAL_BASED: 'Basato in ospedale',
  HCP_RELATED_INFORMATION: "INFORMAZIONI RELATIVE ALL'HCP",
  ACCEPT: 'Accettare',
  REJECT: 'Rifiutare',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Vuoi accettare questi suggerimenti?',
  SALES_REP: 'RAPPRESENTANTE DELLE VENDITE',
  SUGGEST_A_TOPIC: 'Suggerisci un argomento',
  TEAM_SPECIFIC_FIELDS: 'CAMPI SPECIFICI DEL TEAM',
  ADR_RELATED: 'Correlazione ADR',
  NOT_ADR_RELATED: "Non correlato all'ADR",
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Si prega di rivedere il messaggio e classificarlo come relativo alla reazione avversa ai farmaci o meno.',
  ADVERSE_DRUG_REACTION: 'Reazione avversa ai farmaci',
  CHANGE_DETAILS: 'MODIFICA DETTAGLI',
  YES: 'sì',
  NO: 'No',
  DUE_DATE: 'Scadenza',
  CRITICAL: 'Critica',
  NORMAL: 'Normale',
  PRIORITY: 'Priorità',
  SELECT_DETAILS: 'SELEZIONA I DETTAGLI',
  SAVE_REVIEW: 'Salva recensione',
  SET_TO_ALL_INQ: 'IMPOSTATO A TUTTE LE RICHIESTE',
  PRODUCT_QUALITY_COMPLAINT: 'Reclamo sulla qualità del prodotto',
  PQC_PC_A_PRODUCT_:
    'PQC / PC Un reclamo di qualità del prodotto (QC) o un problema è definito come qualsiasi preoccupazione che coinvolga un difetto o un malfunzionamento di qualsiasi prodotto farmaceutico o per quanto riguarda la qualità, le prestazioni, la sicurezza di qualsiasi prodotto farmaceutico, ad es. odore e / o gusto alterati, confezione scadente o difettosa, sospetta contaminazione, ecc.',
  OFF_LABEL_USE_OF_PHARMA___:
    "Uso off-label di farmaci per un'indicazione non approvata o in una fascia di età, dosaggio o via di somministrazione non approvati.",
  A_RESPONSE_TO_A_MEDICINAL_:
    'Una risposta a un medicinale che è nociva e non intenzionale e la relazione causale tra un medicinale e un evento avverso è almeno una ragionevole possibilità. Anche i casi di situazioni speciali (ad es. Casi di gravidanza, uso off-label, sovradosaggio) devono essere contrassegnati come ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normale è di 7 giorni lavorativi, Alta è di 48 ore e Critica (correlata al paziente) è di 24 ore',
  CATEGORY_AND_TOPIC_ARE: 'Categoria e argomento sono',
  PRODUCT_IS: 'Il prodotto è',
  DO_YOU_WANT_TO_ACCEPT_: 'Vuoi accettare questi suggerimenti?',
  MERGE: 'Go',
  Duplicate: 'Duplicare',
  Other: 'Altra',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Stretta interazione',
  RE_OPEN_THE_TARGET_: "Riaprire la richiesta di destinazione dopo l'unione",
  MARK_AS_INTERNAL_: 'Contrassegna come messaggio interno per la richiesta esistente.',
  MARK_AS_AN_ERROR_: 'Contrassegna come messaggio di errore per la richiesta esistente.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Questo messaggio verrà contrassegnato come risposta di follow-up di una richiesta esistente.',
  THANK_YOU: 'Grazie',
  AN_INTERNAL_MESSAGE: 'Un messaggio interno',
  AN_ERROR_MESSAGE: 'Un messaggio di errore',
  A_FOLLOW_UP_RESPONSE: 'Una risposta di follow-up',
  THIS_INQ_IS: 'Questa richiesta è',
  SPLIT_INTERACTION: 'Interazione divisa',
  SAVE_AND_CLOSE: 'Salva e chiudi',
  SAVE_INQ: 'Salva richiesta',
  REVIEW_THE_TEXT_AND: 'Rivedi il testo e la categorizzazione e conferma',
  CHOOSE_THE_RELEVANT_PRODUCT:
    "Scegli il prodotto, la categoria e l'argomento pertinenti per il testo selezionato",
  SELECT_THE_TEXT_THAT_:
    'Seleziona il testo che identifica la domanda relativa a un prodotto / argomento (non includere saluti, firme, dichiarazioni di non responsabilità o altre informazioni non pertinenti)',
  REPEAT_THE_PREVIOUS_: 'Ripetere i passaggi precedenti per aggiungere ulteriori richieste',
  READ_MORE: 'Leggi di più',
  READ_LESS: 'Leggi meno',
  DO_NOT_INCLUDE_:
    'non includere saluti, firme, dichiarazioni di non responsabilità o altre informazioni non pertinenti',
  SELECT_THE_TEXT_THAT_IDENT:
    'Seleziona il testo che identifica la domanda su un prodotto / argomento',
  IF_YOU_HAVE_MULTIPLE_: 'Se hai più prodotti o argomenti, la richiesta deve essere suddivisa.',
  THE_FOLLOWING_PROCESS_:
    'Il seguente processo ti aiuterà a identificare il testo pertinente per ciascun prodotto / argomento: ',
  EXPORT_TO_PDF: 'ESPORTAZIONE IN PDF',
  Added: 'aggiunto',
  Deleted: 'Deleted',
  From: 'A partire dal',
  To: 'Per',
  BACK_TO_INBOX: 'Torna alla Posta in arrivo',
  PERMISSION_DENIED: 'Permesso negato',
  THIS_INQ_IS_LOCKED_BY: 'Questa richiesta è bloccata da',
  FORCE_UNLOCK: 'SBLOCCO FORZA',
  INQUIRIES: 'Indagini',
  Download: 'Scarica',
  Event: 'Evento',
  INSERTED_BY: 'Inserito da',
  LAST_EDITED_ON: 'Ultima modifica',
  LAST_EDITED_BY: 'Ultima modifica di',
  CREATED_ON: 'Creato',
  TOPIC: 'Argomento',
  TABLE_OPTIONS: 'Opzioni tabella',
  DEPARTMENT: 'Dipartimento',
  User: 'Utente',
  ADD_THERAPEUTIC_AREA: 'Aggiungi area terapeutica',
  CREATE_THERAPEUTIC_AREA: 'Crea una nuova area terapeutica',
  ADD_NEW: 'Aggiungere nuova',
  SELECT_TEAM_TO_PROCEED: 'Seleziona Team per procedere',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'I saldi',
  CALL_CENTER: 'Call center',
  ALL: 'Tutte',
  Warning: 'avvertimento',
  clear: 'chiara',
  THESE_CHANGES_WILL_BE_: 'Queste modifiche verranno applicate agli utenti selezionati',
  INSERT_A_FOLLOW_UP_RESP: 'Inserisci una risposta di follow-up',
  INSERT_A_NOTE: 'Inserisci una nota',
  MERGED_FROM: 'Fuso da',
  CHANGES: 'I cambiamenti',
  SHOW_LESS: 'mostra meno',
  COMPLETE: 'Completare',
  MISSING: 'Mancante',
  CLOSE_AS_FORWARDED: 'Chiudi come inoltrato',
  CLOSE_EXT_TEAM: 'Chiudi la richiesta come inoltrata a un team esterno',
  THE_INQ_BELONG_TO_A_:
    'Questa richiesta appartiene a un Paese che è responsabile di un team esterno. La richiesta verrà quindi inoltrata automaticamente a questa squadra.',
  RESOLVE: 'Risolvere',
  TERRITORIES: 'territori',
  EXTERNAL_COMMUNICATION: 'COMUNICAZIONE ESTERNA',
  FOR_INQ_COMING_DIRECTLY_:
    "Per richieste provenienti direttamente dall'HCP tramite e-mail, inviare automaticamente una notifica per informarlo che la richiesta è stata ricevuta.",
  INTERNAL_COMMUNICATION: 'COMUNICAZIONE INTERNA',
  SALESREP_RECEIVES_: 'Salesrep riceve solo notifiche di risposta alla richiesta',
  SALESREP_RECEIVES_FULL_ANSWER_:
    "Salesrep riceve la risposta completa per le domande sull'etichetta",
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep riceve solo notifiche di risposta alla richiesta',
  Name: 'Nome',
  Module: 'Modulo',
  Function: 'Funzione',
  LAST_RUN: 'Ultima corsa',
  End: 'Fine',
  Every: 'Ogni',
  Period: 'Periodo',
  Active: 'Attiva',
  Resolved: 'Risoluta',
  ERROR_PREVIEW: 'Anteprima errori',
  FAILURE_COUNT: 'Conteggio errori',
  COMPLETED_ON: 'Completato il',
  STARTED_ON: 'Iniziato il',
  TASK_NAME: "Nome dell'attività",
  PRODUCT_SPECIFIC: 'PRODOTTO SPECIFICO',
  THERAPEUTIC_AREAS: 'AREE TERAPEUTICHE',
  PRODUCT_GROUPS: 'GRUPPI DI PRODOTTO',
  LICENSING_PARTNER: 'PARTNER DI LICENZA',
  PRODUCT_CODE: 'CODICE PRODOTTO',
  EXTERNAL_ID: 'ID esterno',
  OBJECT_TYPE: 'Tipo di oggetto',
  MIGRATION_NAME: 'Nome della migrazione',
  MIGRATION_ERRORS: 'Errori di migrazione',
  ADD_SALES_REP: 'Aggiungi rappresentante vendite',
  ASSIGNED_TO_MSL: 'ASSEGNATO A MSL',
  NOT_ASSIGNED: 'Non assegnato',
  ASSIGNED_TO_OTHER_MSL: 'Assegnato ad altri MSL',
  ASSIGNED_TO_THIS_MSL: 'Assegnato a questo MSL',
  SHOW_ONLY: 'Mostra solo',
  SALES_REP_LIST: 'Elenco dei rappresentanti di vendita',
  ACTIVE_TASK: 'Attiva attività',
  CHOOSE_USER_AND_SET_THE_SHIFT_: "Scegli l'utente e imposta il turno per l'evento",
  MANAGE_SHIFTS: 'GESTIONE DI MAIUSC',
  CREATE_NEW_USER: 'Crea nuovo utente',
  THIS_TEMPLATE_WILL_BE_AUTO_:
    'Questo modello verrà automaticamente aggiunto al tuo membro del team.',
  TEMPLATE_FOR: 'Modello per',
  Day: 'Giorno',
  Week: 'Settimana',
  Month: 'Mese',
  Today: 'Oggi',
  Archive: 'Archivio',
  PARTICIPANTS: 'PARTECIPANTI',
  EVENT_MANAGEMENT: 'GESTIONE DI EVENTI',
  ADD_TASK: 'Aggiungi attività',
  EDIT_TASK: 'Modifica attività',
  IS_ACTIVE: 'È attivo',
  Months: 'mesi',
  Days: 'giorni',
  Hours: 'Ore',
  Minutes: 'Minuti',
  HCP_ADMINISTRATION: "AMMINISTRAZIONE DELL'HCP",
  AUTH_REQ_ADMINISTRATION: 'Gestione richieste di autenticazione',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'Il prodotto verrà evidenziato nelle aree terapeutiche selezionate.',
  PRODUCT_GROUPS_DEFINE_:
    'I gruppi di prodotti definiscono il team responsabile della risposta a queste domande sul prodotto.',
  USER_ADMINISTRATION: 'Amministrazione utenti',
  IS_PRODUCT_SPECIFIC: 'È specifico del prodotto',
  USER_TEMPLATES_ADMIN: 'Amministrazione dei modelli utente',
  SET_DEFAULT_VALUES: 'IMPOSTARE VALORI PREDEFINITI',
  SET_DEFAULT_VALUES_FOR_: "Imposta i valori predefiniti per l'evento",
  INQUIRY_DEFAULT_VAL: 'Valori predefiniti della richiesta',
  CREATE_NEW_EVENT: 'CREA NUOVO EVENTO',
  EDIT_EVENT: 'MODIFICA EVENTO',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Imposta i dettagli per creare un nuovo evento',
  ADD_DOC_LINKED_TO_: "Aggiungi documenti collegati all'evento",
  INC_CHANNEL: 'Inc. Channel',
  CHANGE_DETAILS_OF_THE_EVENT: "Modifica i dettagli dell'evento",
  DESCRIPTION: 'Descrizione',
  CREATE_APPROVAL_PROCESS: 'Scegli il processo di approvazione',
  CREATE_NEW: 'Creare nuovo',
  CUSTOMIZE_PROCESS: 'Personalizza processo',
  SELECTED_PROCESS: 'Processo selezionato',
  CLONE: 'Clone',
  PREFERENCES_TITLE: 'Preferenze',
  NOTIFICATIONS_TITLE: 'notifiche',
  TEMPLATES: 'Modelli',
  LETTER_TEMPLATES: 'Modello di lettera',
  USER_TEMPLATES: 'Modelli utente',
  TEAM_MEMBERS: 'Membri del team',
  NEW_PASSWORD: 'Nuova password',
  REPEAT_NEW_PASSWORD: 'ripetere la nuova password',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Almeno 1 lettera minuscola',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Almeno 1 lettera maiuscola',
  AT_LEAST_1_DIGIT: 'Almeno 1 cifra',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Almeno 1 carattere speciale',
  MINIMUM_8_CHARACTERS: 'Minimo 8 caratteri',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Non deve contenere e-mail o nomi',
  SHOULD_MATCH_PASSWORD: 'Deve corrispondere alla password',
  USER_MANUAL: "Manuale d'uso",
  SUPPORT: 'Supporto',
  BACK_TO_LOGIN: 'Torna al login',
  PASSWORD_CONFIRMATION: 'Conferma password',
  HAVE_AN_ACCOUNT: 'Hai già un account?',
  Submit: 'Invia',
  Gender: 'Genere',
  PROFESSION: 'Professione',
  MEDICAL_INQ_ROLES: 'Ruolo di elaborazione delle domande mediche',
  MEDICAL_INFO_MANAGER: 'Responsabile delle informazioni mediche',
  MEDICAL_SCIENCE_LIAISON: 'Collegamento di scienze mediche',
  NOT_PROCESSING_INQUIRIES: 'Non elaborare richieste',
  ACTIVE_HEALTH_CARE_SPEC: 'Specialista di assistenza sanitaria attiva',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Specialista sanitario non attivo',
  PASS_IS_REQUIRED: "E 'richiesta la password!",
  EMAIL_IS_REQUIRED: 'Email obbligatoria!',
  VALID_EMAIL: 'Si prega di inserire un indirizzo email valido!',
  FIELD_IS_REQUIRED: 'Questo campo è obbligatorio!',
  ONLY_LETTERS: 'Per favore, inserisci solo lettere!',
  ARE_YOU_daiichi_EMPLOYEE: 'Sei un dipendente Daiichi Sankyo?',
  here: 'Qui',
  CREATE_AN_ACCOUNT: 'Crea un account',
  FORGOT: 'Dimenticato?',
  Medical: 'Medica',
  Medical_not_resp: 'Medico - non è responsabile di rispondere ai MIR',
  Commercial: 'Commerciale',
  Market_access: 'Accesso al mercato',
  Pharmacovigilance: 'farmacovigilanza',
  PR: 'PR',
  Please_specify: 'Per favore specificare',
  Medical_information_manager: 'Responsabile delle informazioni mediche',
  Medical_advisor: 'Consulente medico',
  Are_Medical_inquiries: 'Sei responsabile di rispondere alle domande mediche?',
  Are_Health_care_professional: 'Sei un professionista sanitario?',
  Health_care_Industry: 'Settore sanitario',
  Health_Authority: 'Autorità sanitaria',
  Journalist: 'Giornalista',
  Lawyer: 'Avvocatessa',
  Patient: 'Paziente',
  Payor: 'pagante',
  Scientist: 'Scienziata',
  Angiologist: 'angiologo',
  Cardiologist: 'Cardiologa',
  Cardiovascular_Surgeon: 'Chirurgo cardiovascolare',
  Dentist: 'Dentista',
  Gastroenterologist: 'Gastroenterologa',
  General_Practitioner: 'Medico di medicina generale',
  Geriatrician: 'gerontoiatra',
  Gynecologist: 'Ginecologa',
  Haematologist: 'Ematologo',
  Internist: 'Internista',
  Medical_Student: 'Studente di medicina',
  Nephrologist: 'nefrologo',
  Neurologist: 'Neurologa',
  Neurosurgeon: 'Neurochirurga',
  Nurse: 'Infermiera',
  Oncologist: 'Oncologa',
  Oncology_Nurse: 'Infermiera di oncologia',
  Other_HCP: 'Altro operatore sanitario',
  Other_Surgeon: 'Altro chirurgo',
  Pediatrician: 'Pediatra',
  Pharmacist: 'Farmacista',
  Pharmacologist: 'Farmacologa',
  Pharmacy_Technician: 'Tecnico farmaceutico',
  Pneumologist: 'Pneumologa',
  Radiologist: 'Radiologa',
  Rheumatologist: 'Reumatologa',
  USER_PASS_INCORRECT: 'Nome utente o password non sono corretti',
  SUBMITTED_REQUEST: 'La tua richiesta è stata inviata correttamente.',
  Reset: 'Ripristina',
  Reset_pass: 'Resetta la password',
  New_pass: 'nuova password',
  RESET_PASS_EMAIL: 'Un link per reimpostare la password è stato inviato alla tua email',
  NEW_PASS_SUCCESS: 'La tua password è stata ripristinata correttamente',
  SOMETHING_WENT_WRONG: "Si è verificato un errore, contatta l'assistenza.",
  EVENTS: 'eventi',
  Login_here: 'Accedi qui',
  REGISTRATION: 'Registrazione',
  Create_an_account: 'Crea un account',
  NO_RECORDS: 'Nessuna registrazione',
  INTERACTION: 'Interazione',
  SPECIALIZATION: 'Specializzazione',
  SPECIALIZATION_TYPE: 'Tipo di specializzazione',
  SAVED: 'Salvato',
  SAVING: 'Salvataggio',
  TYPING: 'Digitando',
  CREATE_NEW_INTERACTION: 'Crea nuova interazione',
  FILTER_BY: 'Filtra per',
  SEND_EMAIL: 'Invia una email',
  SAVE_WITHOUT_EMAIL: 'Salva senza e-mail',
  SUBMIT_TO_INBOX: 'Invia a Posta in arrivo',
  Create_my_acc: 'Creare il mio account',
  NEW_QUESTION: 'Nuova domanda',
  LABEL: 'Etichetta',
  SELECT_A_LABEL: "Seleziona un'etichetta",
  CITY: 'Città',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Suggerisci una nuova categoria o selezionane una esistente',
  SUGGEST_CATEGORY_OR_TOPIC: 'Suggerisci una nuova categoria o argomento',
  URGENT: 'Urgente',
  EXISTING_CATEGORY: 'Categoria esistente',
  NEW_CATEGORY_NAME: 'Nome della nuova categoria',
  NEW_TOPIC_NAME: 'Nuovo nome argomento',
  CATEGORY: 'Categoria',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Canale in uscita',
  LOGIN_TO_CONTINUE: 'Accedi per continuare su Madjenta',
  Email_address: 'Indirizzo email',
  FORGOT_PASS: 'Ha dimenticato la password?',
  Remember_me: 'Ricordati di me',
  YOU_WILL_BE_NOTIFIED:
    "L'e-mail di conferma è stata inviata con ulteriori istruzioni per la registrazione",
  SUCCESS_EMAIL_CONFIRMATION: 'La tua e-mail è stata confermata correttamente.',
  SUCCESS_EMAIL_CONFIRMATION_SUB:
    'Riceverai una notifica quando la tua richiesta sarà stata esaminata',
  FAILED_EMAIL_CONFIRMATION: 'Non siamo in grado di confermare la tua e-mail.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Riprova e se il problema persiste scrivi a supporto con la descrizione del problema',
  RESET_FILTERS: 'Ripristina filtri',
  NEW_PASS_RESET_SUCCESS: 'Password ripristinata correttamente!',
  Cardiovascular: 'Cardiovascolare',
  Oncology: 'Oncologia',
  Due_On: 'Dovuto per',
  Follow_Up_Sent_On: 'Follow-up inviato il',
  Follow_Up_Received_On: 'Follow-up ricevuto attivato',
  Closed_On: 'Chiuso',
  Reopened_On: 'Riaperto',
  Inquiry_ID: 'ID richiesta',
  Search: 'Ricerca',
  Assigned_to_Now: 'Assegnato a (ora)',
  Edited_By: 'Modificato da',
  Assigned_to_Any_Time: 'Assegnato a (in qualsiasi momento)',
  Closed: 'Chiusa',
  Foreign: 'Straniera',
  Complex_search_Sentence_: 'Ricerca complessa: Frase: "frase da cercare", non: -word',
  Search_inquiries: 'Cerca informazioni',
  SEARCH_REPOSITORY: 'Cerca nel repository',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'filtri',
  Active_Filters: 'Filtri attivi',
  CONTACT_SUPPORT: 'Contatta il supporto',
  MedicalInformationRequests: 'Richieste (pdf)',
  UserProfileNavigation: 'Profilo utente (pdf)',
  UserManualChanges: "Modifiche al manuale dell'utente (docx)",
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Assegna richiesta',
  MedIS_Documentation_Approval_Process: 'Processo di approvazione della documentazione',
  MedIS_Documentation_Overview: 'Panoramica della documentazione',
  MedIS_Inquiry_Documentation: 'Documentazione di richiesta',
  MedIS_MIR_Inbox_Filters: 'Filtri posta in arrivo MIR',
  MedIS_Merge_MIR: 'Unisci MIR',
  MedIS_New_Documentation: 'Nuova documentazione',
  MedIS_New_Inquiry: 'Nuova richiesta',
  MedIS_Out_Of_Office: "Fuori dall'ufficio",
  MedIS_Prepare_Custom_Answer: 'Prepara la risposta personalizzata',
  MedIS_Split_MIR_Part_I: 'Split MIR Part I',
  MedIS_Split_MIR_Part_II: 'Split MIR Part II',
  I_AGREE_TO: "Sono d'accordo",
  TermsAndConditionsLink: 'Termini e condizioni',
  Title: 'Titolo',
  INTERACTION_TITLE: 'Titolo di interazione',
  Move: 'Mossa',
  Left: 'Sinistra',
  Right: 'Destra',
  Requestor: 'Richiedente',
  Assigned_To: 'Assegnato a',
  Status: 'Stato',
  Note: 'Nota',
  Confirm: 'Confermare',
  rejectConfirm: 'Sei sicuro di voler rifiutare la richiesta?',
  approveConfirm: 'Sei sicuro di voler approvare la richiesta?',
  newUser: 'Nuovo utente',
  editUser: 'Modifica utente',
  deleteUser: 'Elimina utente',
  newProduct: 'Nuovo prodotto',
  editProduct: 'Modifica prodotto',
  deleteProduct: 'Elimina prodotto',
  newCategory: 'Nuova categoria',
  editCategory: 'Modifica categoria',
  deleteCategory: 'Elimina categoria',
  newTopic: 'Nuovo argomento',
  editTopic: 'Modifica argomento',
  deleteTopic: 'Elimina argomento',
  userRequest: "Richiesta dell'utente",
  productRequest: 'Richiesta prodotto',
  categoryRequest: 'Richiesta di categoria',
  categoryTopicRequest: 'Categoria / Richiesta argomento',
  topicRequest: 'Richiesta argomento',
  Suggestion: 'Suggerimento',
  tradeName: 'Nome depositato',
  activeSubstance: 'Sostanza attiva',
  productName: 'nome del prodotto',
  productSpecific: 'È specifico del prodotto?',
  EMAIL_IN_USE: "C'è un account esistente con questa e-mail",
  fromDate: 'Dalla data',
  toDate: 'Ad oggi',
  applyFilter: 'Applica il filtro',
  requestReceivedFrom: 'Richiesta ricevuta da',
  on: 'sopra',
  at: 'a',
  to: 'per',
  add: 'Inserisci',
  from: 'a partire dal',
  approve: 'approvare',
  reject: 'rifiutare',
  withTheFollowingRole: 'con il seguente ruolo',
  forTheFollowingTeam: 'per la seguente squadra',
  request: 'richiesta',
  submittedBy: 'inviato da',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'La richiesta di reimpostazione della password non è valida o è scaduta',
  PASS_IS_RECENT: 'La password è stata utilizzata di recente',
  Assigned_to_me: 'Assegnato a me',
  My_drafts: 'Le mie bozze',
  My_open_inquiries: 'Le mie domande aperte',
  My_closed_inquiries: 'Le mie domande chiuse',
  Filter_inquiries_by: 'Filtra le richieste per',
  Inq_inbox: 'Posta in arrivo',
  REPORTS: 'Rapporti',
  My_teams_open_inq: 'Richieste aperte della mia squadra',
  My_teams_closed_inq: 'Le domande chiuse della mia squadra',
  All_teams_open_inq: 'Tutte le squadre aprono richieste',
  All_teams_closed_inq: 'Tutte le squadre hanno chiuso le indagini',
  Pending_approval: 'In attesa di approvazione',
  ADMINISTRATION: 'Amministrazione',
  Show_all: 'Mostra tutto',
  In_progress: 'In corso',
  Table_Settings_Columns: 'Impostazioni tabella - Colonne',
  Change_column_visibility: 'Cambia la visibilità della colonna',
  Reassign_to: 'Riassegna a',
  set_priority: 'Imposta priorità',
  set_status: 'Imposta stato',
  Spam_list: 'Elenco spam',
  Institution: 'Istituzione',
  DUE_ON: 'Dovuto per',
  Profile_settings: 'Impostazioni del profilo',
  Preferences: 'Preferenze',
  Role_settings: 'Impostazioni del ruolo',
  Notification_settings: 'Impostazioni di notifica',
  User_profile: 'Profilo utente',
  Select_person: 'Seleziona persona',
  FROM_DATE: 'Dalla data',
  TO_DATE: 'Ad oggi',
  JOB_DESC: 'Descrizione del lavoro',
  INQ_Management: 'Gestione delle richieste',
  DOCS_Management: 'Gestione documenti',
  USER_Management: 'Gestione utenti',
  Define_Frequency: 'Definire la frequenza',
  All_teams_all_inq: 'Tutte le richieste per tutte le squadre',
  My_teams_all_inq: 'Tutte le richieste per la mia squadra',
  Search_people_here: 'Cerca persone qui',
  Search_team_here: 'Cerca squadra qui',
  people: 'PERSONE',
  teams: 'SQUADRE',
  empty: '(vuota)',
  can_not_be_assigned: 'Questo utente non ha una squadra e non può essere assegnato',
  select_a_team: 'Seleziona una squadra per',
  confirm_team_change: 'Conferma cambio squadra',
  change_the_team_: 'Sei sicuro di voler cambiare la squadra in',
  can_not_see_it_anymore: 'Non sarai in grado di vederlo nella corrente',
  filtered_view: 'vista filtrata.',
  PHONE: 'Telefono',
  FAX: 'Fax',
  LETTER: 'Lettera',
  F2F_SITE_VISIT: 'F2F (Visita del sito)',
  F2F_CONGRESS: 'F2F (Congresso)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Altro)',
  WEBINAR_SITE_VISIT: 'Virtuale (visita del sito)',
  WEBINAR_CONGRESS: 'Virtuale (Congresso)',
  WEBINAR_ADBOARD: 'Virtuale (AdBoard)',
  WEBINAR_OTHER: 'Virtuale (Altro)',
  CHAT: 'Chiacchierare',
  COURIER: 'Corriera',
  REP_DELIVERY: 'Rep. Consegna',
  SYSTEM: 'Sistema',
  WEB_FORM: 'Modulo Web',
  Signature: 'Firma',
  Disclaimer: 'disconoscimento',
  STATE: 'Stato',
  ADDRESS_1: 'Indirizzo 1',
  ADDRESS_2: 'Indirizzo 2',
  ZIP_CODE: 'Cap',
  HIGH: 'Alta',
  ADVANCED: 'Avanzate',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Fai clic sul testo e sui campi sottostanti per popolare e modificare la risposta',
  DOCUMENT_VIEW: 'Vista documento',
  FORM_VIEW: 'Vista modulo',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Non hai i diritti per inviare la risposta.',
  ANSWER_TEXT: 'Rispondi al testo',
  CONTACT_INFORMATION: 'Informazioni sui contatti',
  COMPANY: 'Azienda',
  SRD_NEEDED_TOOLTIP: 'La risposta a questa richiesta richiede un nuovo documento SRD',
  ENABLE_ALL: 'Attiva tutto',
  DISABLE_ALL: 'Disabilitare tutto',
  BEFORE: 'Prima',
  AFTER: 'Dopo',
  EDIT: 'modificare',
  MY_TEMPLATES: 'I miei modelli',
  TEAM_TEMPLATES: 'Modelli di squadra',
  TEAM_TEMPLATES_HINT: "Modello creato da Medical Information Manager per l'intero team",
  SOURCE: 'fonte',
  TEMPLATE_NO_MATCH_INTERACTION:
    "Questo modello non corrisponde ai dettagli dell'interazione e non è applicabile",
  EMAIL_SUBJECT: "Oggetto dell'email",
  INQUIRY_SUBMIT_HINT:
    'Si prega di compilare tutti i campi obbligatori per rispondere alla richiesta',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Pubblicazione pianificata fino alla data di scadenza',
  COPY_ATTACHMENT_TO_ANSWER: 'Copia allegato in risposta',
  COPY_TEXT_TO_ANSWER: 'Copia il testo nella risposta',
  SELECT_INQUIRY: 'Seleziona Richiesta',
  EDIT_INQ: 'Modifica domanda',
  SPLIT_ANOTHER: 'Dividi un altro',
  DELETE_INQ: 'Elimina richiesta',
  SELECT_TEXT_FOR_SPLIT: 'Aggiungi una nuova domanda nella stessa interazione',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Genera interazione / caso separato',
  SPLIT_ORIGINAL_TEXT: 'Testo originale (seleziona il testo per la divisione)',
  SPLIT_TEXT_TO_REMAIN_: 'Testo da rimanere nella richiesta iniziale',
  SPLIT_TEXT_TO_SPLIT_: 'Testo da dividere',
  SPLIT_SELECTED_SECTIONS: 'Sezioni selezionate per la divisione',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Il testo verrà popolato automaticamente quando selezioni le sezioni dal testo originale',
  SPLIT_MODIFICATIONS_HINT:
    'Il testo è stato modificato manualmente e qualsiasi ulteriore selezione dal testo originale non verrà applicata.\n Fare clic qui per annullare le modifiche.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Il testo verrà aggiornato automaticamente con le modifiche ai dettagli della richiesta.',
  SELECT_DIFFERENT_TEMPLATE: 'Seleziona un modello diverso',
  APPROVE: 'Approvare',
  LAST_EDIT_ON: 'Ultima modifica attivata',
  LAST_EDIT_BY: 'Ultima modifica di',
  INTERNAL_COMM: 'Comunicazione interna',
  INACTIVE: 'Non attivo',
  DEACTIVATE: 'Disattivare',
  ARCHIVED: 'Archiviata',
  REPUBLISH: 'Ripubblica',
  INBOX_NO_FILTERS_HINT:
    'Non ci sono filtri selezionati.\n Se non riesci a trovare la richiesta che stai cercando, controlla di essere nella sezione della posta in arrivo corretta.',
  INTERACTION_TEMPLATE_HINT:
    'Firme, saluti e dichiarazioni di non responsabilità fanno parte delle comunicazioni scritte, come e-mail, lettere e fax.\n Per utilizzarli, assicurati che il canale in uscita sia impostato su uno di essi.',
  CONFIRM_AUTH_REQUEST: 'Conferma richiesta di autenticazione',
  VIEW_AUTH_REQUEST: 'Visualizza richiesta di autenticazione',
  QUESTION_TITLE: 'Titolo della domanda: ',
  NO_TITLE_INQUIRY: '-no-title-enquiry-',
  EXISTING_CATEGORIES: 'Categorie esistenti',
  EXISTING_TOPICS: 'Argomenti esistenti',
  DO_NOT_ASSIGN_PRODUCT: 'Non assegnare il prodotto',
  QUESTION_TEXT: 'Testo della domanda',
  DATE: 'Data',
  REJECTED: 'Respinto',
  FILTER_AUTH_REQUESTS_BY: 'Filtra le richieste di autorizzazione per',
  ALL_REQUESTS_ALL_TEAMS: 'Tutte le richieste per tutte le squadre',
  ALL_TEAMS: 'Tutte le squadre',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'Non disponi dei diritti per modificare le richieste di categoria / argomento.',
  SEND_FOLLOW_UP: 'Invia il follow-up',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Notifica di ricezione al cliente',
  GDPR_DISCLAIMER: 'Dichiarazione di non responsabilità del GDPR',
  ACTIONS: 'Azioni',
  SUBMISSION_CONDITIONS: 'Condizioni di presentazione',
  TYPE_OF_NOTIFICATION: 'Tipo di notifica',
  DIRECT_SUBMISSION: 'Invio diretto',
  DIRECT_SUBMISSION_TOOLTIP:
    'Per le richieste inviate direttamente dal cliente via e-mail, inviare una notifica per informare il cliente sul trattamento dei propri dati personali.',
  COMPANY_EMPLOYEE_SUBMISSION: "Inviato tramite dipendente dell'azienda",
  ENABLE_NOTIFICATION: 'Attivare le notifiche',
  NOTIFICATION_SENDER: 'Notifica al mittente',
  SALES_REP_DELIVERED_RESPONSE: 'Rappresentante di vendita per la risposta consegnata',
  NOTIFICATION_DELIVERED_RESPONSE: 'Notifica per risposta consegnata',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    "Per le richieste inviate tramite il rappresentante dell'azienda (rappresentante di vendita, MSL o altro), informare il mittente quando il cliente ha ricevuto una risposta alla sua richiesta",
  FULL_ANSWER: 'Risposta completa',
  NOTIFICATION: 'Notifica',
  NAME: 'Nome',
  COLUMN_INIT_QUESTION_TEXT: 'Testo originale della domanda',
  NOTIFICATION_ENABLED: 'Notifica abilitata',
  DEFAULT_TEMPLATE: 'Modello predefinito',
  SET_AS_DEFAULT: 'Imposta come predefinito',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Toggle è disabilitato poiché deve esserci almeno un modello predefinito!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE:
    'Il modello predefinito non può essere eliminato!',
  BATCH_UPDATE_ONLY:
    'È possibile solo un aggiornamento batch tramite la casella di controllo più in alto.',
  ACCESS_DENIED: 'Accesso negato!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    'Non sei autorizzato ad accedere a questa pagina.',
  USER_ROLES: 'Ruoli utente',
  MANAGE_USER_ROLES: 'Gestisci i ruoli degli utenti',
  ARCHIVE_ROLE: 'Ruolo di archivio',
  ROLE_ARCHIVE_CONFIRMATION: 'Vuoi davvero archiviare questo ruolo?',
  ROLES_FOR: 'Ruoli per',
  AUDIT_TRAIL: 'Traccia di controllo',
  ACTION_BY_USER: "Azione dell'utente",
  ACTION: 'Azione',
  ORIGINAL_VALUE: 'Valore originale',
  CHANGE: 'Cambia',
  CONDITION: 'Condizione',
  IP: 'IP',
  GEOLOCATION: 'Geolocalizzazione',
  NEW_VALUE: 'Nuovo valore',
  FILTER_BY_ACTION: 'Filtra per azione',
  SELECT_DATE: 'Seleziona data',
  TEAM_SAVE: 'Salva',
  TEAM_DELETE: 'Elimina',
  TEAM_CREATE: 'Creare',
  TEAM_CLONE: 'copia',
  TEAM_NAME: 'Nome',
  TEAM_CODE: 'Codice',
  TEAM_ACTION_DELETE_CONFIRM: 'Vuoi davvero eliminare',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Stai attento!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Una volta cancellato, non può essere ripristinato!',
  TEAM_FULL_ANSWER_ON_LABEL: "Risposta completa sull'etichetta",
  TEAM_RESTRICTED_TEAM: 'Limitata Limitato',
  TEAM_SEGREGATED_DATA: 'Dati segregati',
  TEAM_PRIVATE_TEAM: 'Squadra privata',
  TEAM_AUTO_ASSIGN: 'Assegna auto',
  TEAM_MAILBOX_IN: 'Cassetta postale in entrata',
  TEAM_MAILBOX_OUT: 'Cassetta postale in uscita',
  TEAM_DEFAULT_LANGUAGE: 'Lingua di default',
  TEAM_COUNTRIES: 'Paesi assegnati',
  TEAM_PRODUCT_GROUPS: 'Assegnare gruppi di prodotti',
  TEAM_PARENT: 'Squadra genitore',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Sostituisci il team di root cluster',
  TEAM_AFFILIATIONS: 'Squadre affiliate',
  TEAM_TIMEZONE: 'Fuso orario',
  UPDATE: 'Aggiornare',
  AUTO_ASSIGN_BY: 'Auto Assegnazione da',
  AUTO_ASSIGNMENTS: 'Assegnazioni automatiche',
  NO_ONE: 'Nessuno',
  NOT_APPLICABLE: 'Non applicabile',
  OVERRIDE_NOTIFICATIONS: 'Override Notifiche',
  OVERRIDE_NOTIFICATIONS_HINT:
    "Quando il modello dell'utente con le notifiche di override è impostato sull'utente, disabiliterà qualsiasi impostazione di notifica preesistenti e abiliterà solo le impostazioni di notifica elencate nel modello utente",
  CREATED_MANUALLY: 'Creato manualmente',
  DOCUMENT_TITLE_FOR_WORD:
    "Il titolo per i documenti di Word è generalmente prelevato dalla sezione formattata del titolo all'interno del documento stesso. Se il documento non contiene titolo, è possibile specificarlo qui. Se non si specifica il titolo qui e il documento Word non contiene la sezione formattata dal titolo, l'ID documento verrebbe utilizzato per il titolo.",
  ORDER_BY: 'Ordinato da',
  FORMAT: 'Formato',
  SELECT_QUESTION: 'Seleziona domanda',
  SELECT_QUESTION_FOR_NOTE: 'Seleziona la domanda per la quale si desidera aggiungere nota',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: "Aggiungi nota per l'intera richiesta",
  REQUESTED_DATE: 'Data richiesta',
  UNASSIGNED: 'Non assegnato',
  BLANK: 'Vuota Vuoto',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Risposta salvata senza e -mail',
  INQ_RELATED_TO: 'Indagini relative a',
  QUESTION_TEXT_BEFORE_SPLIT: 'Testo di domanda prima della divisione',
  SPLIT_MERGE: 'Dividere e unire',
  CLARIFICATION_QUESTION: 'Domanda di chiarimento',
  ACTIVE_ON: 'Attivo su',
  DISMISS: 'Congedare',
  GO_TO_ENQUIRY: 'Vai alla richiesta',
  NO_TEAMS: 'Senza squadra',
  NO_TEAMS_TIP:
    'Contiene utenti, che non sono assegnati a nessun team. Puoi verificare se alcuni di questi provengono dalla tua squadra e garantiscono loro il ruolo appropriato.',
  TASKS: 'Compiti',
  Therapeutic_Areas: 'Aree terapeutiche',
  Product_Groups: 'Gruppi di prodotti',
  Licensing_Partner: 'Partner di licenze',
  Product_Code: 'Codice prodotto',
  SELECT_EXISTING_ONE: 'Seleziona uno esistente',
  OR: 'o',
  MANAGE_FORM: 'Gestisci il modulo',
  EVENT_FORM_ADDRESS: "Indirizzo del modulo dell'evento",
  EVENT_FORM_PREVIEW_WARING:
    "Assicurati di aver salvato eventuali modifiche prima di aprire il modulo del Congresso o di copiare l'indirizzo",
  SEARCH_SAVED_SEARCHES: 'Ricerca ricerche salvate',
  GO: 'andare',
  ASSIGN: 'Assegnare',
  CERTIFICATION: 'Certificazione',
  SORT: 'Ordinare',
  ADD_MAILBOX: 'Aggiungi cassetta postale',
  UNSET_INCOMING_MAILBOX: 'Cassetta postale inarnita in arrivo',
  SET_INCOMING_MAILBOX: 'Imposta cassetta postale in arrivo',
  UNSET_OUTGOING_MAILBOX: 'Cassetta postale in uscita in uscita',
  SET_OUTGOING_MAILBOX: 'Imposta cassetta postale in uscita',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Tutte le squadre con cassetta postale corrente',
  ALL_TEAMS_WITH_MAILBOX: 'Tutte le squadre con cassetta postale',
  MAILBOX: 'Cassetta postale',
  EMAIL_POLLING: 'Polling e -mail',
  ADVANCED_SEARCH: 'Ricerca Avanzata',
  SELECT_FIELDS: 'Seleziona campi',
  SEARCH_TERM: 'Termine di ricerca',
  COLUMN_INTERACTION_ID: 'ID interazione',
  COLUMN_QUESTION_ID: 'Domanda ID',
  COLUMN_CREATED_ON: 'Creato',
  COLUMN_LAST_EDITED_DATE: 'Ultimo modifica',
  COLUMN_ASSIGNED_TO: 'Assegnato a',
  COLUMN_DUE_DATE: 'Scadenza',
  COLUMN_FOLLOW_SENT_DATE: 'Data di follow-up inviata',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Data di follow-up ricevuta',
  COLUMN_ANSWER_DATE: 'Data di risposta',
  COLUMN_CLOSED_ON_DATE: 'Chiuso alla data',
  COLUMN_REOPENED_DATE: 'Data riaperta',
  COLUMN_INSERTED_BY: 'Inserito da',
  COLUMN_LAST_EDITED_BY: 'Ultimo modifica da',
  COLUMN_ANSWERED_BY: 'Risposta da',
  COLUMN_APPROVED_BY: 'Approvato da',
  COLUMN_INT_COMM_USER: 'Utente di comunicazione interna',
  COLUMN_INT_COMM_DATE: 'Data di comunicazione interna',
  COLUMN_MY_TEAM: 'La mia squadra',
  COLUMN_PQC_RELATED: 'PQC correlato',
  COLUMN_INCOMING_CHANNEL: 'Canale in arrivo',
  COLUMN_OUTGOING_CHANNEL: 'Canale in uscita',
  COLUMN_OFF_LABEL: 'Offel',
  COLUMN_HCP_SPEC: 'Specializzazione HCP +',
  COLUMN_NON_HCP_SPEC: 'Professione non HCP +',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: "Basato sull'ospedale",
  COLUMN_CITY: 'Città',
  COLUMN_INSTITUTION: 'Istituzione',
  COLUMN_TERRITORY: 'Territorio',
  CASE_ID: 'Caso ID',
  OPTION_ADD: 'INSERISCI',
  OPTION_AND: 'E',
  OPTION_OR: 'O',
  OPTION_NOT: 'NON',
  OPTION_YES: 'sì',
  OPTION_NO: 'No',
  QUERY: 'Domanda',
  EXPORT_LABEL: 'Definire le colonne di esportazione',
  SAVE_SEARCH: 'Salva ricerca',
  SHARE_SEARCH: 'Condividi la ricerca',
  SEARCH_BTN: 'Ricerca',
  BACK_ACTION: 'Di ritorno',
  SAVE_CHANGES: 'Salvare le modifiche',
  SAVED_SEARCH: 'Ricerca salvata',
  NEW: 'Nuova Nuovo',
  SAVE_AS: 'Salva come nome',
  ENTER_RECIPIENTS: 'Inserisci i destinatari',
  CLEAR_SAVED_SEARCH: 'Ricerche salvate chiari',
  NO_SAVED_SEARCH: 'Nessuna ricerca salvata trovata',
  INT_COMM_TEXT: 'Testo di comunicazione interna',
  FOLLOW_UP_TEXT: 'Testo di follow-up',
  CONFIRM_DELETION: 'Confermare la cancellazione',
  ARE_SURE: 'Sei sicuro di voler eliminare la ricerca salvata',
  PERMANENTLY_DELETED: 'La ricerca sarà permanentemente <b> eliminata </b>.',
  NOTHING_SAVE: 'Niente da salvare.',
  NOTHING_SEARCH: 'Niente da cercare.',
  NOTHING_EXPORT: "La ricerca non ha record per l'esportazione.",
  SHARE_SUCCESS: 'La ricerca è stata condivisa con successo',
  SHARE_FAILURE: 'La ricerca non potrebbe essere condivisa',
  DELETED_SUCCESS: 'Ricerca salvata eliminata con successo',
  ALL_DELETED_SUCCESS: 'Eliminata con successo tutte le ricerche salvate',
  VIEW_SAVED_SEARCH: 'Visualizza la ricerca salvata',
  EDIT_SAVED_SEARCH: 'Modifica la ricerca salvata',
  EXPORT_SAVED_SEARCH: 'Export ha salvato la ricerca',
  EXPORT_SEARCH: 'Ricerca di esportazione',
  DELETE_SAVED_SEARCH: 'Elimina la ricerca salvata',
  SELECT_EXPORT_COLUMNS: 'Seleziona le colonne da esportare',
  SAVED_SEARCH_ALREADY_EXIST: 'Cerca già con quel nome.',
  CHARACTER_LIMIT: 'Min 3 caratteri. Max 35 caratteri.',
  CHARACTER_LEFT: 'i personaggi sono lasciati',
  ACTIVATE: 'Attivare',
  INACTIVE_USER:
    "Questo utente non è più attivo. Si prega di contattare l'amministratore di sistema.",
  FOLLOW_UP: 'Seguito',
  USER_NOT_FOUND: 'Utente non trovato',
  DEPARTMENTS: 'Dipartimenti',
  EMAIL: 'E-MAIL',
  ADD_NEW_DEPARTMENT: 'Aggiungi un nuovo dipartimento',
  DEPARTMENT_COUNTRY: 'NAZIONE',
  DEPARTMENT_COMPANY: 'AZIENDA',
  DEPARTMENT_TEAM: 'SQUADRA',
  DEPARTMENT_TA: 'Area terapeutica',
  DEPARTMENT_PRODUCT: 'PRODOTTO',
  DEPARTMENT_ACTIONS: 'AZIONI',
  DEPARTMENT_CONFIRMATION_1: 'Confermare la cancellazione?',
  DEPARTMENT_CONFIRMATION_2: 'Questa azione è irreversibile.',
  DEPARTMENT_NAME: 'Nome Dipartimento',
  TYPE_DEPARTMENT: 'Tipa Tipo',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Paese - Seleziona prima il team',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 caratteri.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Richiesta utente',
      CategoryTopicRequest: 'Categoria/richiesta di argomento',
      ProductRequest: 'Richiesta di prodotto',
      UserUpdateRequest: "Richiesta di aggiornamento dell'utente",
    },
    TYPE: {
      NewUser: 'Nuovo utente',
      NewCategoryTopic: 'Nuova categoria/argomento',
      NewUserUpdate: "Aggiornamento dell'utente",
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS:
        "Definire l'elenco di specializzazione specifico del paese",
      IS_COUNTRY_SPECIFIC: 'È specifico per paese',
      TYPE: 'Tipo di richiedente',
      SPECIALIZATION_EXISTS: 'La configurazione per "Tipo/Specializzazione Enquirer" esiste già.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Genere', COUNTRY: 'Paese', TITLE: 'Titolo' },
  },
  hcp: 'HCP',
  emp: 'Dipendente',
  cons: 'Non-HCP',
  OOO_DEPUTY: 'Ooo vice',
  NO_DEPUTY: 'Nessun deputato',
  UNSPECIFIED_END_DATE: 'Data di fine non specificata',
  DELEGATORS: 'Delegatori',
  NO_DELEGATORS: 'Nessun delegatore',
  PREVIEW_DELEGATORS: 'Anteprima dei delegatori',
  REMOVE_DEPUTY: 'Rimuovere il deputato',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Utenti che ti impostano come deputato',
  CHANGED_USER: 'Utente modificato',
  DEFAULT: 'Predefinita Predefinito',
  SPECIALIZATIONS: 'Specializzazioni',
  ENQUIRERS_LIST: 'Elenco Enquirers',
  EDIT_THE_RECORD: 'Modifica il record',
  DEACTIVATE_THE_RECORD: 'Disattivare il record',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: "Fuori dall'ufficio",
      HINT: 'Solo gli utenti attivi, con il ruolo di elaborazione delle richieste, che non sono fuori ufficio sono disponibili per la selezione.',
      MODAL_TITLE: "Conferma le impostazioni dell'ufficio",
      MODAL_MSG_1:
        "Hai escluso l'opzione dell'ufficio con effetto immediato, tutte le tue richieste verranno immediatamente assegnate al deputato.",
      MODAL_MSG_2:
        "Hai escluso l'opzione dell'ufficio, tutte le tue richieste verranno assegnate al deputato durante il periodo fuori ufficio.",
    },
  },
  GENDER_MALE: 'Sig.',
  GENDER_FEMALE: 'Sig.ra.',
  TITLE_DR: 'Dottora. Dottore.',
  TITLE_PHD: 'PhD',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Casa',
  HOSPITAL: 'Ospedale',
  OFFICE: 'Ufficio',
  WORK: 'Opera',
  INSERT_IMAGE: "Inserire l'immagine",
  USER_ADMIN: 'Amministratore utente',
  MAILBOXES: 'Cassette postali',
  AUTHORIZATION_REQUESTS: 'Richieste di autorizzazione',
  METADATA: 'Metadati',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Incarico',
      FORWARD: 'Inoltrare',
      FOLLOW_UP: 'Seguito',
      DUE_DATE_SETTINGS: 'Impostazioni della data di scadenza',
      CLOSE: 'Chiudere',
      DELIVERY_ERROR: 'Errore di consegna',
      SCHEDULER: 'Scheduler',
      USER_REPORTS: 'Rapporti sugli utenti',
      DOCUMENT_APPROVAL: 'Approvazione del documento',
      AUTHORIZATION_REQUEST: 'Richieste di autorizzazione',
      NEW_INQUIRY: 'Nuova richiesta',
      INQUIRY_APPROVAL: 'Approvazione della richiesta',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Una richiesta di ruolo è stata approvata/respinta',
      AUTHORIZATION_REQUEST_3: 'Un ruolo è aspettare la mia approvazione',
      DOCUMENT_APPROVAL_1: 'Un documento sta aspettando la tua approvazione',
      DOCUMENT_APPROVAL_2: 'Un documento è stato approvato/respinto',
      DOCUMENT_APPROVAL_3: 'È stato archiviato un documento',
      DOCUMENT_APPROVAL_4: 'Un documento è stato approvato con le modifiche',
      DOCUMENT_APPROVAL_5: 'Verrà archiviato un documento',
      DOCUMENT_APPROVAL_6: 'Elenco di documenti approvati',
      DUE_DATE_2: 'Ripeti dopo il passato',
      ERROR_1: 'Errori di notifica di consegna (ad es. Email errata)',
      FOLLOW_UP_1: 'Un follow -up o un promemoria è stato inviato al cliente',
      FOLLOW_UP_4: 'È stato ricevuto un follow -up',
      IMPORT_DATA_1: "Un nuovo oggetto è stato creato durante l'importazione",
      IMPORT_DATA_4: "Un nuovo utente è stato creato durante l'importazione",
      IMPORT_DATA_5:
        'Un utente corrispondeva a uno già esistente ma non proveniva da una precedente importazione',
      IMPORT_DATA_6:
        "Si è verificato un errore durante l'importazione di dati da una fonte esterna",
      NEW_DOCUMENT_1: 'Il nuovo documento approvato è disponibile nel mio paese',
      NEW_DOCUMENT_2: "È disponibile un nuovo documento per l'approvazione locale",
      NEW_DOCUMENT_3:
        "È disponibile un nuovo documento locale per l'approvazione europea / globale",
      NEW_DOCUMENT_4: 'È disponibile un nuovo documento globale',
      NEW_DOCUMENT_5: 'È disponibile un nuovo documento europeo',
      NEW_DOCUMENT_6: "Il nuovo documento europeo è disponibile per l'approvazione globale",
      SCHEDULER_TASK_1: "Un errore si è verificato durante l'esecuzione di un'attività",
      USER_REPORTS_1: 'Elenco di utenti attivi',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          "Ricevi notifiche che ti informano quando una richiesta del ruolo dell'utente deve essere approvata/rifiutata",
        AUTHORIZATION_REQUEST_2:
          'Ricevere notifiche quando è stato creato un nuovo utente o quando un ruolo è stato richiesto da un utente nel tuo team',
        AUTHORIZATION_REQUEST_4:
          'Ricevere notifiche quando è stato suggerito un nuovo argomento e/o una categoria',
        FOLLOW_UP_4:
          'Ricevi notifiche per le richieste a cui sono assegnato che hanno ricevuto una risposta di follow-up dal richiedente',
        NEW_INQUIRY_1:
          'Ricevi notifiche per le nuove richieste ricevute nel tuo paese, inserite da un richiedente/utente o inoltrate da un altro team',
        ANSWER_2:
          'Ricevi notifiche per le richieste a cui è stata data risposta, in cui ho lavorato come collaboratore, ma non sono direttamente assegnato alla richiesta',
        DUE_DATE_1:
          'Selezionando questa opzione verranno attivate tutte le notifiche sottostanti. Per ridurre il numero di notifiche, deseleziona quelle non necessarie.',
        INQUIRY_APPROVAL_5:
          "La risposta proposta è stata rifiutata oppure lo stato dell'interazione è stato aggiornato da Inviato per approvazione a In corso a causa di una divisione tramite interazione, unione come nuova richiesta con conseguente divisione o qualsiasi modifica delle impostazioni del team in termini di necessità di approvazione della richiesta successiva.",
        INQUIRY_APPROVAL_4: 'La risposta proposta è stata approvata.',
        METADATA_MANAGEMENT_2:
          'Ricevi notifiche per i prodotti appena creati, indipendentemente dal motivo della creazione.',
        METADATA_MANAGEMENT_3:
          'Ricevi notifiche per le categorie appena create, indipendentemente dal motivo della creazione.',
        METADATA_MANAGEMENT_4:
          'Ricevi notifiche per gli argomenti appena creati, indipendentemente dal motivo della creazione.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Immediatamente',
        DAILY: 'Quotidiana Quotidiano',
        WEEKLY: 'settimanalmente',
        QUARTERLY: 'Trimestrale',
        MONTHLY: 'Mensile',
        SECOND_DAY: 'Ogni secondo giorno',
        THIRD_DAY: 'Ogni terzo giorno',
        ONE_WEEK: 'Una settimana',
        ONE_MONTH: 'Un mese',
        TWO_MONTHS: 'Due mesi',
        THREE_MONTHS: 'Tre mesi',
        SIX_MONTHS: 'Sei mesi',
        NINE_MONTHS: '9 mesi',
        EVERY_TWO_WEEKS: 'Ogni due settimane',
        EVERY_THREE_WEEKS: 'Ogni tre settimane',
        EVERY_FOUR_WEEKS: 'Ogni quattro settimane',
      },
      OPTIONS: {
        ALMOST_DUE: 'Quasi dovuto',
        DUE_NOW: 'ADESSO ADESSO',
        DUE_SOON: 'Presto',
        EARLY_ON: "All'inizio",
        TEXT_GUIDES: {
          DUE_NOW: 'Ricevi notifiche quando la richiesta è dovuta',
          EARLY_ON:
            'Ricevi notifiche in anticipo per le richieste in scadenza: Normale 4 giorni - Alta 2 giorni - Critica 24 ore prima della data di scadenza',
          DUE_SOON:
            'Ricevi notifiche per le richieste in scadenza a breve: Normale 48 ore - Alta 12 ore - Critica 6 ore prima della data di scadenza',
          ALMOST_DUE:
            'Ricevi notifiche per le richieste in scadenza: Normale 24 ore - Alta 6 ore - Critica 3 ore prima della data di scadenza',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Una categoria/ argomento è stata richiesta nel mio team',
      AUTHORIZATION_REQUEST_2: 'Richieste utente',
      IMPORT_DATA_2: "Un nuovo richiedente è stato creato durante l'importazione",
      IMPORT_DATA_3:
        "Un richiedente stava abbinando un elemento già esistente ma non proveniva da un'importazione precedente",
      ANSWER_2: 'Una richiesta a cui ero coinvolto ha avuto risposta',
      ASSIGNMENT_1: 'Mi è stata assegnata una richiesta',
      ASSIGNMENT_5: "Un'indagine è stata assegnata al mio vice",
      CLOSE_1: 'Una richiesta è stata chiusa',
      DUE_DATE_1:
        'Una richiesta a cui sono stato assegnato è vicina alla data di scadenza, informami',
      FOLLOW_UP_7: 'Una richiesta è stata persa per il follow-up',
      FORWARD_1: 'Una richiesta è stata inoltrata',
      INQUIRY_APPROVAL_1: 'Una richiesta è in attesa della tua approvazione',
      INTERNAL_1: 'Una nota è stata aggiunta alla mia richiesta assegnata',
      NEW_INQUIRY_1: 'È stata ricevuta una nuova richiesta nel mio Paese',
      SPAM_1: 'Nuova richiesta ricevuta nello Spam',
      ANSWER_1: 'Ricevi copia della risposta nelle interazioni a cui ho risposto',
      ANSWER_6: 'Ricevi copia della risposta nelle interazioni da me approvate',
      INQUIRY_APPROVAL_5: 'La risposta da te proposta è stata rifiutata',
      INQUIRY_APPROVAL_4: 'La tua risposta proposta è stata approvata',
      METADATA_MANAGEMENT_1: "Geografia e allineamenti durante l'importazione CRM",
      METADATA_MANAGEMENT_2: 'È stato creato un nuovo prodotto',
      METADATA_MANAGEMENT_3: 'È stata creata una nuova categoria',
      METADATA_MANAGEMENT_4: 'È stato creato un nuovo argomento',
    },
  },
  NEW_TEAM: 'Nuova squadra',
  SmPc: 'SMPC',
  USERS: 'Utenti',
  PAGINATOR_NEXT_PAGE_LABEL: 'Pagina successiva',
  PAGINATOR_PREV_PAGE_LABEL: 'Pagina precedente',
  PAGINATOR_FIRST_PAGE_LABEL: 'Prima pagina',
  PAGINATOR_LAST_PAGE_LABEL: 'Ultima pagina',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'oggetti per pagina',
  Date: 'Data',
  Action: 'Azione',
  'Action by user': 'Azione per utente',
  'Changed user': 'Utente modificato',
  'Original value': 'Valore originale',
  'New value': 'Nuovo valore',
  REGION: 'Regione',
  TEAM_REGION_LABEL: 'Regione: seleziona prima la società',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Aree terapeutiche - Seleziona prima la società',
  TEAM_PRODUCT_LABEL: "Prodotti - Seleziona prima l'area terapeutica",
  TEAM_COUNTRIES_LABEL: 'Paesi - Seleziona prima la regione',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Definire i prodotti per ogni paese',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Seleziona almeno un prodotto per',
  VIEW_CHANGES: 'Visualizza le modifiche',
  Sales_Representative: 'Rappresentante commerciale',
  Other_Commercial: 'Altro commerciale',
  REGIONS: 'Regioni',
  MAIN_CLIENT: 'Cliente principale',
  LICENSE_PARTNER: 'Partner di licenza',
  TEAM_SET_UP: 'Squadra',
  LIST_TEAMS: 'Elenco squadre',
  COMPANIES: 'Aziende',
  DOMAINS: 'Domini',
  TOKEN_REFRESH_NOTICE:
    'Hai richiesto una nuova e -mail di conferma per la registrazione del tuo account Enqmed.',
  TOKEN_REFRESH_SUCCESS: 'Un nuovo link di conferma è stato inviato alla tua e -mail.',
  TOKEN_REFRESH_ERROR:
    'Si è verificato un errore. Si prega di contattare il supporto se hai bisogno di ulteriore assistenza.',
  PLEASE_ENTER_MAX: 'Inserisci il massimo',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Si prega di rivedere le modifiche di seguito, se presenti, e confermare se la società deve essere modificata.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Si prega di procedere con cautela perché le modifiche influenzeranno le squadre',
  THERE_ARE_NO_CHANGES: 'Non ci sono cambiamenti.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Si prega di controllare le aziende esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già la compagnia con gli stessi valori.',
  SIMPLE_TEXT:
    'Inserisci solo lettere (A-Z, A-Z), numeri (0-9) e simboli speciali come (Dash, periodo, spazio e colon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Seleziona almeno un paese per la regione',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    "Seleziona almeno un prodotto per l'area terapeutica",
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    "Hai selezionato prodotti che appartengono a più di un'area terapeutica, seleziona tutte le aree terapeutiche necessarie.",
  HTML_CHANGES: 'Cambia HTML',
  SIDE_BY_SIDE_VIEW: 'A fianco',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Si prega di controllare i domini esistenti nella tabella dietro la finestra di dialogo in quanto abbiamo già dominio con gli stessi valori.',
  OUT_CHANNEL_ERROR_MSG: 'Indirizzo postale in uscita della lettera del canale Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    "Canale in uscita selezionato come lettera. Inserisci l'indirizzo postale di Enquirer nell'area Enquirer",
  LABEL_FOR: 'Etichetta per',
  male: 'Maschia Maschio',
  female: 'Femmina',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Non hai i diritti di archiviare questo ruolo. Solo <b> Mims </b> per il team può archiviare ruoli per i membri del loro team.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    "Invia e -mail all'utente su richiesta di approvazione/rifiuto",
  APPROVE_REQUEST_QUESTION: 'Vuoi approvare la richiesta?',
  NEW_USER_REQUEST: "Nuova richiesta dell'utente",
  OPEN_REQUESTS: 'Richieste aperte',
  APPROVED_REQUESTS: 'Richieste approvate',
  REJECTED_REQUESTS: 'Richieste respinte',
  USER_REQUESTS: 'Richieste utente',
  REQUEST_FOR: 'Richiesta di',
  CATEGORY_TOPIC_REQUESTS: 'Richieste di categoria/argomento',
  WITH_THE_FOLLOWING_PAIRS: 'Con le seguenti coppie:',
  MY_TEAM_OPEN_REQUESTS: 'Richieste aperte',
  MY_TEAM_APPROVED_REQUESTS: 'Richieste approvate',
  MY_TEAM_REJECTED_REQUESTS: 'Richieste respinte',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: "Approvazione del documento all'interno di EnqMed",
  DOCUMENTS_APPROVAL: 'Approvazione dei documenti',
  DOCUMENTS_APPROVAL_TITLE:
    'Impostazioni per il processo di approvazione dei documenti per il team selezionato',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    "Canale in uscita selezionato come fax. Inserisci il numero di fax di Enquirer nell'area Enquirer",
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    "Canale in uscita selezionato come telefono. Inserisci il numero di telefono di Enquirer nell'area Enquirer",
  OUT_CHANNEL_FAX_ERROR_MSG: 'Valore del fax di canale in uscita Fax Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Valore di Enquirer del telefono canale in uscita',
  COPYRIGHT_PART_ONE: "Diritto d'autore",
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Tutti i diritti riservati.',
  REVIEWED_DATE: 'Data rivista',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    "Canale in uscita selezionato come e -mail. Inserisci l'e -mail di Enquirer nell'area Enquirer",
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Valore di Email di Email di canale in uscita',
  ENTER_USER_COMMENT_OPTIONAL: "Immettere commento all'utente (facoltativo)",
  SELECT_ANOTHER_CATEGORY: "Seleziona un'altra categoria",
  SELECT_ANOTHER_PRODUCT: 'Seleziona un altro prodotto',
  SUGGESTED_TOPIC: 'Argomento suggerito',
  EDIT_USER_REQUEST: "Modifica la richiesta dell'utente",
  NEW_TOPIC_REQUEST: 'Nuovo argomento suggerito',
  EDIT_TOPIC_REQUEST: 'Modifica argomento suggerito',
  NEW_CATEGORY_REQUEST: 'Nuova categoria suggerita',
  EDIT_CATEGORY_REQUEST: 'Modifica categoria suggerita',
  REVIEW_COMMENT: 'Recensione del commento',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Flussi di lavoro di approvazione del documento',
    CREATE_NEW: 'Crea un nuovo flusso di lavoro',
    CUSTOMIZE: 'Personalizza il flusso di lavoro',
    NAME: 'Nome flusso di lavoro',
    ERROR_MSG_DUPLICATE_NAME: 'Il flusso di lavoro con questo nome esiste già.',
    SAVE: 'Salva il flusso di lavoro',
    DELETE: 'Elimina il flusso di lavoro',
    STEP: 'Fare un passo',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: "Tutti gli utenti con ruolo dell'autore del documento.",
    APPROVAL_DEADLINE: 'Scadenza di approvazione',
    SELECT_APPROVAL_TYPE: 'Seleziona il tipo di approvazione',
    SELECT_APPROVAL_TYPE_FIRST: 'Seleziona prima il tipo di approvazione',
    SELECT_REVIEWERS: 'Seleziona i revisori',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Azione su Approvare le modifiche',
    ACTION_ON_REJECT: 'Azione sul rifiuto',
    FILL_ALL_REQUIRED_FIELDS:
      'Si prega di compilare tutti i campi richiesti per aggiungere un nuovo passaggio.',
    ALL_APPROVAL_TYPES_USED:
      'Non è possibile aggiungere più passaggi secondari di approvazione quando utilizzati tutti i tipi disponibili per il passaggio corrente.',
    CONFIRMATION_ROW_1:
      'Stai cercando di eliminare il flusso di lavoro di approvazione del documento per il team {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Vuoi davvero eliminare il flusso di lavoro di approvazione?',
    CONFIRMATION_TITLE: 'Eliminazione del flusso di lavoro di approvazione del documento',
    TEAM_VIEW_SELECT_EXISTING:
      "Seleziona un flusso di lavoro di approvazione del documento esistente dall'elenco sottostante o ",
    DOC_VIEW_SELECT_EXISTING:
      'Seleziona un flusso di lavoro di approvazione del documento esistente',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      "L'unica fase di approvazione singola può essere elencata come finale.",
    APPROVAL_TYPE_TOOLTIP:
      'Non ci sono utenti con un ruolo di approvazione del documento per questo team. Si prega di aggiungere prima i ruoli di approvazione.',
    INFO_DIALOG_TITLE:
      'Non esiste un flusso di lavoro di approvazione del documento fissato per questo team.',
    INFO_DIALOG_MSG:
      'Si prega di contattare il tuo MIM locale per creare il flusso di lavoro di approvazione del documento per il team.',
    STEP_LIMIT_REACHED: 'Hai raggiunto il limite di {{ MAX_STEPS }} passaggi di approvazione.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: "Tipi di documenti che non richiedono l'approvazione",
  PLEASE_SELECT_TYPE: 'Selezionare il tipo',
  ABORT_FILE_UPLOAD: 'Abort File Caricamento',
  USERS_REPORT: 'Report degli utenti',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Chiudendo questa finestra perderai le informazioni già compilate.',
  DO_YOU_WANT_TO_PROCEED: 'Vuoi procedere?',
  USERS_REPORT_DESCRIPTION:
    'Elenco di utenti di tutti i team aggiuntivi in cui MIM per Daiichi Sankyo Europe Oncology e Cardiovascolare hanno un ruolo MIM.',
  TEMPLATE_CONFIRM_MSG: 'Una volta eliminato il modello, non può essere ripristinato!',
  EVENT_CONFIRM_MSG: "Una volta archiviato l'evento, non può essere ripristinato!",
  CREATE_DRAFT: 'Crea bozza',
  CREATE_USER: 'Creare un utente',
  ADD_ROLE: 'Aggiungi ruolo',
  DOMAIN_ADDRESS_INVALID_FOR: 'Indirizzo di dominio non valido per',
  AVAILABLE_ADDRESSES: 'Indirizzi disponibili',
  IN_TEAM: 'in squadra',
  TO_THE_SELECTED_USER: "all'utente selezionato",
  USER_INFORMATION: 'Informazioni utente',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    "L'indirizzo e -mail che stai utilizzando deve essere aggiornato poiché l'indirizzo del dominio non corrisponde al team/s Il tuo profilo è associato. <br> Consultare l'amministratore di sistema per assistenza o contattare il team di supporto.",
  MESSAGE: 'Messaggio',
  IN_APPROVAL: 'In approvazione',
  IN_REVIEW: 'In revisione',
  DOCUMENT_APPROVAL: 'Approvazione del documento',
  DOCUMENT_TITLE: 'Titolo del documento',
  SEARCH_DOCUMENTS: 'Documenti di ricerca',
  APPROVAL_INBOX: 'Posta in arrivo di approvazione',
  FILTER_DOCUMENTS_BY: 'Filtro documenti di',
  submit_to_approval: "Presentati all'approvazione",
  create_document_metadata: 'Crea metadati di documenti',
  archive: 'Archivio',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Riconosco che è mio obbligo denunciare tutte le reazioni avverse sui farmaci (ADR) di tutti i prodotti medici in base ai requisiti locali. ENQMED non ha lo scopo di catturare rapporti sulle reazioni avverse ai farmaci: gli ADR devono essere segnalati alla farmacovigilanza direttamente in base ai requisiti locali',
  VERSION_DIALOG: {
    TITLE: 'Nuova versione disponibile',
    MESSAGE:
      "È disponibile una nuova versione del software!\n Devi ottenere la nuova versione, per beneficiare dei miglioramenti in essa contenuti ed evitare problemi derivanti dall'utilizzo della vecchia versione, devi ottenerla.",
    SAVE_BUTTON: 'Ottieni la nuova versione',
  },
  ACTIVE_INACTIVE: 'Attivo / Inattivo',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Si prega di controllare le categorie esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già una categoria con gli stessi valori.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Errore! Consegna fallita',
  VIA: 'attraverso',
  INSERTED_MANUALLY: 'Inserito manualmente',
  AS_A: 'come un',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controlla i prodotti esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già prodotti con gli stessi valori.',
  NOT_PRODUCT_SPECIFIC: 'Non specifico del prodotto',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controlla gli argomenti esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già argomenti con gli stessi valori.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Inserisci un massimo di {{ MAX_CHARS }} caratteri.',
  THANK_YOU_MESSAGE: 'Messaggio di ringraziamento',
  AS: 'COME',
  THERAPEUTIC_AREA_PARENT: 'Zona genitore',
  MAIN_THERAPEUTIC_AREA: 'Area terapeutica principale',
  CHOOSE_AN_IMAGE: "Scegli un'immagine",
  ALLOWED_FORMATS: 'Sono consentiti solo i formati {{ FORMATS }}',
  REMOVE_IMAGE: "Rimuovi l'immagine",
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    "Si prega di controllare le aree terapeutiche esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già un'area con gli stessi valori.",
  PLAIN_TEXT_VIEW: 'Visualizzazione testo normale',
  RICH_TEXT_VIEW: 'Visualizzazione testo ricco',
  EDIT_ITEM: 'Modifica {{ ITEM }}',
  CREATE_NEW_ITEM: 'Crea nuovo {{ ITEM }}',
  ADD_NEW_ITEM: 'Aggiungi nuovo {{ ITEM }}',
  ADD_ITEM: 'Aggiungi {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'e quindi lo stato è cambiato da',
  FOLLOW_UP_WITH_ENQUIRER: 'Follow-up con il richiedente',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'Per salvare le modifiche è necessario inserire:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    "Il follow-up non è possibile per un'interazione chiusa.",
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interazione in stato di revisione. Salva la recensione per abilitare questo menu.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Testo della domanda (minimo 6 caratteri)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Si prega di fornire una risposta (minimo 6 caratteri o un allegato).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'Tieni presente che l\'utente deve recuperare la password da "Password dimenticata".',
  ENABLE_USER_LOGIN_MESSAGE: "Ciò consentirà l'accesso dell'utente.",
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    "Ciò disabiliterà l'accesso e le notifiche dell'utente.",
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Imposta le preferenze di notifica dal tuo profilo utente!',
  SET_PASSWORD: 'Per favore imposta la tua password',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Dopo la riattivazione del tuo account, la tua password è stata cancellata.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Conferma la disattivazione della specializzazione!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    "Questo lo rimuoverà dall'elenco con le specializzazioni attive.",
  GO_TO_ROLES: 'Vai a Ruoli',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    "Le modifiche apportate alle informazioni di contatto dell'utente hanno comportato la modifica dell'e-mail di accesso da {{ OLD_USERNAME }} a {{ NEW_USERNAME }}",
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Assegna ruoli e notifiche.',
  DO_YOU_WANT_TO_CONTINUE: 'Vuoi continuare?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Copia la risposta alla nuova interazione',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Sono state importate nuove richieste. Assicurati di caratterizzare e rispondere a tutte le domande.',
  LEAVE_WITHOUT_SAVING: 'Lascia senza salvare',
  PENDING_CHANGES_MESSAGE: 'Le modifiche in sospeso sono {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Sei sicuro di voler dividere il testo selezionato nella nuova interazione?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    "Ciò rimuoverà il testo selezionato dall'interazione e ne creerà uno nuovo.",
  DOWNLOAD_ERROR: 'Errore di download!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Stai tentando di scaricare più di 1000 richieste. Il numero massimo supportato è 1000. Perfeziona la selezione.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Stai cercando di cambiare la caratterizzazione della domanda!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Tieni presente che la modifica della caratterizzazione della domanda non consentirà alcuna modifica nella risposta fornita in precedenza né consentirà di inviare una nuova risposta!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    "Stai per riaprire un'interazione chiusa per inviare una nuova risposta.",
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Sei sicuro di voler convertire questo follow-up in una domanda?',
  ARE_YOU_SURE: 'Sei sicuro?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Conferma la disattivazione di {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Conferma la riattivazione di {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: "L'utente {{ USER_INFO }} è stato riattivato con successo!",
  DELETE_NOT_POSSIBLE: 'Cancellazione impossibile!',
  SEARCH_NO_LONGER_EXIST: 'Questa ricerca non esiste più poiché è stata eliminata dal mittente.',
  LOGIN_CREDENTIALS_CHANGED: 'Credenziali di accesso cambiate!',
  EDIT_TEXT: 'Modifica il testo',
  FOLLOW_UP_ATTEMPTS: 'Tentativi di follow-up',
  FIRST_FOLLOW_UP_INFO_TEXT: "Primo follow-up inviato nell'ambito dell'interazione individuale",
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Se uno qualsiasi degli elementi seguenti viene disattivato dopo la richiesta iniziale, il processo passerà al passaggio successivo',
  SECOND_ATTEMPT: 'Secondo tentativo (1° sollecito)',
  THIRD_ATTEMPT: 'Terzo tentativo (1° sollecito)',
  FINAL_ATTEMPT: 'Perso al follow-up (chiuso)',
  REMINDER_FREQUENCY: 'Frequenza del promemoria',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Applicare la traduzione automatica nella lingua utilizzata per contattare il richiedente',
  ON_OFF_SWITCH: 'Acceso spento',
  DAY: 'Giorno',
  DAYS: 'Giorni',
  MONTH: 'Mese',
  MONTHS: 'Mesi',
  RESET_TEMPLATES_TO_DEFAULTS: 'Ripristina i modelli ai valori predefiniti',
  SAVE_FREQUENCY: 'Salva frequenza',
  SELECT_FREQUENCY: 'Seleziona la frequenza',
  TEMPLATE_MODIFICATIONS_HINT:
    'Il testo è stato modificato manualmente e non verrà più modificato automaticamente. Fare clic qui per annullare le modifiche e riattivare gli aggiornamenti automatici.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Indirizzo email errato!',
  AUDIT_TRAIL_FOR: 'Traccia di controllo per {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Traccia di controllo per tutti gli {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'La storia cambia per',
  HOLIDAY_GDPR_DISCLAIMER: 'Dichiarazione di non responsabilità GDPR per le festività',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER:
    'Gestisci la dichiarazione di non responsabilità GDPR per le festività',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: 'Modello GDPR festivo mancante per i seguenti team',
  BULK_CREATE: 'Creazione in blocco',
  LAST_SUCCESS: 'Ultimo successo',
  LAST_FAILURE: 'Ultimo fallimento',
  FAILURE: 'Fallimento',
  TASK: 'Compito',
  Weeks: 'Settimane',
  REPEAT_EVERY: 'Ripetere ogni',
  DEACTIVATE_ITEM: 'Disattiva {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Reimposta conteggio errori',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controlla le attività esistenti nella tabella dietro la finestra di dialogo poiché abbiamo già attività con gli stessi valori.',
  ACTIVATE_ITEM: 'Attiva {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Inserisci un numero maggiore di {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      "L'interazione di destinazione selezionata per l'unione è già chiusa. Questa richiesta è una nuova interazione e non è necessario unirla.",
    DESCRIPTION_FOR_OPEN_INTERACTION:
      "Una nuova/e domanda/e verrà aggiunta/e alla richiesta esistente, a tutte le domande dovrà essere data risposta. Seleziona le domande per l'unione.",
    VALIDATION_MSG:
      "Seleziona almeno una domanda dall'interazione unita per procedere con l'unione.",
  },
  MERGED_INTERACTION: 'Interazione unita',
  TARGET_INTERACTION: 'Interazione target',
  MERGE_SUGGESTION: 'Unisci suggerimento',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Si prega di rivedere il suggerimento di unione di seguito',
  INQUIRERS_COMPARISON_TITLE:
    "Tieni presenti diversi richiedenti per l'interazione unita e l'interazione di destinazione",
  INQUIRERS_COMPARISON_QUESTION: 'Conferma se desideri procedere nonostante questa differenza.',
  TERRITORY: 'Territorio',
  DOMAIN: 'Dominio',
  IN: 'In',
  TEAM_GROUP_FILTER: {
    TITLE: 'Definire le autorizzazioni per visualizzare ulteriori team',
    SAVE_BTN_TOOLTIP:
      'Inserisci prima tutti i campi obbligatori per la squadra e salva la squadra.',
  },
  ADR_REF_ID: "Identificativo di riferimento dell'ADR",
  PQC_REF_ID: 'ID di riferimento PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'Questo attaccamento è presente in molteplici interazioni',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    "Seleziona l'opzione della casella di controllo se desideri eliminare l'allegato da tutte le interazioni.",
  CONFIRM_DELETE_ATTACHMENT: 'Conferma se desideri eliminare questo allegato.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Elimina da tutte le interazioni menzionate sopra',
  ATTACHMENT_DELETED: 'Allegato eliminato',
  ROLES: 'Ruoli',
  SET_TO_ALL_INQ_TOOLTIP:
    'Imposta la stessa caratterizzazione ADR, PQC e off-label per tutte le domande.',
  TASKS_LIST: 'Elenco attività',
  TASK_LOGS: 'Registri attività',
  SELECTED: 'Selezionato',
  LICENSE_COUNTER: 'Contatore licenze',
  LICENSES_LIMIT: 'Limite dei numeri di licenza',
  USERS_TO_EXCLUDE: 'Utenti da escludere',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Canoni di licenza contrattati (ruoli utente).',
  SELECT_USER_ROLES_TO_COUNT:
    'Seleziona i ruoli utente applicabili per il conteggio dei costi di licenza.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Escludi gli utenti dal conteggio dei costi di licenza.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Notifiche di ricevute e vendite',
  REFRESH_MIGRATION_ERRORS: "Aggiorna l'elenco degli errori di migrazione",
  MARKED_AS_RESOLVED_BY: 'Contrassegnato come risolto da',
  MARKED_AS_RESOLVED_ON: 'Contrassegnato come risolto il',
  MARK_AS_RESOLVED: 'Segna come risolto',
  ERROR_ON: 'Errore attivato',
  TEXT_WITH_SPECIAL_CHARS:
    'Inserisci solo lettere (a-z, A-Z), numeri (0-9) e simboli speciali come trattino (-), punto (.), spazio, due punti (:), virgola (,), parentesi (()), avanti barra (/) e più (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    "L'interazione è in stato inviato per l'approvazione. Si prega di approvare/rifiutare per abilitare questo menu.",
  REFRESH_TASK_LOGS: "Aggiorna l'elenco dei registri delle attività",
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: 'Conferma se vuoi procedere con le modifiche!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Archiviazione manuale non consentita per i ruoli delegati.',
  RUN_COUNTER: 'Esegui contatore',
  EXPORT_LICENSES: 'Licenze di esportazione',
  LICENSE_COUNT_MESSAGE: 'Il numero di licenze utilizzate è:',
  QUESTIONS: 'Domande',
  CLARIFICATION: 'Una precisazione',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      "Seleziona almeno una domanda nell'interazione di destinazione per procedere con l'unione.",
    DESCRIPTION:
      "La domanda di chiarimento verrà aggiunta sotto le domande selezionate nell'interazione target.",
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      "Seleziona questa opzione per contrassegnare l'interazione come duplicata di un'interazione esistente.",
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    "Unione precedente rilevata come domanda di chiarimento. Tutti i campi di risposta selezionati oltre ai campi di risposta precedentemente selezionati nell'interazione target verranno riaperti per la risposta.",
  DISABLED_ANSWER_FIELD_TOOLTIP:
    "L'interazione è stata riaperta per fornire una risposta a un'altra domanda. La risposta a questa domanda è già stata fornita in precedenza.",
  TIP: 'Mancia',
  MERGE_TIP_TEXT:
    "Se invece desideri riaprire l'interazione {{ MERGED_INTO_ID }}, premi No e avvia un nuovo processo di unione indicando l'interazione target {{ MERGED_INTO_ID }}.",
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    "Stai selezionando un'operazione di unione senza aprire l'interazione di destinazione.",
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    "Stai selezionando un'operazione di unione in un'interazione di destinazione non valida {{ TARGET_INTERACTION_ID }}.<br>L'interazione {{ TARGET_INTERACTION_ID }} è stata chiusa con stato {{ STATUS }}{{ LAST_MERGE_INFO }}.",
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    "Ciò si tradurrà in un'interazione chiusa e nell'impossibilità di fornire una risposta.",
  INTO_INTERACTION: "nell'interazione",
  PLEASE_SELECT_INTERACTION_FOR_MERGE: "Seleziona l'interazione per l'unione.",
  PLEASE_SELECT_MERGE_REASON: "Seleziona il motivo dell'unione.",
  QUESTION_CANNOT_BE_DELETED:
    'Questa domanda non può essere eliminata poiché è associata una domanda di chiarimento senza risposta.',
  MERGE_REASON: 'Unisci motivo',
  WITH_REOPEN: 'con riapertura',
  MERGED_INTO_QUESTION_IDS: 'uniti negli ID domanda',
  MERGED_INTO_QUESTIONS: 'uniti in domanda(i)',
  MERGED_PREVIOUSLY_FROM: 'uniti in precedenza da',
  FROM_INTERACTION_ID: "dall'ID interazione",
  IN_INTERACTION_ID: "nell'ID interazione",
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Stai per annullare il suggerimento di unione.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Non mostrare più il suggerimento di unione?',
  PRODUCT_RESOLVED_VIA_AI: 'Prodotto (risolto tramite AI)',
  REQUIRED_FIELDS_HINT: 'Si prega di compilare tutti i campi richiesti',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'È richiesto almeno un contatto!',
  DISCLAIMER_INFO: 'Informazioni sulla dichiarazione di non responsabilità',
  QUESTION_ID: 'ID della domanda',
  QUESTION_IDS: 'ID delle domande',
  SHOW_TEAMS: 'Mostra squadre',
  LAST_POLLING: 'Ultimo sondaggio',
  HOST: 'Ospite',
  PROTOCOL: 'Protocollo',
  FROM_EMAIL: "Dall'email",
  REASON_FOR_REMOVAL: 'Motivo della rimozione',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: "Un'interazione chiusa non è modificabile.",
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    "L'interazione ha più di una domanda. La modifica è possibile solo dall'interazione aperta.",
  BULK_EDIT: 'Modifica collettiva',
  HOW_TO_ENABLE_BULK_EDIT:
    'Per eseguire un aggiornamento collettivo è necessario selezionare almeno due modelli nella stessa lingua.',
  REASON_FOR_UPDATE: "Motivo dell'aggiornamento",
  FOLLOW_UP_RESEND: 'Il follow-up è stato inviato nuovamente',
  SENT_FOLLOW_UP_REMINDER: 'inviato un promemoria di follow-up',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'interazione chiusa e inviata persa alla notifica di follow-up',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Inserisci un testo con una lunghezza minima di 15 caratteri.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Follow-up disabilitato! Per favore inserisci prima il richiedente.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Richiesta di follow-up disabilitata! Inserisci prima un indirizzo email valido del richiedente.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Il numero per il tipo di frequenza selezionato deve essere compreso tra 1 e {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Seleziona il tipo di frequenza.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    "Il conteggio inizia dalla data e dall'ora della richiesta di follow-up iniziale. Per la conversione da giorni a settimane moltiplicare per 7, ad es. 2 settimane = 14 giorni, per giorni o mesi moltiplicare per 30, ad es. 1 mese = 30 giorni.",
  VALID_PHONE_FAX: 'Inserisci un numero di telefono/fax valido!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Seleziona una lingua per visualizzare e gestire i modelli tradotti',
  RETRANSLATE: 'Ritradurre',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'I modelli seguenti sono solo in lingua inglese, le traduzioni sono gestite di seguito',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Tutti i modelli sopra riportati verranno ripristinati ai valori predefiniti (la modifica manuale verrà rimossa, la frequenza reimpostata su 14 giorni e la casella di controllo della traduzione deselezionata), ritradotti in tutte le lingue e verranno cancellati is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Modifica il testo del modello e ritraduci tutte le traduzioni che non vengono modificate manualmente.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Ritradurre il testo del modello originale, compresi quelli modificati manualmente.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Modelli di promemoria di follow-up',
  MANUALLY_MODIFIED: 'Modificato manualmente',
  SYNC: 'Sincronizzazione',
  DIFFERENT: 'Diverso',
  DEFAULT_CONFIRMATION_QUESTION: 'Per favore conferma se vuoi procedere.',
  SEND_WELCOME_MAIL_: 'Invia e-mail di benvenuto con la reimpostazione della password',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    "Confermo che il richiedente ha ricevuto una nuova informazione di follow-up e che desidero riaprire l'interazione per fornire la risposta.",
  ONLY_LETTERS_SPACE_AND_DASH: 'Inserisci solo lettere, spazi e il simbolo -!',
  INFO: 'Informazioni',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    "La modifica del Paese del richiedente comporterà una modifica dell'assegnazione dell'interazione dal team {{ CURRENT_TEAM }} al team {{ FUTURE_TEAM }}.",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    "La modifica del Paese del richiedente modificherà l'assegnazione dell'interazione dal team {{ CURRENT_TEAM }} al team {{ FUTURE_TEAM }}. L'utente {{ ASSIGNED_TO }} non ha le autorizzazioni per il team {{ FUTURE_TEAM }} e la sua assegnazione personale verrà rimossa.",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'La modifica avrà effetto sulle assegnazioni di squadra nelle seguenti interazioni aggiuntive, in cui le assegnazioni personali saranno rimosse: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'La modifica del Paese del richiedente comporterà la modifica delle assegnazioni del team nelle seguenti interazioni aggiuntive, in cui le assegnazioni personali verranno rimosse. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    "ID interazione: {{ INTERACTION_ID }} da {{ CURRENT_TEAM }} a {{ FUTURE_TEAM }}, assegnazione personale rimossa dall'utente {{ ASSIGNED_TO }}",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utente {{ EDITING_USER }} ha modificato il Paese dell'enquirer {{ ENQUIRER }} nell'ID interazione: {{ UPDATED_INTERACTION_ID }}, modificando l'assegnazione dell'interazione corrente dal team {{ OLD_TEAM }} al team {{ NEW_TEAM }}.",
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utente {{ EDITING_USER }} ha modificato il Paese dell'enquirer {{ ENQUIRER }} nell'amministrazione HCP, modificando l'assegnazione dell'interazione corrente dal team {{ OLD_TEAM }} al team {{ NEW_TEAM }}.",
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utente {{ INTERACTION_ASSIGN_TO }} non ha le autorizzazioni per il team {{ NEW_TEAM }} e la sua assegnazione personale è stata rimossa.",
  PERSONAL_ASSIGNMENT: 'Compito personale',
  DUE_TO_FU_REQUEST: 'a causa di una richiesta di follow-up',
  DUE_TO_FU_RESPONSE: 'a causa della risposta di follow-up',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    "a causa dell'aggiornamento della frequenza dei promemoria di follow-up dalla pagina di amministrazione",
  UPDATED_FROM_INBOX: 'aggiornato dalla posta in arrivo',
  ARCHIVED_ROLE: 'Ruolo archiviato',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    "L'archiviazione del ruolo utente modificherà le assegnazioni nelle seguenti interazioni aggiuntive, in cui le assegnazioni personali verranno rimosse: {{ USER_INTERACTIONS }}",
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utente {{ EDITING_USER }} ha modificato l'utente {{ INTERACTION_ASSIGN_TO }}, archiviando il suo ruolo come {{ USER_ROLE }} nel team {{ TEAM }}. L'utente {{ INTERACTION_ASSIGN_TO }} non ha le autorizzazioni per il team {{ TEAM }} e la sua assegnazione personale è stata rimossa.",
  ASSIGNMENT: 'Assegnazione',
  PERIOD_TO_PERIOD_OVERLAP: 'Periodo e Periodo a si sovrappongono',
  PERIOD_TO_PERIOD_MISMATCH:
    'Periodo e A periodo hanno durata diversa: {{ PERIOD }} {{ INTERVAL }} e {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'domanda creata dalla risposta di follow-up',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Questa opzione consente di creare una nuova domanda a partire da un follow-up.',
  ADD_AS_A_NEW_QUESTION: 'Aggiungi come nuova domanda',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    "Tieni presente che stai tentando di archiviare l'ultimo ruolo approvato per questo utente, il che causerà la disattivazione dell'utente e gli impedirà di accedere ulteriormente a EnqMed.",
  ATTEMPTED_FOLLOW_UP: 'ha tentato un follow-up',
  FAILED_FU_ATTEMPT: 'Tentativo di follow-up non riuscito',
  UNABLE_TO_REACH_ENQUIRER: 'Impossibile contattare il richiedente.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Selezionare questa opzione se è stato effettuato un tentativo di contattare il richiedente ma non ha avuto successo, ad esempio impossibile contattarlo telefonicamente, appuntamento F2F o altro appuntamento non riuscito. Il tentativo verrà registrato nella cronologia delle interazioni.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Verrai reindirizzato a una pagina per la quale hai i permessi.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    "a causa dell'interruzione del processo di follow-up a seguito della modifica del richiedente",
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Stai per cambiare il richiedente, il che interromperà il processo di follow-up.',
  INQUIRER_DETAILS: 'Dettagli del richiedente',
  INQUIRER_TYPE: 'Tipo di richiedente',
  DELETE_INQUIRER_PII: 'Eliminare le informazioni personali del richiedente',
  MARKED_INQUIRER_PII_DELETE:
    'informazioni personali del richiedente contrassegnate per la cancellazione',
  UNMARKED_INQUIRER_PII_DELETE:
    "informazioni personali del richiedente non contrassegnate per l'eliminazione",
  NEW_ENQUIRER: 'Nuovo richiedente',
  NEW_ENQUIRER_TOOLTIP:
    "Usa questa opzione per creare un enquirer completamente nuovo. Se invece è necessario modificare i dati dell'enquirer esistente, non usare questa opzione.",
  CONFIRM_SET_NEW_ENQUIRER:
    'Si prega di confermare che si desidera sostituire il richiedente esistente con uno nuovo.<br>Tutte le informazioni immesse andranno perse.<br>Se invece si desidera modificare i dati del richiedente esistente, modificare i dati direttamente nel modulo e premere Salva.',
  INQUIRER: 'Richiedente',
  KEEP_INQUIRER_DETAILS_OPEN: 'Mantieni aperti i dettagli del richiedente',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Sono presenti modifiche non salvate che andranno perse se si chiudono i dettagli del richiedente.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Condividi: da qui puoi assegnare, inoltrare o avviare un follow-up con il richiedente.',
  UPDATED_INQUIRER_DETAILS: 'dettagli aggiornati del richiedente',
  CREATED_INQUIRER: 'creato richiedente',
  SUGGESTED_INQ: 'Richiedenti suggeriti',
  SELECT_INQUIRER: 'Seleziona il richiedente',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    "Per poter inviare l'interazione è necessario selezionare il consenso all'obbligo ADR e il richiedente",
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    "La risposta non è stata inviata al richiedente a causa dell'indirizzo e-mail errato.",
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Grazie! La notifica verrà inviata una volta inviata la richiesta o ricevuta risposta.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Grazie! La notifica verrà inviata una volta salvato il richiedente.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'La seguente notifica GDPR verrà inviata al richiedente via e-mail.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'La seguente notifica GDPR è stata inviata al richiedente via e-mail.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Inviare la seguente notifica GDPR al richiedente via e-mail.',
  INQ_HAS_NO_EMAIL: 'il richiedente non ha un indirizzo email.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'NON è stata inviata al richiedente una notifica che la richiesta è stata ricevuta.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Notifica inviata al richiedente che la richiesta è stata ricevuta.',
  THIS_IS_JUST_A_THANK_YOU:
    'Questo è solo un messaggio di ringraziamento da parte del richiedente della richiesta esistente.',
  SAVE_INQUIRER: 'Salvare il richiedente',
  INQ_DEFAULT_VAL: 'Valori predefiniti di Enquirer',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Per le richieste inviate tramite un rappresentante aziendale (rappresentante commerciale, MSL o altro), informare il richiedente in merito al trattamento dei suoi dati personali.',
  SELECT_PREFERRED_CONTACT_ERROR:
    'È necessario selezionare un contatto preferito per il richiedente.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Follow-up avviato dal richiedente',
  NO_INQUIRER: 'Nessun richiedente',
  'Changed inquirer': 'Richiedente modificato',
  QUESTION_REQUIRE_ANSWER_MESSAGE: "Per chiudere l'indagine sarà necessaria una nuova risposta.",
  IMAGE: 'Immagine',
  THERAPEUTIC_AREA_STATUS_MESSAGE: "L'Area Terapeutica è {{ STATUS }}",
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ", poiché non ci sono prodotti associati. Seleziona almeno un prodotto per impostare l'area terapeutica come attiva.",
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Espandi tutte le aree terapeutiche',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Chiudi tutte le aree terapeutiche',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    "Per impostare un'Area Terapeutica (AT) come AT principale, deselezionare l'AT padre e salvare.",
  EXTRA_STEP_REQUIRED_TO_LOGIN: "Passaggio aggiuntivo richiesto per l'accesso",
  EXTRA_STEP_DESCRIPTION:
    'La conferma di accesso è stata inviata alla tua email. Per favore, segui i passaggi indicati, per continuare con il sistema.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Inserisci un testo con una lunghezza minima di 15 caratteri o un allegato.',
  REASON_FOR_REJECTION: 'Motivo del rifiuto',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'La nota è stata correttamente inserita nella cronologia.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Con {{ ACTION_TYPE }} il {{ METADATA_TYPE }} selezionato, verranno applicate le seguenti modifiche aggiuntive correlate:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Rimuovendo i prodotti, verranno applicate le seguenti ulteriori modifiche correlate:',
  TEAM_NOTIFY_INQUIRER: 'Avvisare il richiedente',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Abilitando questa opzione verranno inviate automaticamente notifiche GDPR ai richiedenti in interazioni aperte in questo team che non sono ancora stati informati.',
  TEAM_NOTIFY_SALES: 'Avvisare le vendite',
  TEAM_NOTIFY_SALES_TOOLTIP:
    "Abilitando questa opzione verrà attivata la notifica relativa alla risposta per tutti gli utenti con il ruolo di vendita in questo team.\n\nDisabilitando questa opzione verrà disattivata la notifica relativa alla risposta per tutti gli utenti con il ruolo di vendita in questo team, a meno che non abbiano il ruolo di vendita in un altro team con l'impostazione di notifica vendite abilitata.",
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'riceve la seguente notifica',
  EMAILS: 'E-mail',
  PHONES: 'Telefoni',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Utente/i disattivato/i con successo',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Conferma la disattivazione in blocco degli utenti',
  DEACTIVATE_SELECTED_USERS: 'Disattiva gli utenti selezionati',
  SELECT_USERS_TO_DEACTIVATE: 'Seleziona gli utenti da disattivare',
  ARCHIVE_FOLDER: 'Cartella di archivio',
  EXTRA_PARAMS:
    "Parametri extra (k1=v1&k2=v2, 'cartelle' e 'archivio' sono riservati e non possono essere utilizzati)",
  POLL_FOLDER: 'Cartella del sondaggio',
  POLL_FOLDERS_TEXT:
    "Le cartelle dei sondaggi possono essere impostate solo per i protocolli imap o gmail. Per tutti gli altri protocolli, l'impostazione predefinita è la posta in arrivo.",
  POLL_FOLDERS_TOOLTIP_1:
    "Digitare il nome della cartella e premere Invio oppure utilizzare una virgola per aggiungerla per l'interrogazione dalla casella di posta (se non si aggiunge alcuna cartella, l'interrogazione verrà eseguita per impostazione predefinita nella Posta in arrivo).",
  POLL_FOLDERS_TOOLTIP_2:
    'I nomi delle cartelle qui elencati verranno interrogati dalla casella di posta.',
  PERMISSION: 'Permesso',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Si prega di controllare i ruoli esistenti nella tabella dietro la finestra di dialogo, poiché esiste già un ruolo con lo stesso nome.',
  CURR_MAILBOX: 'Casella di posta corrente',
  ENQUIRY_ANSWER_APPROVAL: 'Approvazione della risposta alla richiesta',
  REASON_FOR_INCLUSION: "Motivo dell'inclusione",
  FOLDERS_TO_POLL_FROM_MAILBOX: 'Cartelle da cui effettuare il polling dalla casella di posta',
  VERIFY_SETUP: 'Verifica configurazione',
  HTML_PREVIEW: 'Anteprima HTML',
  IN_REPLY_TO_ID: 'In risposta a id',
  INCOMING: 'In arrivo',
  OUTGOING: 'In uscita',
  VIEW: 'Visualizzazione',
  ROLE_IN_USE_USERS:
    'Il ruolo è attualmente assegnato agli utenti e la sua archiviazione potrebbe limitare i loro diritti di accesso',
  ROLE_IN_USE_USER_TEMPLATES:
    "Il ruolo è attualmente utilizzato dai modelli utente e l'archiviazione lo rimuoverà da loro",
  ROLE_IN_USE_LICENSE_COUNTER:
    'Il ruolo è attualmente utilizzato nel contatore delle licenze e archiviandolo verrà rimosso da esso.',
  REASON_FOR_REPLACEMENT: 'Motivo della sostituzione',
  QUESTION_TEXT_AFTER_SPLIT: 'Testo della domanda dopo la divisione',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'Il flusso di lavoro di approvazione delle risposte alle richieste verrà disabilitato per il team. Le interazioni nello stato "Submitted for Approval" saranno interessate e riportate allo stato "In Progress" per consentire la corretta preparazione delle risposte e la risposta senza approvazione.',
  REVERT_TO_IN_PROGRESS: 'RITORNARE A IN CORSO',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    "È stata eseguita la divisione della domanda, mentre l'interazione è nello stato {{ SUBMITTED_FOR_APPROVAL }}. Questa azione riporta lo stato a {{ IN_PROGRESS }} per consentire la preparazione della risposta per la/le domanda/e appena creata/e.",
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    "Azione {{ MERGE_AS_ADDITIONAL_QUESTION }} eseguita nell'interazione corrente, mentre l'interazione di destinazione è nello stato {{ SUBMITTED_FOR_APPROVAL }}. Questa azione riporta lo stato a {{ IN_PROGRESS }} per consentire la preparazione della risposta per la/le domanda/e appena creata/e.",
  ENQUIRY_ANSWER_APPROVAL_TITLE:
    "Imposta l'approvazione della risposta alla richiesta per i team selezionati",
  APPROVAL_ASSIGN_TO: 'Approvazione richiesta assegnata a',
  APPROVAL_ASSIGN_BY: 'Richiesta approvazione assegnata da',
  APPROVAL_ASSIGNMENTS: 'Assegnazioni di approvazione Enq.',
  APPROVAL_ASSIGNMENTS_TO: "Richiedere l'approvazione delle assegnazioni a",
  APPROVAL_ASSIGNMENTS_BY: 'Incarichi di approvazione da parte di Enq.',
  My_pending_approvals: 'Le mie approvazioni in sospeso',
  RULE: 'Regola',
  RULES: 'Regole',
  FIELD: 'Campo',
  CRITERIA: 'Criteri',
  NUMBER: 'Numero',
  MIN: 'Minimo',
  MAX: 'Massimo',
  ADD_GROUP: 'Aggiungi gruppo',
  DELETE_GROUP: 'Elimina gruppo',
  VALID_FROM_TO: 'Valido da/a',
  NEW_INQ: 'Nuova domanda',
  NOT_APPROVING_INQUIRIES: 'Non approvare le richieste',
};
