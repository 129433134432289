// The const names and values of this file are supposed to match with mis/model/consts.py
// on backend - this would allow easier search where permission is used
export const PERM_VIEW_ENQUIRIES = 'View Enquiries';
export const PERM_PROCESS_ENQUIRIES = 'Process Enquiries';
export const PERM_APPROVE_ENQUIRIES = 'Approve Enquiries';
export const PERM_ANSWER_WITHOUT_APPROVAL = 'Answer Without Approval';
export const PERM_LIMIT_VIEW_ENQUIRIES = 'Limit View Enquiries';
export const PERM_VIEW_ENQUIRY_DRAFTS = 'View Enquiry Drafts';
export const PERM_VIEW_ENQUIRY_SHARE = 'View Enquiry Share';
export const PERM_VIEW_ENQUIRER_NOTIFICATIONS = 'View Enquirer Notifications';
export const PERM_VIEW_ENQUIRY_ANSWER_BCC = 'View Enquiry Answer BCC';
export const PERM_VIEW_REPOSITORY = 'View Repository';
export const PERM_MANAGE_DOCUMENTS = 'Manage Documents';
export const PERM_VIEW_REPORTS = 'View Reports';
export const PERM_VIEW_THERAPEUTIC_AREAS = 'View Therapeutic Areas';
export const PERM_VIEW_USER_ADMIN = 'View User Admin';
export const PERM_MANAGE_ALL_USERS = 'Manage All Users';
export const PERM_MANAGE_OWN_USERS = 'Manage Own Users';
export const PERM_VIEW_HCP_ADMIN = 'View HCP Admin';
export const PERM_VIEW_EVENTS = 'View Events';
export const PERM_VIEW_MAILBOXES = 'View Mailboxes';
export const PERM_VIEW_TASKS = 'View Tasks';
export const PERM_VIEW_MIGRATION_ERRORS = 'View Migration Errors';
export const PERM_VIEW_METADATA = 'View Metadata';
export const PERM_VIEW_TEAMS = 'View Teams';
export const PERM_MANAGE_USER_AUTH_REQUESTS = 'Manage User Auth Requests';
export const PERM_MANAGE_CAT_TOP_AUTH_REQUESTS = 'Manage Category Topic Auth Requests';
export const PERM_VIEW_ROLES = 'View Roles';
export const PERM_VIEW_RULES = 'View Rules';
export const PERM_ADMINISTRATE_SYSTEM = 'Administrate System';

export const PERMS_ACCESS_ENQUIRIES = [PERM_VIEW_ENQUIRIES, PERM_PROCESS_ENQUIRIES];
export const PERMS_ACCESS_AUTH_REQUESTS = [
  PERM_MANAGE_USER_AUTH_REQUESTS,
  PERM_MANAGE_CAT_TOP_AUTH_REQUESTS,
];

export function comparePermOrder(a: string, b: string): number {
  return PERMS_DEFAULT_ORDERING.indexOf(a) - PERMS_DEFAULT_ORDERING.indexOf(b);
}

const PERMS_DEFAULT_ORDERING: string[] = [
  PERM_VIEW_ENQUIRIES,
  PERM_PROCESS_ENQUIRIES,
  PERM_APPROVE_ENQUIRIES,
  PERM_ANSWER_WITHOUT_APPROVAL,
  PERM_LIMIT_VIEW_ENQUIRIES,
  PERM_VIEW_ENQUIRY_DRAFTS,
  PERM_VIEW_ENQUIRY_SHARE,
  PERM_VIEW_ENQUIRER_NOTIFICATIONS,
  PERM_VIEW_ENQUIRY_ANSWER_BCC,
  PERM_VIEW_REPOSITORY,
  PERM_MANAGE_DOCUMENTS,
  PERM_VIEW_REPORTS,
  PERM_VIEW_THERAPEUTIC_AREAS,
  PERM_VIEW_USER_ADMIN,
  PERM_MANAGE_ALL_USERS,
  PERM_MANAGE_OWN_USERS,
  PERM_VIEW_HCP_ADMIN,
  PERM_VIEW_EVENTS,
  PERM_VIEW_MAILBOXES,
  PERM_VIEW_TASKS,
  PERM_VIEW_MIGRATION_ERRORS,
  PERM_VIEW_METADATA,
  PERM_VIEW_TEAMS,
  PERM_MANAGE_USER_AUTH_REQUESTS,
  PERM_MANAGE_CAT_TOP_AUTH_REQUESTS,
  PERM_VIEW_ROLES,
  PERM_VIEW_RULES,
  PERM_ADMINISTRATE_SYSTEM,
];
