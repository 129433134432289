export const portugueseLangData = {
  MEDICAL_INFO_SYSTEM: 'Sistema de Informação Médica',
  ACTIVE_EVENTS: 'Eventos ativos',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Selecione um evento para procurar usuários.',
  BACK_TO_THE_EVENT_SELECTION: 'Voltar para a seleção de eventos',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Se você esqueceu sua senha',
  LOGIN: 'Conecte-se',
  RESET_IT_HERE: 'redefina aqui',
  CHANGE_LANGUAGE_TO: 'Alterar idioma para',
  TEST_SYSTEM: 'SISTEMA DE ENSAIO',
  HELP: 'Socorro',
  THIS_IS_TEST_ENV:
    'Este é um ambiente de teste. Se você deseja inserir uma consulta real, vá para',
  USER_OR_PASS_IS_INCORRECT: 'Nome de usuário ou senha está incorreta',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Não encontramos o que você estava procurando',
  VIDEO_TUTORIALS: 'Tutoriais em vídeo',
  STILL_DIDNT_FIND: 'Ainda não encontrou o que estava procurando,',
  ASK_THE_SUPPORT_TEAM: 'Pergunte à equipe de suporte',
  LOGGED_IN_AS: 'logado como',
  MY_PROFILE: 'Meu perfil',
  LOGOUT: 'Sair',
  RETRIEVING_YOUR_ACCESS_INFO: 'Recuperando suas informações de acesso',
  LOGGED_IN_CAN_CLOSE: 'Conectado, agora você pode fechar esta guia',
  TOKEN_HAS_NOT_BEEN_SAVED: 'O token não foi salvo corretamente, entre em contato com o suporte',
  PASSWORD_RESET: 'Redefinição de senha',
  RESET_PASSWORD: 'Redefinir senha',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'Por favor insira seu email para redefinir sua senha',
  CANCEL: 'Cancelar',
  CANCEL_UPPERCASE: 'CANCELAR',
  RETURN_TO_LOGIN: 'Voltar ao Login',
  USERNAME: 'Nome do usuário',
  USERNAME_IS_REQUIRED: 'Nome de usuário é requerido',
  PASSWORD: 'Senha',
  PASSWORD_IS_REQUIRED: 'Senha requerida',
  FIRST_NAME: 'Primeiro nome',
  LAST_NAME: 'Último nome',
  REGISTER: 'Registro',
  LOADING: 'Carregando',
  INSERT_NEW_PASS: 'Por favor insira sua nova senha',
  VERIFYING_TOKEN: 'Verificando token',
  PASSWORD_DO_NOT_MATCH: 'As senhas não coincidem',
  PASS_RESET_SUCCESSFUL: 'Redefinição de senha bem-sucedida',
  SAVE_NEW_PASS: 'Salvar nova senha',
  GO_TO_INBOX: 'Ir para a caixa de entrada',
  SELECT_ALL: 'Selecionar tudo',
  DESELECT_ALL: 'Desmarcar todos',
  DO_NOT_SHOW_AGAIN: 'Não mostrar novamente',
  INQUIRY: 'Inquérito',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Anexos com esta consulta',
  USER_DETAILS: 'Detalhes do usuario',
  CLOSE: 'Fechar',
  TEAMS: 'Equipas',
  TEAM: 'Equipa',
  SPECIALIZATION_IS: 'Especialização é',
  REQUIRED: 'requeridas',
  RESULTS_PER_PAGE: 'Resultados por página',
  RESULTS_FOUND: 'Resultados encontrados',
  EXPORT: 'Exportação',
  PERSONAL_SETTINGS: 'Configurações pessoais',
  TEAM_SETTINGS: 'Configurações da equipe',
  USER_SETTINGS_OF: 'CONFIGURAÇÕES DO USUÁRIO DE',
  SET_TEMPLATE: 'Definir modelo',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'As notificações serão substituídas pelas atuais',
  PREVIEW: 'Pré-visualização',
  NOTIFICATIONS: 'NOTIFICAÇÕES',
  ROLE: 'FUNÇÃO',
  TEMPLATE_NAME: 'NOME DO MODELO',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Salvar este usuário como modelo',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Adicione um modelo ao seu usuário e copie a função e as preferências para notificações',
  AUTH_AND_NOTIFY_TEMPLATE: 'Modelo de autorização e notificações',
  MY_ROLES: 'Meus papéis',
  STATUS: 'STATUS',
  THIS_ROLE_IS_TEMP: 'Esse papel é temporário, devido ao fato de esse usuário ser um deputado',
  CREATED: 'CRIADA',
  APPROVED_REJECTED: 'APROVADO / REJEITADO',
  APPROVED_REJECTED_BY: 'APROVADO / REJEITADO POR',
  ARCHIVE: 'ARQUIVO',
  SAVE: 'Salve',
  IMMEDIATE_EFFECT: 'Efeito imediato',
  OUT_OF_OFFICE_DEPUTY: 'DEPUTADO FORA DO ESCRITÓRIO',
  AUTO_ASSIGN_TO: 'ESTE EXCELENTE',
  GEOGRAPHY: 'GEOGRAFIA',
  ALIGNMENT: 'ALINHAMENTO',
  Alignments: 'Alinhamentos',
  Address: 'Endereço',
  PHONE_FAX: 'Telefone / Fax',
  Email: 'O email',
  Contacts: 'Contatos',
  WORK_INFO: 'Informações sobre o trabalho',
  LAST_NAME_IS: 'Último nome é',
  PERSONAL_INFO: 'Informação pessoal',
  USER: 'Do utilizador',
  ID: 'ID',
  UPDATED_FROM: 'atualizado de',
  ON: 'em',
  CRM: 'CRM',
  IMPORTED_FROM: 'importado de',
  CHANGE_PASS: 'Mudar senha',
  NOTIFICATION_IN_TEMPLATE: 'Notificações no modelo',
  CONTINUE: 'Continuar',
  DONT_SHOW_AGAIN: 'Não mostrar esta mensagem novamente',
  USING_THE_TABS_ON_THE: 'usando as guias na parte superior da tela',
  PERSONAL_PROFILE_SETTINGS: 'Configurações de perfil pessoal',
  AND_YOUR: 'e seu',
  YOU_CAN_SWITCH_BETWEEN: 'Você pode alternar entre',
  WELCOME_TO_YOUR_USER_PROFILE: 'Bem-vindo ao seu perfil de usuário',
  SAVE_THIS_USER_AS: 'Salvar este usuário como modelo',
  INCLUDE_NOTIFY_SETTINGS: 'Inclui configurações de notificação',
  INCLUDE_ROLES_SETTINGS: 'Inclui configurações de funções',
  ACTIVE_NOTIFICATIONS: 'NOTIFICAÇÕES ATIVAS',
  SET_THIS_USER_AS_A_: 'Defina esta autorização do usuário e notificações como novo modelo',
  REQUEST_AUTH: 'Solicitar autorização',
  REQUEST: 'Solicitação',
  FEEDBACK_SUPPORT: 'Feedback e Suporte',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Se você ainda não encontrar o que está procurando, pergunte à equipe de suporte! Além disso, se você tiver uma sugestão para futuras melhorias, este é o lugar certo.',
  TEMPLATE: 'Modelo',
  SIGNATURE: 'Assinaturas',
  Disclaimers: 'Isenções de responsabilidade',
  Greetings: 'saudações',
  CUSTOMIZE: 'CUSTOMIZAR',
  SAVE_UPPERCASE: 'SALVE',
  DELETE: 'EXCLUIR',
  RESET_TO_DEFAULT: 'RESTAURAR AO PADRÃO',
  AFTER_SIGNATURE: 'Após assinatura',
  BEFORE_SIGNATURE: 'Antes da assinatura',
  AFTER_ANSWER: 'Após resposta',
  BEFORE_ANSWER: 'Antes da resposta',
  AFTER_GREET: 'Depois dos cumprimentos',
  BEFORE_GREET: 'Antes dos cumprimentos',
  Any: 'Qualquer',
  HCP: 'HCP',
  NO_HCP: 'Sem HCP',
  Position: 'Posição',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Você está tentando personalizar o modelo',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'Você não tem permissão para fazê-lo. Se você quiser prosseguir, uma cópia do modelo será criada e você fará as alterações lá.',
  CREATE_COPY: 'Criar cópia',
  RECEIVED_ON: 'Recebido em',
  SENDER_EMAIL: 'Enviando Email',
  SUBJECT: 'Assunto',
  UNSPAM: 'Desfazer spam',
  UPLOAD: 'Envio',
  APPROVED: 'Aprovada',
  DRAFT: 'Esboço, projeto',
  SELECT_STATUS: 'Selecione Status',
  UPLOAD_AS: 'Carregar como',
  TITLE: 'Título',
  REPOSITORY: 'Repositório',
  BROWSE: 'Squeaky toy',
  URL: 'URL',
  APPROVED_ON: 'Aprovado em',
  AUTH_HOLDER: 'Titular da autorização',
  AUTH_NUMBERS: 'Números de autorização',
  APPROVED_FOR: 'Aprovado para',
  SELECT_ONE_REGION: 'Selecione uma região',
  OWNED_BY: 'Propriedade de: ',
  KEYWORDS: 'Palavras-chave',
  TOPICS: 'Tópicas',
  CATEGORIES: 'Categorias',
  PRODUCTS: 'Produtos',
  PRODUCT: 'produtos',
  LANGUAGE_IS_REQUIRED: 'O idioma é obrigatório',
  LANGUAGE: 'Língua',
  TARGET_CUSTOMERS: 'Clientes alvo',
  TYPE_IS_REQUIRED: 'O tipo é obrigatório',
  TYPE: 'Tipo',
  TITLE_IS_REQUIRED: 'O título é obrigatório',
  DEFAULT_REFERENCE_IS_CREATED_:
    'A referência padrão é criada com o estilo Vancouver. Se você deseja substituir isso, insira a referência inteira aqui',
  REFERENCE: 'Referência',
  DOI: 'DOI',
  PUBMED_ID: 'ID Pubmed',
  PAGES: 'Páginas',
  ISSUE: 'Questão',
  VOLUME: 'Volume',
  JOURNAL: 'Diária',
  AUTHORS: 'Autoras',
  ABSTRACT: 'Resumo',
  REFERENCE_IS_REQUIRED: 'Referência é necessária',
  THIS_DOC_IS_APPROVED: 'Este documento foi aprovado. Clique aqui para abrir o',
  ORIGINAL_DOCUMENT_AND_: 'documento original e insira seus comentários',
  EDIT_FILE: 'Editar arquivo',
  PROPERTIES: 'Propriedades',
  OPEN: 'Aberto',
  LOCALIZED_DOCUMENTS: 'Documentos localizados',
  ORIGINAL_DOCUMENTS: 'Documento original',
  INTERNAL_APPROVAL_NUMBER: 'Número de aprovação interno',
  CREATED_BY: 'Criado por',
  EXPIRATION_DATE: 'Data de validade',
  VERSION_NUMBER: 'Número da versão',
  NO_VERSION_AVAILABLE: 'Nenhuma versão disponível',
  ARCHIVED_IF_NO_DRAFT: 'Arquivado: se não houver versões Rascunho / Aprovadas.',
  APPROVED_FOR_USERS: 'Aprovado: para usuários.',
  DRAFT_FOR_AUTHORS_: 'Rascunho: para autores e editores.',
  VERSION_LEGEND: 'Legenda da versão',
  VERSION: 'versão',
  SELECT_ONE_LANG: 'Selecione um idioma.',
  SELECT_ONE_TYPE: 'Selecione um tipo.',
  INTERNAL_NUMBER_REQUIRED: 'É necessário um número de aprovação interno.',
  FILE: 'Arquivo',
  ATTACH_FILE: 'Anexar arquivo',
  LITERATURE: 'Literatura',
  SLIDE_DECK: 'Conjunto de slides',
  PIL: 'PIL',
  FAQ: 'Perguntas frequentes',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Adicionar etapa de aprovação',
  ADD_PARALLEL_STEP: 'Adicionar etapa paralela',
  DELETE_STEP: 'Excluir etapa',
  DELETE_ALL_PARALLEL_STEPS: 'Excluir todas as etapas paralelas',
  CHANGE_STATUS_NAME: 'Alterar nome do status',
  SET_REQUIRED_ROLE: 'Definir função necessária',
  SET_TEAM_MEMBER: 'Definir membro da equipe',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Você não tem permissão para alterar esse processo.',
  SAVE_ROLE: 'SALVAR PAPEL',
  CREATE_NEW_WORKFLOW: 'CRIAR NOVO FLUXO DE TRABALHO',
  CREATE_NEW_STEP: 'CRIAR NOVO PASSO',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Digite o número de etapas de aprovação paralela, significando etapas aprovadas ao mesmo tempo (não sequencialmente). Se você não tiver nenhuma etapa de aprovação paralela, insira 1. Você sempre pode alterar o número de etapas de aprovação posteriormente.',
  APPROVAL_MUST_BE_AT_LEAST: 'A aprovação deve ser pelo menos',
  INSERT_THE_NEW_STATUS_NAME: 'Inserir o novo nome do status',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Insira o novo nome do fluxo de trabalho.',
  INSERT_THE_NEW_STEP_NAME: 'Insira o novo nome da etapa.',
  NEW_DOCUMENT: 'Novo Documento',
  CREATE: 'Crio',
  DOCUMENT_MANAGEMENT: 'Gerenciamento de documento',
  CONFIRM: 'confirme',
  CONFIRM_PROCESS: 'CONFIRMAR PROCESSO',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINE AS ETAPAS DE APROVAÇÃO E A EQUIPE',
  SET_DETAILS: 'DETALHES DO SET',
  SELECT_TYPE: 'SELECIONE O TIPO',
  COMPLETE_LOCALIZATION_PROCESS: 'Processo completo de localização',
  CREATE_NEW_VERSION: 'Criar nova versão',
  CREATE_NEW_DOCUMENT: 'Criar novo documento',
  IS_PRIVATE: 'É Privado',
  SHARED_WITH_OTHER_TEAMS: 'Compartilhado com outras equipes',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Digite o número de etapas de aprovação sequencial necessárias. Se duas aprovações estiverem em paralelo, isso conta como uma etapa. Você sempre pode alterar o número de etapas de aprovação posteriormente.',
  DONE: 'Feita',
  SUBMIT_FOR_APPROVAL: 'Enviar para aprovação',
  YOU_HAVE_TO_CONFIRM_THE_: 'Você precisa confirmar o processo antes do upload.',
  SELECT_YOUR_DEC_TYPE: 'Selecione seu tipo de documento',
  TARGET_AUDIENCE: 'Público-alvo',
  PROCESS_DETAILS: 'Detalhes do processo',
  START_DATE: 'Data de início',
  DOC_DETAILS: 'Detalhes do Documento',
  CONFIRMATION: 'Confirmação',
  APPROVAL_PROCESS: 'Processo de aprovação',
  SCHEDULED_EXP_DATE: 'Data de Expiração Programada',
  SCHEDULED_PUBLISHING_DATE: 'Data de publicação agendada',
  NO_PROCESS_SELECTED: 'Nenhum processo selecionado',
  COUNTRIES: 'Países',
  DOC_ID: 'ID do documento',
  THERAPEUTIC_AREA: 'Área Terapêutica',
  FILTER: 'Filtro',
  BACK: 'costas',
  ATTACH: 'Anexar',
  COPY_ANSWER: 'Copiar resposta',
  SIMILAR_INQUIRIES: 'Pesquisas semelhantes',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Slide Decks',
  MEDICAL_DOC: 'Documento Médico',
  SHOW: 'mostrar',
  LOAD_MORE: 'Carregue mais',
  NO_RESULT_FOUND: 'Nenhum resultado encontrado.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'pode ser usado para procurar uma frase inteira, - pode ser usado para excluir dos resultados da pesquisa na palavra especificada',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Pesquise documentos médicos ou consultas semelhantes',
  SEARCH: 'PROCURAR',
  Preview: 'Pré-visualização',
  PREVIEW_AND_SEND: 'Visualizar e enviar',
  PREVIEW_AS_PDF: 'Visualizar em pdf',
  PUBLISHED_VERSION: 'Versão publicada',
  NEW_VERSION: 'Nova versão',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'O documento ainda está em aprovação. Se você quiser alterar o conteúdo do documento, envie outro arquivo.',
  OUT_TO: 'sair para',
  NEXT: 'próxima',
  SELECTED_SLIDES: 'Slides selecionados',
  GO_TO_SLIDE: 'IR AO SLIDE',
  PREV: 'prev',
  SELECT_SLIDE: 'Selecionar slide',
  ANSWER: 'Responda',
  BACK_TO_STANDARD_VIEW: 'Voltar para a visualização padrão.',
  UPLOAD_NEW_FILE: 'Carregar novo arquivo',
  CREATE_FILE: 'CRIAR ARQUIVO',
  EDITING_WORD_DOC: 'Editando documento do Word',
  INSERT_FILE_NAME: 'Inserir nome do arquivo',
  PROCEED_WITH_THE_LINK_: 'Prossiga com o link do arquivo',
  A_NEW_WINDOW_WILL_BE_:
    'Uma nova janela será aberta com um arquivo vazio, escreva o conteúdo e feche a guia',
  INSERT_THE_NAME_FOR_:
    'Insira o nome do anexo que você deseja criar e continue com a criação do arquivo',
  INSTRUCTIONS: 'Instruções',
  CREATE_NEW_WORD_FILE_FOR: 'Crie um novo arquivo de palavras para',
  FROM: 'a partir de',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Você pode encontrar aqui as últimas consultas inseridas no sistema enviadas e ainda não enviadas. Você pode reutilizar as consultas inseridas para criar consultas mais semelhantes ou retornar às que ainda não terminou de inserir.',
  MY_DRAFT_INQ: 'Meus rascunhos',
  WARNING_CHANGES_NOT_SAVED: 'Aviso: alterações não salvas',
  PAGE_THAT_WILL_BE_LOST: 'página que será perdida se você decidir continuar.',
  YOU_HAVE_UNUSED: 'Você tem alterações não salvas em',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Tem certeza de que deseja sair desta página sem salvar?',
  LEAVE_THIS_PAGE: 'Sair desta página',
  STAY_ON: 'Ficar em',
  ASSIGNED_TO: 'Atribuído a',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Esta consulta foi inserida durante o evento.',
  STATUS_IN_APPROVAL: 'Status: Em aprovação',
  STATUS_DRAFT: 'Status: Rascunho',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Se você deseja criar a consulta real, vá para a guia Enviar e clique no botão Criar.',
  INQ_IMPORTED_FROM: 'Consulta importada de',
  SEND_NEW_ANSWER: 'ENVIAR NOVA RESPOSTA',
  RETURN_TO_INBOX: 'VOLTAR À INBOX',
  SUBMITTED_FOR_APPROVAL: 'Apresentado para aprovação',
  ANSWER_SUBMITTED_ON: 'Resposta enviada em',
  BY: 'por',
  INQ_HAS_BEEN_CLOSED: 'O inquérito foi encerrado sem resposta.',
  BCC: 'Cco',
  ANSWER_GIVEN_ON: 'Resposta dada em',
  ANSWER_SENT_ON: 'Resposta enviada em',
  INBOX: 'Caixa de entrada',
  OPEN_ORIGINAL_INQ: 'Abrir consulta original',
  CLOSE_ANSWER_NOT_NEEDED: 'Fechar: Anser não é necessário',
  HISTORY: 'História',
  ADD_A_NOTE: 'Adicione uma anotação',
  ADD_A_NOTE_INTO_: 'Adicione uma nota ao histórico de perguntas.',
  SHARE_OR_START_A_FOLLOW_UP: 'Compartilhe ou inicie um acompanhamento',
  SEE_ALL_INQ_RELATED_TO: 'Veja todas as perguntas relacionadas a',
  SRD_NEEDED: 'SRD necessário',
  ANSWER_UNAVAILABLE: 'Resposta indisponível',
  QUESTION: 'Questão',
  SUBMIT: 'ENVIAR',
  ANSWER_UPPERCASE: 'RESPONDA',
  DETAILS: 'DETALHES',
  SPLIT_INQ: 'Dividir Consulta',
  ADD_QUESTION: 'Adicionar pergunta',
  ENTER_QUESTION: 'Digite a pergunta',
  MARK_AS_ANSWERED: 'Marcar como respondido',
  TO_BE_ANSWERED: 'Para ser respondido',
  FOLLOW_UP_RECEIVED: 'Acompanhamento recebido',
  ERROR: 'Erro',
  SHOW_ORIGINAL_MESSAGE: 'Mostrar mensagem original',
  SEND: 'Mandar',
  DO_NO_SEND: 'Não envie',
  OK: 'Está bem',
  MAX_LENGTH_FOR_ZIP_CODE: 'O comprimento máximo do CEP é',
  CHARACTERS: 'personagens',
  ADDRESS_1_IS_: 'O endereço 1 é',
  LABEL_IS: 'A etiqueta é',
  NO_QUESTION_INSERTED: 'Nenhuma pergunta inserida',
  WARNING: 'AVISO',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'O acompanhamento não está disponível para esta interação.',
  INSERT_INQ_TEXT: 'Inserir texto da consulta',
  SHOW_MORE: 'Mostre mais',
  OUT_OF: 'fora de',
  INQ_TO_BE_SENT: 'Consultas a serem enviadas',
  HIDE_INQ: 'Ocultar consultas',
  SHARE: 'Compartilhar',
  HCP_PROFILE_OF: 'PERFIL HCP de',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Preencha todos os campos obrigatórios abaixo e pelo menos um endereço de e-mail e salve para visualizar a notificação de recebimento do GDPR.',
  CONFIRMATION_SENT_BY: 'Notificação enviada por',
  COUNTRY_IS: 'País é',
  COUNTRY: 'País',
  AT_LEAST_ONE_CONTACT_IS_: 'Pelo menos um contato é',
  EMAIL_IS: 'O email é',
  EMAIL_LABEL_IS: 'O rótulo de e-mail é',
  PHONE_FAX_IS: 'A etiqueta Telefone / Fax está',
  PHONE_FAX_NUMBER_IS: 'O número de telefone / fax é',
  ADDRESS: 'Endereço',
  DELETE_PERSONAL_DATA: 'Excluir dados pessoais',
  HCP_VERIFIED: 'HCP verificado',
  KEY_OPINION_LEADER: 'Líder de opinião chave',
  HOSPITAL_BASED: 'Hospital Based',
  HCP_RELATED_INFORMATION: 'INFORMAÇÕES RELACIONADAS AO HCP',
  ACCEPT: 'Aceitar',
  REJECT: 'Rejeitar',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Deseja aceitar essas sugestões?',
  SALES_REP: 'REPRESENTANTE DE VENDAS',
  SUGGEST_A_TOPIC: 'Sugira um tópico',
  TEAM_SPECIFIC_FIELDS: 'CAMPOS ESPECÍFICOS DA EQUIPE',
  ADR_RELATED: 'Relacionado a ADR',
  NOT_ADR_RELATED: 'Não relacionado a ADR',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Por favor, revise a mensagem e classifique-a como relacionada à reação adversa a medicamentos ou não.',
  ADVERSE_DRUG_REACTION: 'Reação adversa à droga',
  CHANGE_DETAILS: 'MUDAR DETALHES',
  YES: 'sim',
  NO: 'Não',
  DUE_DATE: 'Data de Vencimento',
  CRITICAL: 'Crítica',
  NORMAL: 'Normal',
  PRIORITY: 'Prioridade',
  SELECT_DETAILS: 'SELECIONAR DETALHES',
  SAVE_REVIEW: 'Salvar comentário',
  SET_TO_ALL_INQ: 'CONFIGURAR TODAS AS PERGUNTAS',
  PRODUCT_QUALITY_COMPLAINT: 'Reclamação de qualidade do produto',
  PQC_PC_A_PRODUCT_:
    'PQC / PC Uma queixa ou problema de qualidade do produto (QC) é definida como qualquer preocupação que envolva um defeito ou mau funcionamento de qualquer medicamento ou em relação à qualidade, desempenho e segurança de qualquer medicamento, p. cheiro e / ou sabor alterado, embalagem deficiente ou defeituosa, suspeita de contaminação, etc.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Uso não rotulado de medicamentos para indicação não aprovada ou em uma faixa etária, dose ou via de administração não aprovada.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Uma resposta a um medicamento nociva, não intencional e uma relação causal entre um medicamento e um evento adverso é pelo menos uma possibilidade razoável. Também casos de situações especiais (por exemplo, casos de gravidez, uso fora do rótulo, overdose) precisam ser marcados como RAM.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normal é 7 dias úteis, Alta é 48 horas e Crítica (relacionada ao paciente) é 24 horas',
  CATEGORY_AND_TOPIC_ARE: 'Categoria e tópico são',
  PRODUCT_IS: 'O produto é',
  DO_YOU_WANT_TO_ACCEPT_: 'Deseja aceitar essas sugestões?',
  MERGE: 'go',
  Duplicate: 'Duplicada',
  Other: 'De outros',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Interação próxima',
  RE_OPEN_THE_TARGET_: 'Reabra a consulta de destino após a mesclagem',
  MARK_AS_INTERNAL_: 'Marcar como uma mensagem interna para a consulta existente.',
  MARK_AS_AN_ERROR_: 'Marque como uma mensagem de erro para a consulta existente.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Esta mensagem será marcada como resposta de acompanhamento de uma consulta existente.',
  THANK_YOU: 'Obrigado',
  AN_INTERNAL_MESSAGE: 'Uma mensagem interna',
  AN_ERROR_MESSAGE: 'Uma mensagem de erro',
  A_FOLLOW_UP_RESPONSE: 'Uma resposta de acompanhamento',
  THIS_INQ_IS: 'Este inquérito é',
  SPLIT_INTERACTION: 'Interação dividida',
  SAVE_AND_CLOSE: 'Salvar e fechar',
  SAVE_INQ: 'Salvar consulta',
  REVIEW_THE_TEXT_AND: 'Revise o texto e a categorização e confirme',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Escolha o produto, a categoria e o tópico relevantes para o texto selecionado',
  SELECT_THE_TEXT_THAT_:
    'Selecione o texto que identifica a pergunta sobre um produto / tópico (não inclua saudações, assinaturas, isenções de responsabilidade ou outras informações não relevantes)',
  REPEAT_THE_PREVIOUS_: 'Repita as etapas anteriores para adicionar mais consultas',
  READ_MORE: 'Consulte Mais informação',
  READ_LESS: 'Ler menos',
  DO_NOT_INCLUDE_:
    'não inclua saudações, assinaturas, isenções de responsabilidade ou outras informações não relevantes',
  SELECT_THE_TEXT_THAT_IDENT:
    'Selecione o texto que identifica a pergunta sobre um produto / tópico',
  IF_YOU_HAVE_MULTIPLE_:
    'Se você tiver vários produtos ou tópicos, a consulta deverá ser dividida.',
  THE_FOLLOWING_PROCESS_:
    'O processo a seguir ajudará você a identificar o texto relevante para cada produto / tópico: ',
  EXPORT_TO_PDF: 'EXPORTAÇÃO PARA PDF',
  Added: 'Adicionada',
  Deleted: 'Excluída',
  From: 'A partir de',
  To: 'Para',
  BACK_TO_INBOX: 'De volta à caixa de entrada',
  PERMISSION_DENIED: 'Permissão negada',
  THIS_INQ_IS_LOCKED_BY: 'Esta consulta está bloqueada por',
  FORCE_UNLOCK: 'DESBLOQUEIO DE FORÇA',
  INQUIRIES: 'Inquéritos',
  Download: 'Baixar',
  Event: 'Evento',
  INSERTED_BY: 'Inserido por',
  LAST_EDITED_ON: 'Última edição em',
  LAST_EDITED_BY: 'Última edição por',
  CREATED_ON: 'Criado em',
  TOPIC: 'Tópico',
  TABLE_OPTIONS: 'Opções de tabela',
  DEPARTMENT: 'Departamento',
  User: 'Do utilizador',
  ADD_THERAPEUTIC_AREA: 'Adicionar área terapêutica',
  CREATE_THERAPEUTIC_AREA: 'Criar nova área terapêutica',
  ADD_NEW: 'Adicionar novo',
  SELECT_TEAM_TO_PROCEED: 'Selecione Equipe para continuar',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Vendas',
  CALL_CENTER: 'Central de Atendimento',
  ALL: 'Tudo',
  Warning: 'Aviso',
  clear: 'clara',
  THESE_CHANGES_WILL_BE_: 'Essas alterações serão aplicadas aos usuários selecionados',
  INSERT_A_FOLLOW_UP_RESP: 'Inserir uma resposta de acompanhamento',
  INSERT_A_NOTE: 'Inserir uma nota',
  MERGED_FROM: 'Mesclado de',
  CHANGES: 'Alterar',
  SHOW_LESS: 'mostre menos',
  COMPLETE: 'Completa',
  MISSING: 'Ausência de',
  CLOSE_AS_FORWARDED: 'Fechar como encaminhado',
  CLOSE_EXT_TEAM: 'Feche a consulta conforme encaminhada à equipe externa',
  THE_INQ_BELONG_TO_A_:
    'Essa consulta pertence a um país que é responsabilidade de uma equipe externa. A consulta será encaminhada para essa equipe automaticamente.',
  RESOLVE: 'Resolver',
  TERRITORIES: 'Territórios',
  EXTERNAL_COMMUNICATION: 'COMUNICAÇÃO EXTERNA',
  FOR_INQ_COMING_DIRECTLY_:
    'Para consultas enviadas diretamente do HCP por e-mail, envie automaticamente uma notificação para informá-lo de que a consulta foi recebida.',
  INTERNAL_COMMUNICATION: 'COMUNICAÇÃO INTERNA',
  SALESREP_RECEIVES_: 'O Salesrep recebe apenas notificações de que a consulta foi respondida',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep recebe resposta completa para consultas sobre etiqueta',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'O Salesrep recebe apenas notificações de que a consulta foi respondida',
  Name: 'Nome',
  Module: 'Módulo',
  Function: 'Função',
  LAST_RUN: 'Última corrida',
  End: 'Fim',
  Every: 'Cada',
  Period: 'Período',
  Active: 'Ativa',
  Resolved: 'Resolvida',
  ERROR_PREVIEW: 'Visualização de erro',
  FAILURE_COUNT: 'Contagem de falhas',
  COMPLETED_ON: 'Completo em',
  STARTED_ON: 'Começou em',
  TASK_NAME: 'Nome da tarefa',
  PRODUCT_SPECIFIC: 'ESPECÍFICOS DO PRODUTO',
  THERAPEUTIC_AREAS: 'ÁREAS TERAPÊUTICAS',
  PRODUCT_GROUPS: 'GRUPOS DE PRODUTOS',
  LICENSING_PARTNER: 'PARCEIRO DE LICENCIAMENTO',
  PRODUCT_CODE: 'CÓDIGO DO PRODUTO',
  EXTERNAL_ID: 'ID externo',
  OBJECT_TYPE: 'Tipo de objeto',
  MIGRATION_NAME: 'Nome da migração',
  MIGRATION_ERRORS: 'Erros de migração',
  ADD_SALES_REP: 'Adicionar representante de vendas',
  ASSIGNED_TO_MSL: 'ATRIBUÍDO À MSL',
  NOT_ASSIGNED: 'Não atribuído',
  ASSIGNED_TO_OTHER_MSL: 'Atribuído a outro MSL',
  ASSIGNED_TO_THIS_MSL: 'Atribuído a este MSL',
  SHOW_ONLY: 'Apenas mostrar',
  SALES_REP_LIST: 'Lista de representantes de vendas',
  ACTIVE_TASK: 'Ativar tarefa',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Escolha o usuário e defina o turno para o evento',
  MANAGE_SHIFTS: 'GERIR SHIFT',
  CREATE_NEW_USER: 'Criar novo usuário',
  THIS_TEMPLATE_WILL_BE_AUTO_:
    'Este modelo será adicionado automaticamente ao seu membro da equipe.',
  TEMPLATE_FOR: 'Modelo para',
  Day: 'Dia',
  Week: 'Semana',
  Month: 'Mês',
  Today: 'Hoje',
  Archive: 'Arquivo',
  PARTICIPANTS: 'PARTICIPANTES',
  EVENT_MANAGEMENT: 'GESTÃO DE EVENTOS',
  ADD_TASK: 'Adicionar tarefa',
  EDIT_TASK: 'Editar Tarefa',
  IS_ACTIVE: 'Está ativo',
  Months: 'Meses',
  Days: 'Dias',
  Hours: 'Horas',
  Minutes: 'Minutos',
  HCP_ADMINISTRATION: 'ADMINISTRAÇÃO DO HCP',
  AUTH_REQ_ADMINISTRATION: 'Administração de solicitações de autenticação',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'O produto será destacado nas áreas terapêuticas selecionadas.',
  PRODUCT_GROUPS_DEFINE_:
    'Os grupos de produtos definem a equipe responsável por responder às consultas deste produto.',
  USER_ADMINISTRATION: 'Administração de usuários',
  IS_PRODUCT_SPECIFIC: 'É específico do produto',
  USER_TEMPLATES_ADMIN: 'Administração de modelos de usuário',
  SET_DEFAULT_VALUES: 'DEFINIR VALORES PADRÃO',
  SET_DEFAULT_VALUES_FOR_: 'Definir valores padrão para o evento',
  INQUIRY_DEFAULT_VAL: 'Valores padrão da consulta',
  CREATE_NEW_EVENT: 'CRIAR NOVO EVENTO',
  EDIT_EVENT: 'EDITAR EVENTO',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Definir detalhes para criar um novo evento',
  ADD_DOC_LINKED_TO_: 'Adicionar documentos vinculados ao evento',
  INC_CHANNEL: 'Inc. Channel',
  CHANGE_DETAILS_OF_THE_EVENT: 'Alterar detalhes do evento',
  DESCRIPTION: 'Descrição',
  CREATE_APPROVAL_PROCESS: 'Escolha Processo de aprovação',
  CREATE_NEW: 'Crie um novo',
  CUSTOMIZE_PROCESS: 'Personalizar processo',
  SELECTED_PROCESS: 'Processo Selecionado',
  CLONE: 'Clone',
  PREFERENCES_TITLE: 'Preferências',
  NOTIFICATIONS_TITLE: 'Notificações',
  TEMPLATES: 'Modelos',
  LETTER_TEMPLATES: 'Modelo de carta',
  USER_TEMPLATES: 'Modelos de usuário',
  TEAM_MEMBERS: 'Membros do time',
  NEW_PASSWORD: 'Nova senha',
  REPEAT_NEW_PASSWORD: 'Repita a nova senha',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Pelo menos 1 letra minúscula',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Pelo menos 1 letra maiúscula',
  AT_LEAST_1_DIGIT: 'Pelo menos 1 dígito',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Pelo menos 1 caractere especial',
  MINIMUM_8_CHARACTERS: 'Mínimo de 8 caracteres',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Não deve conter email ou nomes',
  SHOULD_MATCH_PASSWORD: 'Deve corresponder à senha',
  USER_MANUAL: 'Manual do usuário',
  SUPPORT: 'Apoio, suporte',
  BACK_TO_LOGIN: 'Volte ao login',
  PASSWORD_CONFIRMATION: 'ConfirmaÇão Da Senha',
  HAVE_AN_ACCOUNT: 'Já possui uma conta?',
  Submit: 'Enviar',
  Gender: 'Gênero',
  PROFESSION: 'Profissão',
  MEDICAL_INQ_ROLES: 'Função de processamento de consultas médicas',
  MEDICAL_INFO_MANAGER: 'Gerente de Informações Médicas',
  MEDICAL_SCIENCE_LIAISON: 'Medical Science Liaison',
  NOT_PROCESSING_INQUIRIES: 'Não processando consultas',
  ACTIVE_HEALTH_CARE_SPEC: 'Especialista em cuidados de saúde ativo',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Especialista em cuidados de saúde não ativo',
  PASS_IS_REQUIRED: 'Senha requerida!',
  EMAIL_IS_REQUIRED: 'Email é obrigatório!',
  VALID_EMAIL: 'Por favor insira um endereço de e-mail válido!',
  FIELD_IS_REQUIRED: 'Este campo é obrigatório!',
  ONLY_LETTERS: 'Por favor, insira apenas letras!',
  ARE_YOU_daiichi_EMPLOYEE: 'Você é funcionário da Daiichi Sankyo?',
  here: 'aqui',
  CREATE_AN_ACCOUNT: 'Crie a sua conta aqui',
  FORGOT: 'Esqueceu?',
  Medical: 'Médica',
  Medical_not_resp: 'Médico - não é responsável por responder às MIRs',
  Commercial: 'Comercial',
  Market_access: 'Acesso ao mercado',
  Pharmacovigilance: 'Farmacovigilância',
  PR: 'PR',
  Please_specify: 'Por favor especifique',
  Medical_information_manager: 'Gerente de informações médicas',
  Medical_advisor: 'Conselheiro médico',
  Are_Medical_inquiries: 'Você é responsável por responder perguntas médicas?',
  Are_Health_care_professional: 'Você é um profissional de saúde?',
  Health_care_Industry: 'Setor de saúde',
  Health_Authority: 'Autoridade de saúde',
  Journalist: 'Jornalista',
  Lawyer: 'Advogada',
  Patient: 'Paciente',
  Payor: 'Pagador',
  Scientist: 'Cientista',
  Angiologist: 'Angiologista',
  Cardiologist: 'Cardiologista',
  Cardiovascular_Surgeon: 'Cirurgião Cardiovascular',
  Dentist: 'Dentista',
  Gastroenterologist: 'Gastroenterologista',
  General_Practitioner: 'Clínico Geral',
  Geriatrician: 'Geriatria',
  Gynecologist: 'Ginecologista',
  Haematologist: 'Hematologista',
  Internist: 'Interna',
  Medical_Student: 'Estudante de medicina',
  Nephrologist: 'Nefrologista',
  Neurologist: 'Neurologista',
  Neurosurgeon: 'Neurocirurgiã',
  Nurse: 'Enfermeira',
  Oncologist: 'Oncologista',
  Oncology_Nurse: 'Enfermeira de Oncologia',
  Other_HCP: 'Outro HCP',
  Other_Surgeon: 'Outro cirurgião',
  Pediatrician: 'Pediatra',
  Pharmacist: 'Farmacêutica',
  Pharmacologist: 'Farmacologista',
  Pharmacy_Technician: 'Técnico farmacêutico',
  Pneumologist: 'Pneumologista',
  Radiologist: 'Radiologista',
  Rheumatologist: 'Reumatologista',
  USER_PASS_INCORRECT: 'Nome de usuário ou senha está incorreta',
  SUBMITTED_REQUEST: 'Sua solicitação foi enviada com sucesso.',
  Reset: 'Redefinir',
  Reset_pass: 'Redefinir senha',
  New_pass: 'Nova senha',
  RESET_PASS_EMAIL: 'Um link de redefinição de senha foi enviado para seu e-mail',
  NEW_PASS_SUCCESS: 'Sua senha foi redefinida com sucesso',
  SOMETHING_WENT_WRONG: 'Ocorreu um erro. Entre em contato com o suporte.',
  EVENTS: 'Eventos',
  Login_here: 'Entre aqui',
  REGISTRATION: 'Cadastro',
  Create_an_account: 'Crie a sua conta aqui',
  NO_RECORDS: 'Não há registros',
  INTERACTION: 'Interação',
  SPECIALIZATION: 'Especialização',
  SPECIALIZATION_TYPE: 'Tipo de Especialização',
  SAVED: 'Salvou',
  SAVING: 'Salvando',
  TYPING: 'Digitando',
  CREATE_NEW_INTERACTION: 'Criar nova interação',
  FILTER_BY: 'Filtrar por',
  SEND_EMAIL: 'Enviar email',
  SAVE_WITHOUT_EMAIL: 'Salvar sem e-mail',
  SUBMIT_TO_INBOX: 'Enviar para a Caixa de entrada',
  Create_my_acc: 'Criar a minha conta',
  NEW_QUESTION: 'Nova pergunta',
  LABEL: 'Rótulo',
  SELECT_A_LABEL: 'Selecione um rótulo',
  CITY: 'Cidade',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Sugira uma nova categoria ou selecione uma existente',
  SUGGEST_CATEGORY_OR_TOPIC: 'Sugerir nova categoria ou tópico',
  URGENT: 'Urgente',
  EXISTING_CATEGORY: 'Categoria existente',
  NEW_CATEGORY_NAME: 'Nome da nova categoria',
  NEW_TOPIC_NAME: 'Novo nome do tópico',
  CATEGORY: 'Categoria',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Canal de saída',
  LOGIN_TO_CONTINUE: 'Faça login para continuar no Madjenta',
  Email_address: 'Endereço de e-mail',
  FORGOT_PASS: 'Esqueceu a senha?',
  Remember_me: 'Lembre de mim',
  YOU_WILL_BE_NOTIFIED: 'O e-mail de confirmação foi enviado com mais instruções para seu registro',
  SUCCESS_EMAIL_CONFIRMATION: 'Seu e-mail foi confirmado com sucesso.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Você será notificado quando sua solicitação for analisada',
  FAILED_EMAIL_CONFIRMATION: 'Não podemos confirmar seu e-mail.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Tente novamente e, se o problema persistir, escreva para o suporte com a descrição do problema',
  RESET_FILTERS: 'Redefinir filtros',
  NEW_PASS_RESET_SUCCESS: 'Redefinição de senha com sucesso!',
  Cardiovascular: 'Cardiovascular',
  Oncology: 'Oncologia',
  Due_On: 'Devido a',
  Follow_Up_Sent_On: 'Acompanhamento enviado em',
  Follow_Up_Received_On: 'Acompanhamento recebido em',
  Closed_On: 'Fechado em',
  Reopened_On: 'Reaberto em',
  Inquiry_ID: 'ID da consulta',
  Search: 'Procurar',
  Assigned_to_Now: 'Atribuído a (agora)',
  Edited_By: 'Editado por',
  Assigned_to_Any_Time: 'Atribuído a (a qualquer momento)',
  Closed: 'Fechadas',
  Foreign: 'Estrangeira',
  Complex_search_Sentence_: 'Pesquisa complexa: Sentença: "sentença a pesquisar", Não: -word',
  Search_inquiries: 'Consultas de pesquisa',
  SEARCH_REPOSITORY: 'Procurar repositório',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filtros',
  Active_Filters: 'Filtros ativos',
  CONTACT_SUPPORT: 'Entre em contato com o suporte',
  MedicalInformationRequests: 'Perguntas (pdf)',
  UserProfileNavigation: 'Perfil do usuário (pdf)',
  UserManualChanges: 'Alterações no manual do usuário (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Atribuir consulta',
  MedIS_Documentation_Approval_Process: 'Processo de aprovação de documentação',
  MedIS_Documentation_Overview: 'Visão geral da documentação',
  MedIS_Inquiry_Documentation: 'Documentação da consulta',
  MedIS_MIR_Inbox_Filters: 'Filtros de caixa de entrada MIR',
  MedIS_Merge_MIR: 'Mesclar MIR',
  MedIS_New_Documentation: 'Nova documentação',
  MedIS_New_Inquiry: 'Novo inquérito',
  MedIS_Out_Of_Office: 'Fora do escritório',
  MedIS_Prepare_Custom_Answer: 'Preparar resposta personalizada',
  MedIS_Split_MIR_Part_I: 'Split MIR Part I',
  MedIS_Split_MIR_Part_II: 'Split MIR Part II',
  I_AGREE_TO: 'Concordo',
  TermsAndConditionsLink: 'Termos e Condições',
  Title: 'Título',
  INTERACTION_TITLE: 'Título da interação',
  Move: 'Mover',
  Left: 'Esquerda',
  Right: 'Certa',
  Requestor: 'Solicitante',
  Assigned_To: 'Atribuído a',
  Status: 'Status',
  Note: 'Nota',
  Confirm: 'confirme',
  rejectConfirm: 'Tem certeza de que deseja rejeitar a solicitação?',
  approveConfirm: 'Tem certeza de que deseja aprovar a solicitação?',
  newUser: 'Novo usuário',
  editUser: 'Editar usuário',
  deleteUser: 'Deletar usuário',
  newProduct: 'Novo produto',
  editProduct: 'Editar produto',
  deleteProduct: 'Excluir produto',
  newCategory: 'Nova categoria',
  editCategory: 'Editar categoria',
  deleteCategory: 'Excluir categoria',
  newTopic: 'Novo topico',
  editTopic: 'Editar Tópico',
  deleteTopic: 'Excluir tópico',
  userRequest: 'Solicitação de usuário',
  productRequest: 'Pedido do produto',
  categoryRequest: 'Solicitação de categoria',
  categoryTopicRequest: 'Solicitação de categoria / tópico',
  topicRequest: 'Solicitação de tópico',
  Suggestion: 'Sugestão',
  tradeName: 'Nome comercial',
  activeSubstance: 'Substância ativa',
  productName: 'Nome do Produto',
  productSpecific: 'É específico do produto?',
  EMAIL_IN_USE: 'Existe uma conta neste e-mail',
  fromDate: 'Da data',
  toDate: 'Até a presente data',
  applyFilter: 'Aplicar filtro',
  requestReceivedFrom: 'Pedido recebido de',
  on: 'em',
  at: 'às',
  to: 'para',
  add: 'adicionar',
  from: 'de',
  approve: 'aprovar',
  reject: 'rejeitar',
  withTheFollowingRole: 'com a seguinte função',
  forTheFollowingTeam: 'para a seguinte equipe',
  request: 'solicitação',
  submittedBy: 'enviado por',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'A solicitação de redefinição de senha é inválida ou expirou',
  PASS_IS_RECENT: 'A senha é usada recentemente',
  Assigned_to_me: 'Atribuído a mim',
  My_drafts: 'Meus rascunhos',
  My_open_inquiries: 'Meus inquéritos abertos',
  My_closed_inquiries: 'Meus inquéritos encerrados',
  Filter_inquiries_by: 'Filtrar consultas por',
  Inq_inbox: 'Caixa de entrada de inquéritos',
  REPORTS: 'Relatórios',
  My_teams_open_inq: 'Consultas abertas da minha equipe',
  My_teams_closed_inq: 'Consultas fechadas da minha equipe',
  All_teams_open_inq: 'Inquéritos abertos de todas as equipas',
  All_teams_closed_inq: 'Todas as equipes encerraram consultas',
  Pending_approval: 'Aprovação pendente',
  ADMINISTRATION: 'Administração',
  Show_all: 'Mostre tudo',
  In_progress: 'Em progresso',
  Table_Settings_Columns: 'Configurações da tabela - colunas',
  Change_column_visibility: 'Alterar a visibilidade da coluna',
  Reassign_to: 'Reatribuir para',
  set_priority: 'Definir prioridade',
  set_status: 'Definir status',
  Spam_list: 'Lista de spam',
  Institution: 'Instituição',
  DUE_ON: 'Devido a',
  Profile_settings: 'Configurações de perfil',
  Preferences: 'Preferências',
  Role_settings: 'Configurações de função',
  Notification_settings: 'Configurações de notificação',
  User_profile: 'Perfil de usuário',
  Select_person: 'Selecionar pessoa',
  FROM_DATE: 'Da data',
  TO_DATE: 'Até a presente data',
  JOB_DESC: 'Descrição do trabalho',
  INQ_Management: 'Gerenciamento de Inquérito',
  DOCS_Management: 'Gerenciamento de documento',
  USER_Management: 'Gerenciamento de usuários',
  Define_Frequency: 'Definir frequência',
  All_teams_all_inq: 'Inquéritos para todas as equipas',
  My_teams_all_inq: 'Todos os inquéritos para minha equipe',
  Search_people_here: 'Pesquise pessoas aqui',
  Search_team_here: 'Equipe de pesquisa aqui',
  people: 'PESSOAS',
  teams: 'EQUIPES',
  empty: '(vazia)',
  can_not_be_assigned: 'Este usuário não possui equipe e não pode ser atribuído',
  select_a_team: 'Por favor, selecione uma equipe para',
  confirm_team_change: 'Confirmar alteração da equipe',
  change_the_team_: 'Tem certeza de que deseja mudar a equipe para',
  can_not_see_it_anymore: 'Você não poderá vê-lo no atual',
  filtered_view: 'visualização filtrada.',
  PHONE: 'telefone',
  FAX: 'Fax',
  LETTER: 'Carta',
  F2F_SITE_VISIT: 'F2F (visita ao local)',
  F2F_CONGRESS: 'F2F (Congresso)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Outro)',
  WEBINAR_SITE_VISIT: 'Virtual (visita ao site)',
  WEBINAR_CONGRESS: 'Virtual (Congresso)',
  WEBINAR_ADBOARD: 'Virtual (AdBoard)',
  WEBINAR_OTHER: 'Virtual (Outro)',
  CHAT: 'Bate-papo',
  COURIER: 'Correio',
  REP_DELIVERY: 'Rep. Entrega',
  SYSTEM: 'Sistema',
  WEB_FORM: 'Formulário da Web',
  Signature: 'Assinatura',
  Disclaimer: 'aviso Legal',
  STATE: 'Estado',
  ADDRESS_1: 'Endereço 1',
  ADDRESS_2: 'Endereço 2',
  ZIP_CODE: 'Código postal',
  HIGH: 'Alta',
  ADVANCED: 'Avançada',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Clique no texto e nos campos abaixo para preencher e editar a resposta',
  DOCUMENT_VIEW: 'Visualização de documento',
  FORM_VIEW: 'Vista de formulário',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Você não tem direitos para enviar a resposta.',
  ANSWER_TEXT: 'Texto da resposta',
  CONTACT_INFORMATION: 'Informações de Contato',
  COMPANY: 'Companhia',
  SRD_NEEDED_TOOLTIP: 'A resposta para esta pergunta requer um novo documento SRD',
  ENABLE_ALL: 'Habilitar todos',
  DISABLE_ALL: 'Desativar tudo',
  BEFORE: 'Antes',
  AFTER: 'Depois de',
  EDIT: 'Editar',
  MY_TEMPLATES: 'Meus Modelos',
  TEAM_TEMPLATES: 'Modelos de equipe',
  TEAM_TEMPLATES_HINT: 'Modelo criado pelo gerente de informações médicas para toda a equipe',
  SOURCE: 'Fonte',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Este modelo não corresponde aos detalhes de interação e não é aplicável',
  EMAIL_SUBJECT: 'Assunto do email',
  INQUIRY_SUBMIT_HINT:
    'Por favor, preencha todos os campos obrigatórios, a fim de responder à consulta',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Publicação programada até a data de expiração',
  COPY_ATTACHMENT_TO_ANSWER: 'Copiar anexo para responder',
  COPY_TEXT_TO_ANSWER: 'Copiar texto para responder',
  SELECT_INQUIRY: 'Selecione Inquérito',
  EDIT_INQ: 'Editar Pergunta',
  SPLIT_ANOTHER: 'Dividir outro',
  DELETE_INQ: 'Apagar Inquérito',
  SELECT_TEXT_FOR_SPLIT: 'Adicione uma nova pergunta na mesma interação',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Gerar interação / caso separado',
  SPLIT_ORIGINAL_TEXT: 'Texto Original (Selecione o texto para divisão)',
  SPLIT_TEXT_TO_REMAIN_: 'Texto para permanecer na investigação inicial',
  SPLIT_TEXT_TO_SPLIT_: 'Texto a ser dividido',
  SPLIT_SELECTED_SECTIONS: 'Seções selecionadas para divisão',
  SPLIT_NO_MODIFICATIONS_HINT:
    'O texto irá preencher automaticamente conforme você seleciona seções do texto original',
  SPLIT_MODIFICATIONS_HINT:
    'O texto foi modificado manualmente e qualquer outra seleção do texto original não será aplicada.\n Clique aqui para descartar as modificações.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'O texto será atualizado automaticamente com as alterações nos detalhes da consulta.',
  SELECT_DIFFERENT_TEMPLATE: 'Selecione um modelo diferente',
  APPROVE: 'Aprovar',
  LAST_EDIT_ON: 'Última edição em',
  LAST_EDIT_BY: 'Última edição por',
  INTERNAL_COMM: 'Comunicação interna',
  INACTIVE: 'Inativa',
  DEACTIVATE: 'Desativar',
  ARCHIVED: 'Arquivada',
  REPUBLISH: 'Republicar',
  INBOX_NO_FILTERS_HINT:
    'Não há filtros selecionados.\n Se você não conseguir encontrar a consulta que está procurando, verifique se você está na seção correta da caixa de entrada.',
  INTERACTION_TEMPLATE_HINT:
    'Assinaturas, saudações e isenções de responsabilidade fazem parte da comunicação escrita, como e-mail, carta e fax.\n Para usá-los, certifique-se de que o canal de saída esteja definido como um deles.',
  CONFIRM_AUTH_REQUEST: 'Confirmar solicitação de autenticação',
  VIEW_AUTH_REQUEST: 'Ver solicitação de autenticação',
  QUESTION_TITLE: 'Título da questão: ',
  NO_TITLE_INQUIRY: '-no-title-enquiry-',
  EXISTING_CATEGORIES: 'Categorias Existentes',
  EXISTING_TOPICS: 'Tópicos Existentes',
  DO_NOT_ASSIGN_PRODUCT: 'Não atribuir produto',
  QUESTION_TEXT: 'Texto da pergunta',
  DATE: 'Encontro',
  REJECTED: 'Rejeitada',
  FILTER_AUTH_REQUESTS_BY: 'Filtrar solicitações de autenticação por',
  ALL_REQUESTS_ALL_TEAMS: 'Todas as solicitações para todas as equipes',
  ALL_TEAMS: 'Todas as equipes',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'Você não tem direitos para editar solicitações de categoria / tópico.',
  SEND_FOLLOW_UP: 'Enviar Acompanhamento',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Notificação de recebimento para o cliente',
  GDPR_DISCLAIMER: 'Exoneração de responsabilidade do GDPR',
  ACTIONS: 'Ações',
  SUBMISSION_CONDITIONS: 'Condições de envio',
  TYPE_OF_NOTIFICATION: 'Tipo de notificação',
  DIRECT_SUBMISSION: 'Submissão direta',
  DIRECT_SUBMISSION_TOOLTIP:
    'Para consultas enviadas diretamente do cliente por e-mail, envie uma notificação para informar o cliente sobre o tratamento de seus dados pessoais.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Enviado via funcionário da empresa',
  ENABLE_NOTIFICATION: 'Ativar notificações',
  NOTIFICATION_SENDER: 'Notificação ao Remetente',
  SALES_REP_DELIVERED_RESPONSE: 'Representante de vendas para resposta entregue',
  NOTIFICATION_DELIVERED_RESPONSE: 'Notificação para resposta entregue',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Para consultas enviadas por meio do representante da empresa (representante de vendas, MSL ou outro), notifique o remetente quando o cliente receber uma resposta à sua consulta',
  FULL_ANSWER: 'Resposta completa',
  NOTIFICATION: 'Notificação',
  NAME: 'Nome',
  COLUMN_INIT_QUESTION_TEXT: 'Texto original da pergunta',
  NOTIFICATION_ENABLED: 'Notificação ativada',
  DEFAULT_TEMPLATE: 'Modelo padrão',
  SET_AS_DEFAULT: 'Definir como padrão',
  DEFAULT_TEMPLATE_TOOLTIP:
    'A alternância está desabilitada, pois deve haver pelo menos um modelo padrão!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'O modelo padrão não pode ser excluído!',
  BATCH_UPDATE_ONLY:
    'Somente uma atualização em lote é possível por meio da caixa de seleção superior.',
  ACCESS_DENIED: 'Acesso negado!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    'Você não tem permissão para acessar esta página.',
  USER_ROLES: 'Funções do usuário',
  MANAGE_USER_ROLES: 'Gerenciar funções de usuário',
  ARCHIVE_ROLE: 'Função de arquivo',
  ROLE_ARCHIVE_CONFIRMATION: 'Você realmente deseja arquivar esta função?',
  ROLES_FOR: 'Funções para',
  AUDIT_TRAIL: 'Trilha de auditoria',
  ACTION_BY_USER: 'Ação do usuário',
  ACTION: 'Ação',
  ORIGINAL_VALUE: 'Valor original',
  CHANGE: 'Alterar',
  CONDITION: 'Condição',
  IP: 'IP',
  GEOLOCATION: 'Geolocalização',
  NEW_VALUE: 'Novo valor',
  FILTER_BY_ACTION: 'Filtrar por ação',
  SELECT_DATE: 'Selecionar data',
  TEAM_SAVE: 'Salvar',
  TEAM_DELETE: 'Excluir',
  TEAM_CREATE: 'Crio',
  TEAM_CLONE: 'cópia de',
  TEAM_NAME: 'Nome',
  TEAM_CODE: 'Código',
  TEAM_ACTION_DELETE_CONFIRM: 'Você realmente quer excluir',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Tome cuidado!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Depois de excluído, não pode ser restaurado!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Resposta completa na etiqueta',
  TEAM_RESTRICTED_TEAM: 'Restrita Restrito',
  TEAM_SEGREGATED_DATA: 'Dados segregados',
  TEAM_PRIVATE_TEAM: 'Equipe privada',
  TEAM_AUTO_ASSIGN: 'Atribuir automaticamente',
  TEAM_MAILBOX_IN: 'Caixa de correio de entrada',
  TEAM_MAILBOX_OUT: 'Caixa de correio de saída',
  TEAM_DEFAULT_LANGUAGE: 'Idioma padrão',
  TEAM_COUNTRIES: 'Países designados',
  TEAM_PRODUCT_GROUPS: 'Atribuir grupos de produtos',
  TEAM_PARENT: 'Equipe de pai',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Substituir a equipe raiz de cluster',
  TEAM_AFFILIATIONS: 'Equipes afiliadas',
  TEAM_TIMEZONE: 'Fuso horário',
  UPDATE: 'Atualizar',
  AUTO_ASSIGN_BY: 'Atribuir automaticamente por',
  AUTO_ASSIGNMENTS: 'Atribuições de automóveis',
  NO_ONE: 'Ninguém',
  NOT_APPLICABLE: 'Não aplicável',
  OVERRIDE_NOTIFICATIONS: 'Substituir notificações',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Quando o modelo do usuário com notificações de substituição é definido para o usuário, ele desativará qualquer configuração de notificação pré -existente e permitirá apenas as configurações de notificação listadas no modelo do usuário',
  CREATED_MANUALLY: 'Criado manualmente',
  DOCUMENT_TITLE_FOR_WORD:
    'O título de documentos do Word geralmente é retirado da seção formatada pelo título dentro do próprio documento. Se o documento não conter o título, você poderá especificá -lo aqui. Se você não especificar o título aqui e o documento do Word não contém seção formatada pelo título, o ID do documento será usado para o título.',
  ORDER_BY: 'Ordenar por',
  FORMAT: 'Formato',
  SELECT_QUESTION: 'Selecione Pergunta',
  SELECT_QUESTION_FOR_NOTE: 'Selecione a pergunta para a qual você deseja adicionar nota',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Adicionar nota para uma investigação inteira',
  REQUESTED_DATE: 'Data solicitada',
  UNASSIGNED: 'Não atribuído',
  BLANK: 'Em branco',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Resposta salva sem email',
  INQ_RELATED_TO: 'Consultas relacionadas a',
  QUESTION_TEXT_BEFORE_SPLIT: 'Pergunta o texto antes da divisão',
  SPLIT_MERGE: 'Dividir e mesclar',
  CLARIFICATION_QUESTION: 'Pergunta de esclarecimento',
  ACTIVE_ON: 'Ativo em',
  DISMISS: 'Dispensar',
  GO_TO_ENQUIRY: 'Vá para o inquérito',
  NO_TEAMS: 'Sem equipe',
  NO_TEAMS_TIP:
    'Contém usuários, que não são atribuídos a nenhuma equipe. Você pode verificar se alguns deles são da sua equipe e conceder a eles o papel apropriado.',
  TASKS: 'Tarefas',
  Therapeutic_Areas: 'Áreas terapêuticas',
  Product_Groups: 'Grupos de produtos',
  Licensing_Partner: 'Parceiro de licenciamento',
  Product_Code: 'Código do produto',
  SELECT_EXISTING_ONE: 'Selecione um existente',
  OR: 'ou',
  MANAGE_FORM: 'Gerenciar forma',
  EVENT_FORM_ADDRESS: 'Endereço do formulário de evento',
  EVENT_FORM_PREVIEW_WARING:
    'Certifique -se de salvar quaisquer alterações antes de abrir o formulário do Congresso ou copiar seu endereço',
  SEARCH_SAVED_SEARCHES: 'Pesquisar pesquisas salvas',
  GO: 'Vai',
  ASSIGN: 'Atribuir',
  CERTIFICATION: 'Certificação',
  SORT: 'Ordenar',
  ADD_MAILBOX: 'Adicionar caixa de correio',
  UNSET_INCOMING_MAILBOX: 'Caixa de correio não atendida',
  SET_INCOMING_MAILBOX: 'Defina a caixa de correio recebida',
  UNSET_OUTGOING_MAILBOX: 'Caixa de correio não definida',
  SET_OUTGOING_MAILBOX: 'Defina caixa de correio de saída',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Todas as equipes com caixa de correio atual',
  ALL_TEAMS_WITH_MAILBOX: 'Todas as equipes com caixa de correio',
  MAILBOX: 'Caixa de correio',
  EMAIL_POLLING: 'Pesquisa por e -mail',
  ADVANCED_SEARCH: 'Busca Avançada',
  SELECT_FIELDS: 'Selecione Campos',
  SEARCH_TERM: 'Termo de pesquisa',
  COLUMN_INTERACTION_ID: 'ID da interação',
  COLUMN_QUESTION_ID: 'ID da pergunta',
  COLUMN_CREATED_ON: 'Criado em',
  COLUMN_LAST_EDITED_DATE: 'Última edição em',
  COLUMN_ASSIGNED_TO: 'Atribuir a',
  COLUMN_DUE_DATE: 'Data de Vencimento',
  COLUMN_FOLLOW_SENT_DATE: 'Data enviada de acompanhamento',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Data de recebimento de acompanhamento',
  COLUMN_ANSWER_DATE: 'Data de resposta',
  COLUMN_CLOSED_ON_DATE: 'Fechado na data',
  COLUMN_REOPENED_DATE: 'Data reaberta',
  COLUMN_INSERTED_BY: 'Inserido por',
  COLUMN_LAST_EDITED_BY: 'Última edição por',
  COLUMN_ANSWERED_BY: 'Respondido por',
  COLUMN_APPROVED_BY: 'Aprovado por',
  COLUMN_INT_COMM_USER: 'Usuário de comunicação interna',
  COLUMN_INT_COMM_DATE: 'Data de comunicação interna',
  COLUMN_MY_TEAM: 'Meu time',
  COLUMN_PQC_RELATED: 'PQC relacionado',
  COLUMN_INCOMING_CHANNEL: 'Canal recebido',
  COLUMN_OUTGOING_CHANNEL: 'Canal de saída',
  COLUMN_OFF_LABEL: 'Off-label',
  COLUMN_HCP_SPEC: 'HCP + Especialização',
  COLUMN_NON_HCP_SPEC: 'Não-HCP + Profissão',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: 'Hospital baseado',
  COLUMN_CITY: 'Cidade',
  COLUMN_INSTITUTION: 'Instituição',
  COLUMN_TERRITORY: 'Território',
  CASE_ID: 'ID do caso',
  OPTION_ADD: 'ADICIONAR',
  OPTION_AND: 'E',
  OPTION_OR: 'OU',
  OPTION_NOT: 'NÃO',
  OPTION_YES: 'Sim',
  OPTION_NO: 'Não',
  QUERY: 'Consulta',
  EXPORT_LABEL: 'Defina colunas de exportação',
  SAVE_SEARCH: 'Salvar busca',
  SHARE_SEARCH: 'Compartilhar pesquisa',
  SEARCH_BTN: 'Procurar',
  BACK_ACTION: 'De volta',
  SAVE_CHANGES: 'Salvar alterações',
  SAVED_SEARCH: 'Pesquisa salva',
  NEW: 'Nova Novo',
  SAVE_AS: 'Salve como nome',
  ENTER_RECIPIENTS: 'Insira os destinatários',
  CLEAR_SAVED_SEARCH: 'Pesquisas claras salvas',
  NO_SAVED_SEARCH: 'Nenhuma pesquisa salva encontrada',
  INT_COMM_TEXT: 'Texto de comunicação interna',
  FOLLOW_UP_TEXT: 'Texto de acompanhamento',
  CONFIRM_DELETION: 'Confirme a exclusão',
  ARE_SURE: 'Tem certeza que deseja excluir a pesquisa salva',
  PERMANENTLY_DELETED: 'A pesquisa será permanentemente <b> excluída </b>.',
  NOTHING_SAVE: 'Nada para salvar.',
  NOTHING_SEARCH: 'Nada para pesquisar.',
  NOTHING_EXPORT: 'A pesquisa não tem registro para exportação.',
  SHARE_SUCCESS: 'A pesquisa foi compartilhada com sucesso',
  SHARE_FAILURE: 'A pesquisa não pôde ser compartilhada',
  DELETED_SUCCESS: 'Excluído com sucesso Pesquisa salva',
  ALL_DELETED_SUCCESS: 'Excluído com sucesso todas as pesquisas salvas',
  VIEW_SAVED_SEARCH: 'Veja a pesquisa salva',
  EDIT_SAVED_SEARCH: 'Editar pesquisa salva',
  EXPORT_SAVED_SEARCH: 'Exportar pesquisa salva',
  EXPORT_SEARCH: 'Pesquisa de exportação',
  DELETE_SAVED_SEARCH: 'Exclua pesquisa salva salva',
  SELECT_EXPORT_COLUMNS: 'Selecione colunas para exportar',
  SAVED_SEARCH_ALREADY_EXIST: 'Pesquise com esse nome já existe.',
  CHARACTER_LIMIT: 'Min 3 caracteres. Max 35 caracteres.',
  CHARACTER_LEFT: 'Os caracteres são deixados',
  ACTIVATE: 'Ativar',
  INACTIVE_USER:
    'Este usuário não está mais ativo. Entre em contato com o administrador do seu sistema.',
  FOLLOW_UP: 'Acompanhamento',
  USER_NOT_FOUND: 'Usuário não encontrado',
  DEPARTMENTS: 'Departamentos',
  EMAIL: 'O EMAIL',
  ADD_NEW_DEPARTMENT: 'Adicione um novo departamento',
  DEPARTMENT_COUNTRY: 'PAÍS',
  DEPARTMENT_COMPANY: 'COMPANHIA',
  DEPARTMENT_TEAM: 'EQUIPE',
  DEPARTMENT_TA: 'Área terapêutica',
  DEPARTMENT_PRODUCT: 'PRODUTOS',
  DEPARTMENT_ACTIONS: 'AÇÕES',
  DEPARTMENT_CONFIRMATION_1: 'Confirmar a exclusão?',
  DEPARTMENT_CONFIRMATION_2: 'Esta ação é irreversível.',
  DEPARTMENT_NAME: 'Nome do departamento',
  TYPE_DEPARTMENT: 'Modelo',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'País - Selecione a equipe primeiro',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 caracteres.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Solicitação de usuário',
      CategoryTopicRequest: 'Solicitação de categoria/tópico',
      ProductRequest: 'Solicitação de produto',
      UserUpdateRequest: 'Solicitação de atualização do usuário',
    },
    TYPE: {
      NewUser: 'Novo usuário',
      NewCategoryTopic: 'Nova categoria/tópico',
      NewUserUpdate: 'Atualização do usuário',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Definir lista de especialização específica do país',
      IS_COUNTRY_SPECIFIC: 'É específico do país',
      TYPE: 'Tipo de inquiridor',
      SPECIALIZATION_EXISTS: 'A configuração para "Tipo/Especialização do Inquiridor" já existe.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Gênero', COUNTRY: 'País', TITLE: 'Título' },
  },
  hcp: 'HCP',
  emp: 'Empregada Empregado',
  cons: 'Não-hcp',
  OOO_DEPUTY: 'Ooo deputado',
  NO_DEPUTY: 'Sem deputado',
  UNSPECIFIED_END_DATE: 'Data final não especificada',
  DELEGATORS: 'Delegadores',
  NO_DELEGATORS: 'Sem delegadores',
  PREVIEW_DELEGATORS: 'Visualizar delegadores',
  REMOVE_DEPUTY: 'Remova o deputado',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Usuários que o definiram como deputado',
  CHANGED_USER: 'Usuário alterado',
  DEFAULT: 'Predefinição',
  SPECIALIZATIONS: 'Especializações',
  ENQUIRERS_LIST: 'Lista de Enquirers',
  EDIT_THE_RECORD: 'Edite o registro',
  DEACTIVATE_THE_RECORD: 'Desative o registro',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Fora do escritório',
      HINT: 'Somente usuários ativos, com a função de processamento de consulta, que não estão fora do escritório estão disponíveis para seleção.',
      MODAL_TITLE: 'Confirme das configurações do escritório',
      MODAL_MSG_1:
        'Você saiu da opção Office com efeito imediato, todas as suas perguntas serão atribuídas imediatamente ao deputado.',
      MODAL_MSG_2:
        'Você saiu da opção de escritório, todas as suas perguntas serão atribuídas ao deputado durante o período fora do escritório.',
    },
  },
  GENDER_MALE: 'Senhora. Senhor.',
  GENDER_FEMALE: 'Sra.',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'PhD',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Casa',
  HOSPITAL: 'Hospital',
  OFFICE: 'Escritório',
  WORK: 'Trabalhar',
  INSERT_IMAGE: 'Inserir Imagem',
  USER_ADMIN: 'Admin do usuário',
  MAILBOXES: 'Caixas de correio',
  AUTHORIZATION_REQUESTS: 'Solicitações de autorização',
  METADATA: 'Metadados',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Atribuição',
      FORWARD: 'Avançar',
      FOLLOW_UP: 'Acompanhamento',
      DUE_DATE_SETTINGS: 'Configurações de data de vencimento',
      CLOSE: 'Perto',
      DELIVERY_ERROR: 'Erro de entrega',
      SCHEDULER: 'Agendador',
      USER_REPORTS: 'Relatórios do usuário',
      DOCUMENT_APPROVAL: 'Aprovação do documento',
      AUTHORIZATION_REQUEST: 'Solicitações de autorização',
      NEW_INQUIRY: 'Nova consulta',
      INQUIRY_APPROVAL: 'Aprovação de consulta',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Uma solicitação de função foi aprovada/rejeitada',
      AUTHORIZATION_REQUEST_3: 'Uma função está aguardando minha aprovação',
      DOCUMENT_APPROVAL_1: 'Um documento está aguardando sua aprovação',
      DOCUMENT_APPROVAL_2: 'Um documento foi aprovado/rejeitado',
      DOCUMENT_APPROVAL_3: 'Um documento foi arquivado',
      DOCUMENT_APPROVAL_4: 'Um documento foi aprovado com mudanças',
      DOCUMENT_APPROVAL_5: 'Um documento será arquivado em',
      DOCUMENT_APPROVAL_6: 'Lista de documentos aprovados',
      DUE_DATE_2: 'Repita após o vencimento',
      ERROR_1: 'Falha na notificação de entrega (por exemplo, email incorreto)',
      FOLLOW_UP_1: 'Um acompanhamento ou lembrete foi enviado ao cliente',
      FOLLOW_UP_4: 'Um acompanhamento foi recebido',
      IMPORT_DATA_1: 'Um novo objeto foi criado durante a importação',
      IMPORT_DATA_4: 'Um novo usuário foi criado durante a importação',
      IMPORT_DATA_5:
        'Um usuário estava combinando com um já existente, mas não vinha de uma importação anterior',
      IMPORT_DATA_6: 'Ocorreu um erro durante a importação de dados de uma fonte externa',
      NEW_DOCUMENT_1: 'Novo documento aprovado está disponível no meu país',
      NEW_DOCUMENT_2: 'Novo documento está disponível para aprovação local',
      NEW_DOCUMENT_3: 'Novo documento local está disponível para aprovação européia / global',
      NEW_DOCUMENT_4: 'Novo documento global está disponível',
      NEW_DOCUMENT_5: 'Novo documento europeu está disponível',
      NEW_DOCUMENT_6: 'Novo documento europeu está disponível para aprovação global',
      SCHEDULER_TASK_1: 'Um erro aconteceu durante a execução de uma tarefa',
      USER_REPORTS_1: 'Lista de usuários ativos',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Receba notificações informando que quando uma solicitação de função de usuário precisa ser aprovada/rejeitada',
        AUTHORIZATION_REQUEST_2:
          'Receba notificações quando o novo usuário foi criado ou quando uma função foi solicitada por um usuário em sua equipe',
        AUTHORIZATION_REQUEST_4:
          'Receber notificações quando um novo tópico e/ou categoria foram sugeridos',
        FOLLOW_UP_4:
          'Receber notificações sobre consultas às quais fui designado e que receberam uma resposta de acompanhamento do solicitante',
        NEW_INQUIRY_1:
          'Receba notificações sobre novas consultas recebidas em seu país, inseridas por um solicitante/usuário ou encaminhadas de outra equipe',
        ANSWER_2:
          'Receber notificações de consultas que foram respondidas, de que trabalhei como colaborador, mas não estou diretamente designado para a consulta',
        DUE_DATE_1:
          'Selecionando esta opção, todas as notificações abaixo serão ativadas. Para reduzir o número de notificações, desmarque aquelas que não são necessárias.',
        INQUIRY_APPROVAL_5:
          'Sua resposta proposta foi Rejeitada ou o status da interação foi atualizado de Enviado para aprovação para Em andamento devido à divisão por meio de interação, Mesclar como nova consulta resultando em divisão ou qualquer alteração na configuração da equipe em termos de necessidade de aprovação de consulta subsequente.',
        INQUIRY_APPROVAL_4: 'Sua resposta proposta foi Aprovada.',
        METADATA_MANAGEMENT_2:
          'Receba notificações sobre produtos recém-criados, independentemente do motivo da criação.',
        METADATA_MANAGEMENT_3:
          'Receba notificações sobre categorias recém-criadas, independentemente do motivo da criação.',
        METADATA_MANAGEMENT_4:
          'Receba notificações sobre tópicos recém-criados, independentemente do motivo da criação.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Imediatamente',
        DAILY: 'Diária Diário',
        WEEKLY: 'Semanalmente',
        QUARTERLY: 'Trimestral',
        MONTHLY: 'Por mês',
        SECOND_DAY: 'Cada segundo dia',
        THIRD_DAY: 'Todo terceiro dia',
        ONE_WEEK: 'Uma semana',
        ONE_MONTH: 'Um mês',
        TWO_MONTHS: 'Dois meses',
        THREE_MONTHS: 'Três meses',
        SIX_MONTHS: 'Seis meses',
        NINE_MONTHS: 'Nove meses',
        EVERY_TWO_WEEKS: 'A cada duas semanas',
        EVERY_THREE_WEEKS: 'A cada três semanas',
        EVERY_FOUR_WEEKS: 'A cada quatro semanas',
      },
      OPTIONS: {
        ALMOST_DUE: 'Quase devido',
        DUE_NOW: 'Devido agora',
        DUE_SOON: 'Vencimento logo',
        EARLY_ON: 'Cedo',
        TEXT_GUIDES: {
          DUE_NOW: 'Receba notificações quando a consulta estiver vencida',
          EARLY_ON:
            'Receba notificações com antecedência para consultas que estão vencidas: Normal 4 dias - Alta 2 dias - Crítico 24 horas antes da data de vencimento',
          DUE_SOON:
            'Receba notificações para consultas que vencem em breve: Normal 48 horas - Alta 12 horas - Crítico 6 horas antes da data de vencimento',
          ALMOST_DUE:
            'Receba notificações para consultas que estão quase vencendo: Normal 24 horas - Alta 6 horas - Crítico 3 horas antes da data de vencimento',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Uma categoria/ tópico foi solicitada em minha equipe',
      AUTHORIZATION_REQUEST_2: 'Solicitações de usuário',
      IMPORT_DATA_2: 'Um novo inquiridor foi criado durante a importação',
      IMPORT_DATA_3:
        'Um inquiridor estava correspondendo a um já existente, mas não estava vindo de uma importação anterior',
      ANSWER_2: 'Uma consulta na qual eu estava envolvido foi respondida',
      ASSIGNMENT_1: 'Uma consulta foi atribuída a mim',
      ASSIGNMENT_5: 'Foi atribuído um inquérito ao meu representante',
      CLOSE_1: 'Um inquérito foi encerrado',
      DUE_DATE_1:
        'Uma consulta que me foi atribuída está próxima da data de vencimento, informe-me',
      FOLLOW_UP_7: 'Uma consulta foi perdida para acompanhamento',
      FORWARD_1: 'Foi encaminhado um inquérito',
      INQUIRY_APPROVAL_1: 'Uma consulta está aguardando sua aprovação',
      INTERNAL_1: 'Uma nota foi adicionada à minha consulta atribuída',
      NEW_INQUIRY_1: 'Nova consulta foi recebida no meu país',
      SPAM_1: 'Nova consulta recebida em Spam',
      ANSWER_1: 'Receba cópia da resposta nas interações respondidas por mim',
      ANSWER_6: 'Receber cópia da resposta nas interações aprovadas por mim',
      INQUIRY_APPROVAL_5: 'Sua resposta proposta foi rejeitada',
      INQUIRY_APPROVAL_4: 'Sua resposta proposta foi aprovada',
      METADATA_MANAGEMENT_1: 'Geografia e alinhamentos durante a importação de CRM',
      METADATA_MANAGEMENT_2: 'Um novo produto foi criado',
      METADATA_MANAGEMENT_3: 'Uma nova categoria foi criada',
      METADATA_MANAGEMENT_4: 'Um novo tópico foi criado',
    },
  },
  NEW_TEAM: 'Novo time',
  SmPc: 'Smpc',
  USERS: 'Usuárias Usuários',
  PAGINATOR_NEXT_PAGE_LABEL: 'Próxima página',
  PAGINATOR_PREV_PAGE_LABEL: 'Página anterior',
  PAGINATOR_FIRST_PAGE_LABEL: 'Primeira página',
  PAGINATOR_LAST_PAGE_LABEL: 'Última página',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'itens por página',
  Date: 'Encontro',
  Action: 'Ação',
  'Action by user': 'Ação por usuário',
  'Changed user': 'Usuário alterado',
  'Original value': 'Valor original',
  'New value': 'Novo valor',
  REGION: 'Região',
  TEAM_REGION_LABEL: 'Região - Selecione a empresa primeiro',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Áreas terapêuticas - selecione a empresa primeiro',
  TEAM_PRODUCT_LABEL: 'Produtos - Selecione a área terapêutica primeiro',
  TEAM_COUNTRIES_LABEL: 'Países - selecione a região primeiro',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Defina produtos para cada país',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Selecione pelo menos um produto para',
  VIEW_CHANGES: 'Ver mudanças',
  Sales_Representative: 'Representante de vendas',
  Other_Commercial: 'Outro comercial',
  REGIONS: 'Regiões',
  MAIN_CLIENT: 'Cliente principal',
  LICENSE_PARTNER: 'Parceiro de licença',
  TEAM_SET_UP: 'Equipe configurada',
  LIST_TEAMS: 'Listar equipes',
  COMPANIES: 'Empresas',
  DOMAINS: 'Domínios',
  TOKEN_REFRESH_NOTICE:
    'Você solicitou um novo email de confirmação para o seu registro da conta ENQMED.',
  TOKEN_REFRESH_SUCCESS: 'Um novo link de confirmação foi enviado ao seu e -mail.',
  TOKEN_REFRESH_ERROR:
    'Um erro ocorreu. Entre em contato com o suporte se precisar de mais assistência.',
  PLEASE_ENTER_MAX: 'Por favor, digite o máximo',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Revise as alterações abaixo, se houver, e confirme se a empresa deve ser alterada.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Prossiga com cautela porque as alterações afetarão as equipes',
  THERE_ARE_NO_CHANGES: 'Não há alterações.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique as empresas existentes na tabela por trás da caixa de diálogo, pois já temos a empresa com os mesmos valores.',
  SIMPLE_TEXT:
    'Por favor, insira apenas cartas (A-Z, A-Z), números (0-9) e símbolos especiais como (Dash, Período, Espaço e Cólon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Selecione pelo menos um país para a região',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Selecione pelo menos um produto para área terapêutica',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Você selecionou produtos que pertencem a mais de uma área terapêutica, selecione todas as áreas terapêuticas necessárias.',
  HTML_CHANGES: 'Alterações em HTML',
  SIDE_BY_SIDE_VIEW: 'Vista lado a lado',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique os domínios existentes na tabela atrás da caixa de diálogo, pois já temos domínio com os mesmos valores.',
  OUT_CHANNEL_ERROR_MSG: 'Letra do canal de saída Enquirer O endereço postal de Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Canal de saída selecionado como carta. Por favor, insira o endereço postal de Enquirer na área de Enquirer',
  LABEL_FOR: 'Etiqueta para',
  male: 'Macho',
  female: 'Fêmea Fêmeo',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Você não tem direitos para arquivar esse papel. Somente <b> MIMS </b> para a equipe pode arquivar papéis para os membros da equipe.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Envie email para o usuário mediante solicitação de aprovação/rejeição',
  APPROVE_REQUEST_QUESTION: 'Você quer aprovar o pedido?',
  NEW_USER_REQUEST: 'Nova solicitação de usuário',
  OPEN_REQUESTS: 'Solicitações abertas',
  APPROVED_REQUESTS: 'Solicitações aprovadas',
  REJECTED_REQUESTS: 'Solicitações rejeitadas',
  USER_REQUESTS: 'Solicitações de usuário',
  REQUEST_FOR: 'Pedido para',
  CATEGORY_TOPIC_REQUESTS: 'Solicitações de categoria/tópico',
  WITH_THE_FOLLOWING_PAIRS: 'com os seguintes pares:',
  MY_TEAM_OPEN_REQUESTS: 'Solicitações abertas',
  MY_TEAM_APPROVED_REQUESTS: 'Solicitações aprovadas',
  MY_TEAM_REJECTED_REQUESTS: 'Solicitações rejeitadas',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Documentar a aprovação do EnqMed',
  DOCUMENTS_APPROVAL: 'Aprovação de documentos',
  DOCUMENTS_APPROVAL_TITLE:
    'Configurações para o processo de aprovação de documentos para a equipe selecionada',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Canal de saída selecionado como fax. Por favor, insira o número de fax de Enquirer na área de Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Canal de saída selecionado como telefone. Por favor, insira o número de telefone de Enquirer na área de Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Valor do fax do canal de saída',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Valor do telefone do canal de saída',
  COPYRIGHT_PART_ONE: 'direito autoral',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Todos os direitos reservados.',
  REVIEWED_DATE: 'Data de revisão',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Canal de saída selecionado como email. Insira o e -mail de Enquirer na área de Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'E -mail de canal de saída O valor do Enquirer',
  ENTER_USER_COMMENT_OPTIONAL: 'Digite comentário para o usuário (opcional)',
  SELECT_ANOTHER_CATEGORY: 'Selecione outra categoria',
  SELECT_ANOTHER_PRODUCT: 'Selecione outro produto',
  SUGGESTED_TOPIC: 'Tópico sugerido',
  EDIT_USER_REQUEST: 'Editar solicitação do usuário',
  NEW_TOPIC_REQUEST: 'Novo tópico sugerido',
  EDIT_TOPIC_REQUEST: 'Editar tópico sugerido',
  NEW_CATEGORY_REQUEST: 'Nova categoria sugerida',
  EDIT_CATEGORY_REQUEST: 'Editar categoria sugerida',
  REVIEW_COMMENT: 'Revise o comentário',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Fluxos de trabalho de aprovação de documentos',
    CREATE_NEW: 'Crie um novo fluxo de trabalho',
    CUSTOMIZE: 'Personalize o fluxo de trabalho',
    NAME: 'Nome do fluxo de trabalho',
    ERROR_MSG_DUPLICATE_NAME: 'Fluxo de trabalho com esse nome já existe.',
    SAVE: 'Salvar fluxo de trabalho',
    DELETE: 'Excluir fluxo de trabalho',
    STEP: 'Etapa',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Todos os usuários com a função do autor do documento.',
    APPROVAL_DEADLINE: 'Prazo para aprovação',
    SELECT_APPROVAL_TYPE: 'Selecione Tipo de aprovação',
    SELECT_APPROVAL_TYPE_FIRST: 'Selecione Tipo de aprovação primeiro',
    SELECT_REVIEWERS: 'Selecione revisores',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Ação sobre aprovar com mudanças',
    ACTION_ON_REJECT: 'Ação em rejeição',
    FILL_ALL_REQUIRED_FIELDS:
      'Por favor, preencha todos os campos necessários para adicionar uma nova etapa.',
    ALL_APPROVAL_TYPES_USED:
      'Você não pode adicionar mais etapas de aprovação, pois usou todos os tipos disponíveis para a etapa atual.',
    CONFIRMATION_ROW_1:
      'Você está tentando excluir o fluxo de trabalho de aprovação do documento para a equipe {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Você realmente deseja excluir o fluxo de trabalho de aprovação?',
    CONFIRMATION_TITLE: 'Documentar a exclusão do fluxo de trabalho de aprovação',
    TEAM_VIEW_SELECT_EXISTING:
      'Selecione um fluxo de trabalho de aprovação do documento existente na lista abaixo ou ',
    DOC_VIEW_SELECT_EXISTING: 'Selecione um fluxo de trabalho de aprovação do documento existente',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Somente a etapa de aprovação única pode ser listada como final.',
    APPROVAL_TYPE_TOOLTIP:
      'Não há usuários com função de aprovação de documentos para esta equipe. Adicione as funções de aprovação primeiro.',
    INFO_DIALOG_TITLE: 'Não há fluxo de trabalho de aprovação de documentos para esta equipe.',
    INFO_DIALOG_MSG:
      'Entre em contato com o MIM local para criar um fluxo de trabalho de aprovação do documento para a equipe.',
    STEP_LIMIT_REACHED: 'Você atingiu o limite de {{ MAX_STEPS }} etapas de aprovação.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Tipos de documentos que não requerem aprovação',
  PLEASE_SELECT_TYPE: 'Selecione o tipo',
  ABORT_FILE_UPLOAD: 'Abortar o upload do arquivo',
  USERS_REPORT: 'Relatório de usuários',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Ao fechar esta janela, você perderá as informações já preenchidas.',
  DO_YOU_WANT_TO_PROCEED: 'Você quer prosseguir?',
  USERS_REPORT_DESCRIPTION:
    'Lista de usuários de todas as equipes adicionais em que o MIM para Daiichi Sankyo Europa Oncologia e Cardiovascular têm um papel MIM.',
  TEMPLATE_CONFIRM_MSG: 'Depois que o modelo é excluído, ele não pode ser restaurado!',
  EVENT_CONFIRM_MSG: 'Depois que o evento é arquivado, ele não pode ser restaurado!',
  CREATE_DRAFT: 'Criar rascunho',
  CREATE_USER: 'Criar usuário',
  ADD_ROLE: 'Adicione o papel',
  DOMAIN_ADDRESS_INVALID_FOR: 'Endereço de domínio inválido para',
  AVAILABLE_ADDRESSES: 'Endereços disponíveis',
  IN_TEAM: 'No time',
  TO_THE_SELECTED_USER: 'para o usuário selecionado',
  USER_INFORMATION: 'Informação do usuário',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'O endereço de email que você está usando precisa ser atualizado, pois o endereço do domínio não corresponde à equipe do seu perfil.',
  MESSAGE: 'Mensagem',
  IN_APPROVAL: 'Em aprovação',
  IN_REVIEW: 'Em revisão',
  DOCUMENT_APPROVAL: 'Aprovação do documento',
  DOCUMENT_TITLE: 'Título do documento',
  SEARCH_DOCUMENTS: 'Documentos de pesquisa',
  APPROVAL_INBOX: 'Caixa de entrada de aprovação',
  FILTER_DOCUMENTS_BY: 'Filtrar documentos por',
  submit_to_approval: 'Envie para aprovação',
  create_document_metadata: 'Crie metadados do documento',
  archive: 'Arquivo',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Reconheço que é minha obrigação relatar todas as reações adversas de medicamentos (ADRs) de todos os produtos médicos de acordo com os requisitos locais. O ENQMED não se destina a capturar relatórios sobre reações adversas de medicamentos - os ADRs devem ser relatados à farmacovigilância diretamente de acordo com os requisitos locais',
  VERSION_DIALOG: {
    TITLE: 'Nova versão disponível',
    MESSAGE:
      'Há uma nova versão do software disponível!\n Você precisa obter a nova versão, para se beneficiar das melhorias nela e evitar problemas ao usar a versão antiga, você precisa obtê-la.',
    SAVE_BUTTON: 'Obtenha a nova versão',
  },
  ACTIVE_INACTIVE: 'Ativo inativo',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique as categorias existentes na tabela atrás da caixa de diálogo, pois já temos categorias com os mesmos valores.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Erro! Falha na entrega',
  VIA: 'através da',
  INSERTED_MANUALLY: 'inserido manualmente',
  AS_A: 'como um',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique os produtos existentes na tabela atrás da caixa de diálogo, pois já temos produtos com os mesmos valores.',
  NOT_PRODUCT_SPECIFIC: 'Não específico do produto',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Por favor verifique os tópicos existentes na tabela atrás da caixa de diálogo, pois já temos tópicos com os mesmos valores.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Insira no máximo {{ MAX_CHARS }} caracteres.',
  THANK_YOU_MESSAGE: 'mensagem de agradecimento',
  AS: 'como',
  THERAPEUTIC_AREA_PARENT: 'Área pai',
  MAIN_THERAPEUTIC_AREA: 'Área Terapêutica Principal',
  CHOOSE_AN_IMAGE: 'Escolha uma imagem',
  ALLOWED_FORMATS: 'Somente formatos {{ FORMATS }} são permitidos',
  REMOVE_IMAGE: 'Remover imagem',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Por favor verifique as áreas terapêuticas existentes na tabela atrás do diálogo pois já temos áreas com os mesmos valores.',
  PLAIN_TEXT_VIEW: 'Visualização de texto simples',
  RICH_TEXT_VIEW: 'Visualização de rich text',
  EDIT_ITEM: 'Editar {{ ITEM }}',
  CREATE_NEW_ITEM: 'Criar novo {{ ITEM }}',
  ADD_NEW_ITEM: 'Adicionar novo {{ ITEM }}',
  ADD_ITEM: 'Adicionar {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'e, portanto, o status mudou de',
  FOLLOW_UP_WITH_ENQUIRER: 'Acompanhamento com o inquiridor',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'O seguinte deve ser inserido para salvar as alterações:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'O acompanhamento não é possível para uma interação fechada.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interação no status de revisão. Salve a revisão para ativar este menu.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Texto da pergunta (mínimo 6 caracteres)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Forneça uma resposta (mínimo 6 caracteres ou um anexo).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Observe que o usuário deve recuperar a senha em 'Esqueci a senha'.",
  ENABLE_USER_LOGIN_MESSAGE: 'Isso permitirá o login do usuário.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Isso desativará o login e as notificações do usuário.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Por favor, defina suas preferências de notificação em seu perfil de usuário!',
  SET_PASSWORD: 'Por favor defina sua senha',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Após a reativação da sua conta, sua senha foi apagada.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Confirme a desativação da especialização!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Isso irá removê-lo da lista com especializações ativas.',
  GO_TO_ROLES: 'Vá para Funções',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Suas alterações nas informações de contato do usuário acionaram a alteração do e-mail de login de {{ OLD_USERNAME }} para {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Atribua funções e notificações.',
  DO_YOU_WANT_TO_CONTINUE: 'Você quer continuar?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Copiar resposta para a nova interação',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Novas consultas foram importadas. Certifique-se de caracterizar e responder a todas as perguntas.',
  LEAVE_WITHOUT_SAVING: 'Sair sem salvar',
  PENDING_CHANGES_MESSAGE: 'As alterações pendentes são {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Tem certeza de que deseja dividir o texto selecionado em uma nova interação?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Isso removerá o texto selecionado da interação e criará um novo a partir dele.',
  DOWNLOAD_ERROR: 'Erro de download!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Você está tentando baixar mais de 1.000 consultas. O número máximo suportado é 1000. Refine sua seleção.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Você está tentando mudar a caracterização da pergunta!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Observe que alterar a caracterização da pergunta não permitirá nenhuma alteração na resposta dada anteriormente nem permitirá o envio de uma nova resposta!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Você está prestes a reabrir uma interação fechada para enviar uma nova resposta.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Tem certeza de que deseja converter este acompanhamento em uma pergunta?',
  ARE_YOU_SURE: 'Tem certeza?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Confirme a desativação de {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Confirme a reativação de {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Usuário {{ USER_INFO }} reativado com sucesso!',
  DELETE_NOT_POSSIBLE: 'Excluir não é possível!',
  SEARCH_NO_LONGER_EXIST: 'Esta pesquisa não existe mais porque foi excluída pelo remetente.',
  LOGIN_CREDENTIALS_CHANGED: 'Credenciais de login alteradas!',
  EDIT_TEXT: 'Editar texto',
  FOLLOW_UP_ATTEMPTS: 'Tentativas de acompanhamento',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Primeiro acompanhamento enviado dentro da interação individual',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Se algum dos itens abaixo for desativado após a solicitação inicial, o processo passará para a próxima etapa',
  SECOND_ATTEMPT: 'Segunda tentativa (1º lembrete)',
  THIRD_ATTEMPT: 'Terceira tentativa (1º lembrete)',
  FINAL_ATTEMPT: 'Perdeu o acompanhamento (fechar)',
  REMINDER_FREQUENCY: 'Frequência do lembrete',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Aplicar tradução automática para o idioma usado para entrar em contato com o solicitante',
  ON_OFF_SWITCH: 'Ligado desligado',
  DAY: 'Dia',
  DAYS: 'Dias',
  MONTH: 'Mês',
  MONTHS: 'Meses',
  RESET_TEMPLATES_TO_DEFAULTS: 'Redefinir modelos para padrões',
  SAVE_FREQUENCY: 'Salvar frequência',
  SELECT_FREQUENCY: 'Selecione a frequência',
  TEMPLATE_MODIFICATIONS_HINT:
    'O texto foi modificado manualmente e não será mais alterado automaticamente. Clique aqui para descartar as modificações e reativar as atualizações automáticas.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Endereço de e-mail incorreto!',
  AUDIT_TRAIL_FOR: 'Trilha de auditoria para {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Trilha de auditoria para todos {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Mudanças no histórico para',
  HOLIDAY_GDPR_DISCLAIMER: 'Isenção de responsabilidade do GDPR para feriados',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Gerenciar isenção de responsabilidade do GDPR para feriados',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Modelo GDPR de feriados ausente para as seguintes equipes',
  BULK_CREATE: 'Criação em massa',
  LAST_SUCCESS: 'Último sucesso',
  LAST_FAILURE: 'Última falha',
  FAILURE: 'Falha',
  TASK: 'Tarefa',
  Weeks: 'Semanas',
  REPEAT_EVERY: 'Repita em cada',
  DEACTIVATE_ITEM: 'Desativar {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Redefinir contagem de falhas',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Por favor, verifique as tarefas existentes na tabela atrás da caixa de diálogo, pois já temos tarefas com os mesmos valores.',
  ACTIVATE_ITEM: 'Ative {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Insira um número maior que {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'A interação de destino selecionada para mesclagem já está fechada. Esta investigação é uma nova interação e não precisa ser mesclada.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Uma nova(s) pergunta(s) será(ão) adicionada(s) à consulta existente, todas as perguntas deverão ser respondidas. Selecione as perguntas para mesclar.',
    VALIDATION_MSG:
      'Selecione pelo menos uma pergunta da interação mesclada para prosseguir com a mesclagem.',
  },
  MERGED_INTERACTION: 'Interação mesclada',
  TARGET_INTERACTION: 'Interação alvo',
  MERGE_SUGGESTION: 'Sugestão de mesclagem',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Revise a sugestão de mesclagem abaixo',
  INQUIRERS_COMPARISON_TITLE:
    'Observe os diferentes questionadores para a interação mesclada e a interação de destino',
  INQUIRERS_COMPARISON_QUESTION:
    'Por favor, confirme se você deseja prosseguir apesar dessa diferença.',
  TERRITORY: 'Território',
  DOMAIN: 'Domínio',
  IN: 'em',
  TEAM_GROUP_FILTER: {
    TITLE: 'Defina permissões para visualizar equipes adicionais',
    SAVE_BTN_TOOLTIP:
      'Por favor, insira todos os campos obrigatórios para equipe e salve a equipe primeiro.',
  },
  ADR_REF_ID: 'ID de referência de ADR',
  PQC_REF_ID: 'ID de referência PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'Este anexo está presente em múltiplas interações',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Marque a opção da caixa de seleção se desejar excluir o anexo de todas as interações.',
  CONFIRM_DELETE_ATTACHMENT: 'Por favor, confirme se deseja excluir este anexo.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Excluir de todas as interações mencionadas acima',
  ATTACHMENT_DELETED: 'Anexo excluído',
  ROLES: 'Funções',
  SET_TO_ALL_INQ_TOOLTIP:
    'Defina a mesma caracterização ADR, PQC e Off-label para todas as perguntas.',
  TASKS_LIST: 'Lista de tarefas',
  TASK_LOGS: 'Registros de tarefas',
  SELECTED: 'Selecionado',
  LICENSE_COUNTER: 'Contador de licenças',
  LICENSES_LIMIT: 'Limite de números de licença',
  USERS_TO_EXCLUDE: 'Usuários a serem excluídos',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Taxas de licença contratadas (funções de usuário).',
  SELECT_USER_ROLES_TO_COUNT:
    'Selecione as funções de usuário aplicáveis à contagem de taxas de licença.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Excluir usuários da contagem de taxas de licença.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Notificações de recebimento e vendas',
  REFRESH_MIGRATION_ERRORS: 'Atualizar lista de erros de migração',
  MARKED_AS_RESOLVED_BY: 'Marcado como resolvido por',
  MARKED_AS_RESOLVED_ON: 'Marcado como resolvido em',
  MARK_AS_RESOLVED: 'Marcar como resolvido',
  ERROR_ON: 'Erro ativado',
  TEXT_WITH_SPECIAL_CHARS:
    'Insira apenas letras (a-z, A-Z), números (0-9) e símbolos especiais como traço (-), ponto final (.), espaço, dois pontos (:), vírgula (,), parênteses (()), avanço barra (/) e mais (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'A interação foi enviada para status de aprovação. Aprove/rejeite para ativar este menu.',
  REFRESH_TASK_LOGS: 'Atualizar lista de registros de tarefas',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Por favor, confirme se você deseja prosseguir com as alterações!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Arquivamento manual não permitido para funções delegadas.',
  RUN_COUNTER: 'Executar contador',
  EXPORT_LICENSES: 'Licenças de exportação',
  LICENSE_COUNT_MESSAGE: 'O número de licenças usadas é:',
  QUESTIONS: 'Questões',
  CLARIFICATION: 'Esclarecimento',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Selecione pelo menos uma pergunta na interação de destino para prosseguir com a mesclagem.',
    DESCRIPTION:
      'A pergunta de esclarecimento será adicionada às perguntas selecionadas na interação de destino.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Selecione esta opção para sinalizar a interação como uma duplicata da interação existente.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Mesclagem anterior como pergunta de esclarecimento detectada. Todos os campos de resposta selecionados, além dos campos de resposta selecionados anteriormente na interação de destino, serão reabertos para resposta.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'A interação foi reaberta para fornecer resposta a outra pergunta. A resposta para esta pergunta já foi fornecida anteriormente.',
  TIP: 'Dica',
  MERGE_TIP_TEXT:
    'Se você deseja reabrir a interação {{ MERGED_INTO_ID }}, pressione não e inicie um novo processo de mesclagem indicando a interação de destino {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Você está selecionando uma operação de mesclagem sem abrir a interação de destino.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Você está selecionando uma operação de mesclagem em uma interação de destino inválida {{ TARGET_INTERACTION_ID }}.<br>A interação {{ TARGET_INTERACTION_ID }} foi fechada com status {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Isso resultará em interação fechada e incapacidade de fornecer uma resposta.',
  INTO_INTERACTION: 'em interação',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Selecione a interação para mesclar.',
  PLEASE_SELECT_MERGE_REASON: 'Selecione o motivo da mesclagem.',
  QUESTION_CANNOT_BE_DELETED:
    'Esta pergunta não pode ser excluída porque há uma pergunta de esclarecimento não respondida associada.',
  MERGE_REASON: 'Motivo da mesclagem',
  WITH_REOPEN: 'com reabertura',
  MERGED_INTO_QUESTION_IDS: 'mesclado em ID(s) de pergunta(s)',
  MERGED_INTO_QUESTIONS: 'mesclado em pergunta(s)',
  MERGED_PREVIOUSLY_FROM: 'mesclado anteriormente de',
  FROM_INTERACTION_ID: 'do ID de interação',
  IN_INTERACTION_ID: 'no ID de interação',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Você está prestes a cancelar a sugestão de mesclagem.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Não mostrar sugestão de mesclagem novamente?',
  PRODUCT_RESOLVED_VIA_AI: 'Produto (resolvido via AI)',
  REQUIRED_FIELDS_HINT: 'Por favor preencha todos os campos requeridos',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'É necessário pelo menos um contato!',
  DISCLAIMER_INFO: 'Informações de isenção de responsabilidade',
  QUESTION_ID: 'ID da pergunta',
  QUESTION_IDS: 'IDs das perguntas',
  SHOW_TEAMS: 'Mostrar equipes',
  LAST_POLLING: 'Última votação',
  HOST: 'Hospedar',
  PROTOCOL: 'Protocolo',
  FROM_EMAIL: 'Do email',
  REASON_FOR_REMOVAL: 'Motivo da retirada',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Uma interação fechada não é editável.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'A interação tem mais de uma pergunta. Edição possível apenas a partir de interação aberta.',
  BULK_EDIT: 'Edição em massa',
  HOW_TO_ENABLE_BULK_EDIT:
    'Pelo menos dois modelos no mesmo idioma devem ser selecionados para fazer uma atualização em massa.',
  REASON_FOR_UPDATE: 'Motivo da atualização',
  FOLLOW_UP_RESEND: 'Acompanhamento reenviado',
  SENT_FOLLOW_UP_REMINDER: 'enviou lembrete de acompanhamento',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'interação fechada e enviada perdida para notificação de acompanhamento',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Insira um texto com comprimento mínimo de 15 caracteres.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Acompanhamento desabilitado! Insira o solicitante primeiro.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Solicitação de acompanhamento desativada! Por favor, insira primeiro o e-mail válido do solicitante.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'O número do tipo de frequência selecionado deve estar entre 1 e {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Selecione o tipo de frequência.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'A contagem começa a partir da data e hora da solicitação de acompanhamento inicial. Para conversão de dias em semanas, multiplique por 7, por ex. 2 semanas = 14 dias, para dias a meses multiplique por 30, por ex. 1 mês = 30 dias.',
  VALID_PHONE_FAX: 'Por favor insira um número de telefone/fax válido!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Selecione um idioma para visualizar e gerenciar os modelos traduzidos',
  RETRANSLATE: 'Retraduzir',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Os modelos abaixo estão apenas no idioma inglês, as traduções são gerenciadas abaixo',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Todos os modelos acima serão redefinidos para os padrões (a modificação manual será removida, a frequência será redefinida para 14 dias e a caixa de seleção de tradução será desmarcada), retraduzidos para todos os idiomas e serão desmarcados como is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Edite o texto do modelo e traduza novamente todas as traduções que não foram modificadas manualmente.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Traduza novamente o texto do modelo original, incluindo aqueles modificados manualmente.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Modelos de lembrete de acompanhamento',
  MANUALLY_MODIFIED: 'Modificado manualmente',
  SYNC: 'Sincronizar',
  DIFFERENT: 'Diferente',
  DEFAULT_CONFIRMATION_QUESTION: 'Por favor, confirme se deseja prosseguir.',
  SEND_WELCOME_MAIL_: 'Enviar e-mail de boas-vindas com redefinição de senha',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Confirmo que uma nova informação de acompanhamento foi recebida pelo solicitante e que gostaria de reabrir a interação para fornecimento de respostas.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Por favor, insira apenas letras, espaço e o símbolo -!',
  INFO: 'Informações',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'A alteração no país do solicitante alterará a atribuição da interação da equipe {{ CURRENT_TEAM }} para a equipe {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'A alteração no país do solicitante alterará a atribuição da interação da equipe {{ CURRENT_TEAM }} para a equipe {{ FUTURE_TEAM }}. O usuário {{ ASSIGNED_TO }} não tem permissões para a equipe {{ FUTURE_TEAM }} e sua atribuição pessoal será removida.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'A alteração afetará as atribuições de equipe nas seguintes interações adicionais, onde as atribuições pessoais serão removidas: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'A alteração no país do solicitante alterará as atribuições da equipe nas seguintes interações adicionais, onde a atribuição pessoal será removida. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ID de interação: {{ INTERACTION_ID }} de {{ CURRENT_TEAM }} para {{ FUTURE_TEAM }}, atribuição pessoal removida do usuário {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'O usuário {{ EDITING_USER }} alterou o país do solicitante {{ ENQUIRER }} no ID de interação: {{ UPDATED_INTERACTION_ID }}, o que alterou a atribuição da interação atual da equipe {{ OLD_TEAM }} para a equipe {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'O usuário {{ EDITING_USER }} alterou o país do solicitante {{ ENQUIRER }} na administração do HCP, o que alterou a atribuição da interação atual da equipe {{ OLD_TEAM }} para a equipe {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'O usuário {{ INTERACTION_ASSIGN_TO }} não tem permissões para a equipe {{ NEW_TEAM }} e sua atribuição pessoal foi removida.',
  PERSONAL_ASSIGNMENT: 'Tarefa pessoal',
  DUE_TO_FU_REQUEST: 'devido a solicitação de acompanhamento',
  DUE_TO_FU_RESPONSE: 'devido à resposta de acompanhamento',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'devido à atualização da frequência de lembretes de acompanhamento da página de administração',
  UPDATED_FROM_INBOX: 'atualizado da caixa de entrada',
  ARCHIVED_ROLE: 'Função arquivada',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Arquivar a função do usuário alterará as atribuições nas seguintes interações adicionais, onde as atribuições pessoais serão removidas: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'O usuário {{ EDITING_USER }} editou o usuário {{ INTERACTION_ASSIGN_TO }}, arquivando sua função como {{ USER_ROLE }} na equipe {{ TEAM }}. O usuário {{ INTERACTION_ASSIGN_TO }} não tem permissões para a equipe {{ TEAM }} e sua atribuição pessoal foi removida.',
  ASSIGNMENT: 'Atribuição',
  PERIOD_TO_PERIOD_OVERLAP: 'Período e Para Período estão sobrepostos',
  PERIOD_TO_PERIOD_MISMATCH:
    'Período e Para Período têm durações diferentes - {{ PERIOD }} {{ INTERVAL }} e {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE:
    'criou uma pergunta a partir de uma resposta de acompanhamento',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Esta opção permite criar uma nova pergunta a partir de uma pergunta de acompanhamento.',
  ADD_AS_A_NEW_QUESTION: 'Adicionar como uma nova pergunta',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Esteja ciente de que você está tentando arquivar a última função aprovada para este usuário, o que causará a desativação do usuário e impedirá seu acesso futuro ao EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'tentou um acompanhamento',
  FAILED_FU_ATTEMPT: 'Tentativa de acompanhamento falhada',
  UNABLE_TO_REACH_ENQUIRER: 'Não foi possível contatar o solicitante.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Selecione esta opção se a tentativa de contato com o consulente foi feita, mas não obteve sucesso, por exemplo, não foi possível contatar por telefone, falha no F2F ou outro compromisso.\nA tentativa será registrada no histórico de interação.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Você será redirecionado para uma página para a qual você possui permissões.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'devido ao processo de acompanhamento ter sido interrompido em decorrência da alteração do solicitante',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Você está prestes a trocar o inquiridor, o que interromperá o processo de acompanhamento.',
  INQUIRER_DETAILS: 'Detalhes do inquiridor',
  INQUIRER_TYPE: 'Tipo de inquiridor',
  DELETE_INQUIRER_PII: 'Excluir informações pessoais do solicitante',
  MARKED_INQUIRER_PII_DELETE: 'informações pessoais do inquiridor marcadas para exclusão',
  UNMARKED_INQUIRER_PII_DELETE: 'informações pessoais do inquiridor não marcadas para exclusão',
  NEW_ENQUIRER: 'Novo inquiridor',
  NEW_ENQUIRER_TOOLTIP:
    'Use esta opção para criar um inquiridor completamente novo. Se, em vez disso, for necessário editar os dados do inquiridor existente, não use esta opção.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Por favor, confirme que você deseja substituir o solicitante existente por um novo.<br>Todas as informações inseridas serão perdidas.<br>Se você desejar editar os dados do solicitante existente, modifique os dados diretamente no formulário e pressione Salvar.',
  INQUIRER: 'Inquiridor',
  KEEP_INQUIRER_DETAILS_OPEN: 'Mantenha os detalhes do inquiridor abertos',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Você tem alterações não salvas, que serão perdidas se você fechar os detalhes do consulente.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Compartilhar: aqui você pode atribuir, encaminhar ou iniciar um acompanhamento com o solicitante.',
  UPDATED_INQUIRER_DETAILS: 'detalhes atualizados do solicitante',
  CREATED_INQUIRER: 'criou o inquiridor',
  SUGGESTED_INQ: 'Pesquisadores sugeridos',
  SELECT_INQUIRER: 'Selecione o Inquiridor',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'O consentimento da obrigação de ADR e o inquiridor devem ser selecionados para submeter a interação',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'A resposta não foi enviada ao solicitante devido ao endereço de e-mail incorreto.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Obrigado! A notificação será enviada assim que a consulta for enviada ou respondida.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Obrigado! A notificação será enviada assim que o inquiridor for salvo.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'A seguinte notificação do GDPR será enviada ao solicitante por e-mail.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'A seguinte notificação do GDPR foi enviada ao solicitante por e-mail.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Envie a seguinte notificação do GDPR ao solicitante por e-mail.',
  INQ_HAS_NO_EMAIL: 'o solicitante não possui e-mail.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'NÃO foi enviada uma notificação ao solicitante informando que a consulta foi recebida.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Notificação enviada ao solicitante de que a consulta foi recebida.',
  THIS_IS_JUST_A_THANK_YOU:
    'Esta é apenas uma mensagem de agradecimento do autor da consulta existente.',
  SAVE_INQUIRER: 'Salvar Enquirer',
  INQ_DEFAULT_VAL: 'Valores padrão do Enquirer',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Para consultas enviadas por meio de representante da empresa (representante de vendas, MSL ou outro), notifique o consulente sobre o tratamento de seus dados pessoais.',
  SELECT_PREFERRED_CONTACT_ERROR: 'Você deve selecionar um contato preferencial para o consulente.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Acompanhamento iniciado pelo inquiridor',
  NO_INQUIRER: 'Nenhum inquiridor',
  'Changed inquirer': 'Mudou o inquiridor',
  QUESTION_REQUIRE_ANSWER_MESSAGE: 'A nova pergunta exigirá uma resposta para encerrar a consulta.',
  IMAGE: 'Imagem',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'A Área Terapêutica é {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', pois não há produtos associados. Selecione pelo menos um produto para definir a área terapêutica como ativa.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Expandir todas as áreas terapêuticas',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Recolher todas as áreas terapêuticas',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Para definir uma Área Terapêutica (AT) como AT principal, desmarque a AT pai e salve.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Etapa extra necessária para efetuar login',
  EXTRA_STEP_DESCRIPTION:
    'A confirmação de login foi enviada para seu e-mail. Por favor, siga os passos nele, para continuar no sistema.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Insira um texto com no mínimo 15 caracteres ou um anexo.',
  REASON_FOR_REJECTION: 'Motivo da rejeição',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'A nota foi inserida corretamente no histórico.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Ao selecionar {{ ACTION_TYPE }} o {{ METADATA_TYPE }}, as seguintes alterações adicionais relacionadas serão aplicadas:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Ao remover os produtos, as seguintes alterações adicionais relacionadas serão aplicadas:',
  TEAM_NOTIFY_INQUIRER: 'Notificar o solicitante',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Habilitar esta opção enviará automaticamente notificações do GDPR aos solicitantes em interações abertas nesta equipe que ainda não foram notificadas.',
  TEAM_NOTIFY_SALES: 'Notificar vendas',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Habilitar esta opção ativará a notificação de resposta envolvida para todos os usuários com a função de vendas nesta equipe.\n\nDesabilitar esta opção desativará a notificação de resposta envolvida para todos os usuários com a função de vendas nesta equipe, a menos que eles tenham a função de vendas em outra equipe com a configuração de notificação de vendas habilitada.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'recebe a seguinte notificação',
  EMAILS: 'E-mails',
  PHONES: 'Telefones',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Usuário(s) desativado(s) com sucesso',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Confirmar desativação em massa de usuários',
  DEACTIVATE_SELECTED_USERS: 'Desativar usuários selecionados',
  SELECT_USERS_TO_DEACTIVATE: 'Selecione usuários para desativar',
  ARCHIVE_FOLDER: 'Pasta de arquivo',
  EXTRA_PARAMS:
    "Parâmetros extras (k1=v1&k2=v2, 'pastas' e 'arquivo' são reservados e não podem ser usados)",
  POLL_FOLDER: 'Pasta de enquete',
  POLL_FOLDERS_TEXT:
    'As pastas de enquete só podem ser definidas para os protocolos imap ou gmail. Para todos os outros protocolos, o padrão é a caixa de entrada.',
  POLL_FOLDERS_TOOLTIP_1:
    'Digite o nome da pasta e pressione Enter ou use uma vírgula para adicioná-la para pesquisa na caixa de correio (se nenhuma pasta for adicionada, a Caixa de entrada será pesquisada por padrão).',
  POLL_FOLDERS_TOOLTIP_2: 'Os nomes de pastas listados aqui serão pesquisados na caixa de correio.',
  PERMISSION: 'Permissão',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique as funções existentes na tabela atrás da caixa de diálogo, pois já temos uma função com o mesmo nome.',
  CURR_MAILBOX: 'Caixa de correio atual',
  ENQUIRY_ANSWER_APPROVAL: 'Aprovação da resposta da consulta',
  REASON_FOR_INCLUSION: 'Motivo da inclusão',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'Pastas para pesquisar na caixa de correio',
  VERIFY_SETUP: 'Verificar configuração',
  HTML_PREVIEW: 'Visualização HTML',
  IN_REPLY_TO_ID: 'Em resposta a id',
  INCOMING: 'Entrada',
  OUTGOING: 'Extrovertido',
  VIEW: 'Visualizar',
  ROLE_IN_USE_USERS:
    'A função está atualmente atribuída aos usuários e arquivá-la pode limitar seus direitos de acesso',
  ROLE_IN_USE_USER_TEMPLATES:
    'A função é usada atualmente por modelos de usuários e arquivá-la irá removê-la deles',
  ROLE_IN_USE_LICENSE_COUNTER:
    'A função é usada atualmente no contador de licenças e arquivá-la a removerá dele.',
  REASON_FOR_REPLACEMENT: 'Motivo da substituição',
  QUESTION_TEXT_AFTER_SPLIT: 'Texto da pergunta após a divisão',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'O fluxo de trabalho de aprovação de resposta de consulta será desabilitado para a equipe. A(s) interação(ões) no status "Enviado para aprovação" será(ão) afetada(s) e revertida(s) para o status "Em andamento" para permitir a preparação correta da resposta e a resposta sem aprovação.',
  REVERT_TO_IN_PROGRESS: 'RETORNAR PARA EM ANDAMENTO',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Divisão de pergunta realizada, enquanto a interação está no status {{ SUBMITTED_FOR_APPROVAL }}. Esta ação reverte o status de volta para {{ IN_PROGRESS }} para permitir a preparação da resposta para a(s) pergunta(s) recém-criada(s).',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Ação {{ MERGE_AS_ADDITIONAL_QUESTION }} realizada na interação atual, enquanto a interação de destino está no status {{ SUBMITTED_FOR_APPROVAL }}. Esta ação reverte o status de volta para {{ IN_PROGRESS }} para permitir a preparação da resposta para a(s) pergunta(s) recém-criada(s).',
  ENQUIRY_ANSWER_APPROVAL_TITLE:
    'Definir aprovação de resposta de consulta para as equipes selecionadas',
  APPROVAL_ASSIGN_TO: 'Aprovação de eq. atribuída a',
  APPROVAL_ASSIGN_BY: 'Aprovação de eq. atribuída por',
  APPROVAL_ASSIGNMENTS: 'Atribuições de aprovação de Enq.',
  APPROVAL_ASSIGNMENTS_TO: 'Atribuições de aprovação de eq. para',
  APPROVAL_ASSIGNMENTS_BY: 'Atribuições de aprovação de eq. por',
  My_pending_approvals: 'Minhas aprovações pendentes',
  RULE: 'Regra',
  RULES: 'Regras',
  FIELD: 'Campo',
  CRITERIA: 'Critérios',
  NUMBER: 'Número',
  MIN: 'Mínimo',
  MAX: 'Máx.',
  ADD_GROUP: 'Adicionar grupo',
  DELETE_GROUP: 'Excluir grupo',
  VALID_FROM_TO: 'Válido de/para',
  NEW_INQ: 'Nova pergunta',
  NOT_APPROVING_INQUIRIES: 'Não aprovar consultas',
};
