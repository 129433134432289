<button class="inbox-inq-label" [routerLink]="['/inq/my-teams-open-inq']">
  <i class="fa fa-inbox inbox-inq-icon"></i>
  <span class="inbox-inq-content">{{ 'Inq_inbox' | translate }}</span>
</button>

@if (canProcessEnquiries) {
<button mat-button class="medis-button-main new-inq-btn" [routerLink]="['/new']">
  <i class="fas fa-plus"></i>
  <span class="new-inq-content">{{ 'NEW_INQ' | translate }}</span>
</button>
}

<ng-template #popContent>
  <div class="row m-0" *ngIf="filteredFilters.length > 0">
    <div class="col p-0">
      <p
        class="d-flex justify-content-between align-items-center detail-hover p-2 m-0 saved-search-row"
        *ngFor="let filter of filteredFilters"
      >
        <i
          class="ms-1 text-18-azure me-2 cursor-pointer saved-search-star-icon fa-star"
          (click)="setFavourite(filter)"
          [ngClass]="filter.favourite ? 'fas' : 'far'"
        ></i>
        <a
          [matTooltip]="filter.name"
          (click)="searchInquiry(pop, filter)"
          class="page-link text-azure search-link saved-search-record"
        >
          {{ filter.name }}
        </a>
        <span class="text-end w-100">
          <i
            class="ms-1 fa fa-eye text-14-azure cursor-pointer saved-search-view-icon"
            (click)="editViewInquiry(pop, filter, true)"
            [matTooltip]="'VIEW_SAVED_SEARCH' | translate"
          ></i>
          <i
            class="ms-4 fa fa-edit text-14-azure cursor-pointer saved-search-edit-icon"
            [matTooltip]="'EDIT_SAVED_SEARCH' | translate"
            (click)="editViewInquiry(pop, filter, false)"
          ></i>
          <i
            class="ms-4 fa fa-file-download saved-search-export-icon"
            [ngClass]="{
              'cursor-default': objectKeys(filter.exportedColumns).length < 1,
              'text-14-cool-grey': objectKeys(filter.exportedColumns).length < 1,
              'text-14-azure': objectKeys(filter.exportedColumns).length > 0,
              'cursor-pointer': objectKeys(filter.exportedColumns).length > 0
            }"
            [matTooltip]="'EXPORT_SAVED_SEARCH' | translate"
            (click)="exportInquiry(filter)"
          ></i>
          <i
            (click)="deleteInquiry(pop, filter)"
            [matTooltip]="'DELETE_SAVED_SEARCH' | translate"
            class="ms-4 fa fa-trash text-14-azure cursor-pointer saved-search-delete-icon"
          ></i
        ></span>
      </p>
      <p class="mt-5 text-12-azure ps-3 pe-2">
        <a
          class="cursor-pointer"
          (click)="clearSearch(pop)"
          [attr.data-testid]="'clear-saved-search'"
          >{{ 'CLEAR_SAVED_SEARCH' | translate }}</a
        >
      </p>
    </div>
  </div>
  <div class="row" *ngIf="filteredFilters.length < 1 && searchSubject.val.length >= 3">
    <div class="col ms-3 me-4">
      <p class="mt-4 text-12-azure text-center">
        {{ 'NO_SAVED_SEARCH' | translate }}
      </p>
    </div>
  </div>
</ng-template>

<mat-form-field
  class="search-box form-field-wrapper rounded-white"
  [attr.data-testid]="'search-enquiries-wrapper'"
>
  <i class="fas fa-search medis-icon-prefix" matPrefix></i>
  <mat-label class="search-label">{{ 'Search_inquiries' | translate }}</mat-label>

  <button
    matSuffix
    [attr.data-testid]="'search-enquiries-go-button'"
    class="btn-keyword-search text-14-700-white rounded-circle border-0"
    (click)="textSearch = textSearch.trim(); onTextSearchChange()"
  >
    {{ 'GO' | translate }}
  </button>
  <input
    matInput
    [(ngModel)]="textSearch"
    [attr.data-testid]="'search-enquiries-input-field-button'"
  />
</mat-form-field>

<div class="text-end me-4 container-open-advanced-search">
  <a (click)="openFilterModal(pop)" class="medis-link" data-testid="open-advanced-search">
    {{ 'ADVANCED' | translate }}
  </a>
</div>

<mat-form-field
  class="search-box form-field-wrapper rounded-white"
  [attr.data-testid]="'search-saved-searches-wrapper'"
  [ngbPopover]="popContent"
  triggers="manual"
  popoverClass="popover-search"
  #pop="ngbPopover"
  [autoClose]="true"
  placement="bottom"
>
  <i
    class="fas fa-star medis-icon-prefix cursor-pointer"
    matPrefix
    (click)="showAllSavedSearches(pop)"
    matTooltip="{{ (listFilters?.length ? 'Show_all' : 'NO_SAVED_SEARCH') | translate }}"
  ></i>
  <mat-label class="search-label" [attr.data-testid]="'search-saved-searches-label'">{{
    'SEARCH_SAVED_SEARCHES' | translate
  }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [(ngModel)]="searchSubject.val"
    [attr.data-testid]="'search-saved-searches-input-field'"
    (ngModelChange)="searchChange(pop, $event)"
  />
</mat-form-field>

<ng-container *ngFor="let item of navigation; let last = last">
  <medis-nav-vertical-group
    *ngIf="item.type === 'group'"
    [item]="item"
    [dataTestId]="item.id"
  ></medis-nav-vertical-group>
  <medis-nav-vertical-item
    *ngIf="item.type === 'item'"
    [item]="item"
    [dataTestId]="item.id"
  ></medis-nav-vertical-item>
  <div *ngIf="!last" class="navigation-separator"></div>
</ng-container>
